import { StyledRoot, StyledTable, StyledTableBody, StyledTableBodyCell, StyledTableBodyRow, StyledTableHead, StyledTableHeadCell, StyledTableHeadRow } from 'baseui/table-semantic';
import React, { ReactElement } from 'react'
import { CreditsSettings } from '../../types/graphql/AppConfiguration'
import CellInput from './CellInput'

interface Props {
loading:boolean,
settings: CreditsSettings
onValueChange:(key:string, value:string)=>void;
}

export default function CreditsTable({loading, onValueChange, settings }: Props): ReactElement {
    return (
        <StyledRoot>
            <StyledTable >
                <StyledTableHead>
                    <StyledTableHeadRow>
                        <StyledTableHeadCell colSpan={2}>Crédits</StyledTableHeadCell>
                    </StyledTableHeadRow>
                    <StyledTableHeadRow>
                        <StyledTableHeadCell>Challenge</StyledTableHeadCell>
                        <StyledTableHeadCell>Coût/Montant</StyledTableHeadCell>
                    </StyledTableHeadRow>
                </StyledTableHead>
                <StyledTableBody>
                    {/* <StyledTableBodyRow >
                        <StyledTableBodyCell>Mois</StyledTableBodyCell>
                        <CellInput disabled={loading == true} onChange={onValueChange} name="credits.month" initialValue={settings.month.value} />
                    </StyledTableBodyRow>
                    <StyledTableBodyRow >
                        <StyledTableBodyCell>Semaine</StyledTableBodyCell>
                        <CellInput disabled={loading == true} onChange={onValueChange} name="credits.week" initialValue={settings.week.value} />
                    </StyledTableBodyRow>
                    <StyledTableBodyRow >
                        <StyledTableBodyCell>Jour</StyledTableBodyCell>
                        <CellInput disabled={loading == true} onChange={onValueChange} name="credits.day" initialValue={settings.day.value} />
                    </StyledTableBodyRow>
                    <StyledTableBodyRow >
                        <StyledTableBodyCell>Heure</StyledTableBodyCell>
                        <CellInput disabled={loading == true} onChange={onValueChange} name="credits.hour" initialValue={settings.hour.value} />
                    </StyledTableBodyRow> */}
                    {/* <StyledTableBodyRow >
                        <StyledTableBodyCell>Random - accepte</StyledTableBodyCell>
                        <CellInput disabled={loading == true} onChange={onValueChange} name="credits.random.accept" initialValue={settings.random.accept!.value} />
                    </StyledTableBodyRow>
                    <StyledTableBodyRow >
                        <StyledTableBodyCell>Random - refuse</StyledTableBodyCell>
                        <CellInput disabled={loading == true} onChange={onValueChange} name="credits.random.decline" initialValue={settings.random.decline!.value} />
                    </StyledTableBodyRow> */}
                    <StyledTableBodyRow >
                        <StyledTableBodyCell>Bonus d'inscription</StyledTableBodyCell>
                        <CellInput disabled={loading == true} colSpan={3} onChange={onValueChange} name="credits.signUpBonus" initialValue={settings.signUpBonus.value} />
                    </StyledTableBodyRow>
                </StyledTableBody>
            </StyledTable>
        </StyledRoot>

    )
}
