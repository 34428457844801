import {gql, useMutation} from "@apollo/client";
import {AdInput, GqlAd} from "../../types/graphql/Ads";

const UPDATE_AD_MUTATION = gql`
    mutation updateAd($ad: AdInputTypeInput) {
        editAd(editedAd:$ad){
          id
          dateStart
          dateEnd
          media {
            id
            path
            name
            keywords
          }
        }
    }
  `;

export function UpdateAdMutation() {
    return useMutation<{ editAd: GqlAd }, { ad: AdInput }>(UPDATE_AD_MUTATION);
}