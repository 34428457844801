import { gql } from "@apollo/client";

export type GqlAppConfigurationQueryResult = {
  settings: GqlAppConfiguration;
}

export type CreditsSettings = {
//   hour: GqlAppConfigurationItem
//   month: GqlAppConfigurationItem
//   newPV: GqlAppConfigurationItem
//   week: GqlAppConfigurationItem
//   day: GqlAppConfigurationItem
  signUpBonus: GqlAppConfigurationItem;
//   random: {
    // accept?: GqlAppConfigurationItem,
    // decline?: GqlAppConfigurationItem
//   }
}

export type PointsSettings = {
  hour: GqlConfigurationPositions;
  month: GqlConfigurationPositions;
  newPV: GqlConfigurationPositions;
  random: GqlConfigurationPositions;
  week: GqlConfigurationPositions;
  day: GqlConfigurationPositions;
  firstParticipationBonus: GqlAppConfigurationItem;
}
export type CashSettings = {
  seasonEndDay: GqlConfigurationPositions;
  seasonEndHour: GqlConfigurationPositions;
  seasonEndMonth: GqlConfigurationPositions;
  seasonEndWeek: GqlConfigurationPositions;
  singleDay: GqlConfigurationPositions;
  singleHour: GqlConfigurationPositions;
  singleMonth: GqlConfigurationPositions;
  singleWeek: GqlConfigurationPositions;
}
export type GqlAppConfiguration = {
  credits: CreditsSettings;
  points: PointsSettings;
//   cashPrizes: CashSettings
}
export type GqlAppConfigurationItem = {
    value: string;
  }

type GqlConfigurationPositions = {
  firstPlace: GqlAppConfigurationItem;
  secondPlace: GqlAppConfigurationItem;
  thirdPlace: GqlAppConfigurationItem;
}


export const APP_CONFIGURATION_QUERY = gql`
query {
    settings{
      points{
      	hour {...position}
        month {...position}
        week {...position}
        day {...position}
        newPV {...position}
        random {...position}
        firstParticipationBonus {...configItem}
      }
      credits {
        #hour {...configItem}
        #month {...configItem}
        #day {...configItem}
        #newPV {...configItem}
        #week {...configItem}
        #random {  
        #  accept {...configItem} 
        #  decline {...configItem}}
        signUpBonus {...configItem}
      }
      #cashPrizes {
      #  singleMonth {...position}
      #  singleWeek {...position}
      #  singleDay {...position}
      #  singleHour {...position}
      #  seasonEndMonth {...position}
      #  seasonEndWeek {...position}
      #  seasonEndDay {...position}
      #  seasonEndHour {...position}
      #}
    }
  
}
    
    fragment configItem on ConfigurationItemMapped {
      value
    }
     fragment position on Positions {
      firstPlace {...configItem}
      secondPlace {...configItem}
      thirdPlace {...configItem}
    }

`;