import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Grid, Cell } from 'baseui/layout-grid';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, SIZE } from 'baseui/modal'
import { Table } from 'baseui/table-semantic';
import { format } from 'date-fns';
import { frCA } from 'date-fns/locale';
import { IoIosCheckmark, IoIosClose } from 'react-icons/io';
import { ImageUtils } from '../../../utils/ImageUtils';
import Image from '../../UiElements/Image/Image';
import Username from '../../Username';
import { toaster } from 'baseui/toast';
import { GqlChallengeType, languageString } from '../../../types/graphql/PeriodicChallengeInput';
import { LabelLarge, LabelMedium } from 'baseui/typography';
import BannishingReasonChoices from './BannishingReasonChoices';
import { useCommentComplaintQueries } from '../../Complaints/useCommentComplaintQueries';
import { Block } from 'baseui/block';

interface Props {
    isOpen: boolean,
    comment?: any,
    imagePath?: string,
    close: (refetch: boolean) => void
}

export default function CommentComplaintDetailsModal({ isOpen, close, comment }: Props): ReactElement {
    const { complaintsPerComment, moderateComment } = useCommentComplaintQueries();
    const [showReasonModal, setShowReasonModal] = useState(false);
    const bannishReason = useRef({ reason: '', reasonText: [] as languageString[] });

    useEffect(() => {
        if (comment)
            complaintsPerComment.refetch({ id: comment.id })
    }, [comment, isOpen]);

    function onReasonChange(reason: string, reasonText: languageString[] = []) {
        bannishReason.current = { reason, reasonText };
    }

    function onReasonClose(bannish: boolean) {
        setShowReasonModal(false);
        if (bannish)
            moderate(false)
    }
    async function moderate(isValid: boolean) {
        try {
            const result = await moderateComment[0]({
                variables: {
                    id: comment.id,
                    isValid,
                    bannedReason: isValid ? undefined : bannishReason.current.reason,
                    bannedReasonText: isValid ? undefined : bannishReason.current.reasonText
                }
            });
            if (result.errors?.length) {
                toaster.negative(result.errors.map(x => x.message), {});
            } else {
                toaster.positive(`Commentaire ${isValid ? 'accepté' : 'banni'} avec succès`, {});
                close(true);
            }
        }
        catch (err: any) {
            toaster.negative(err.message, {})
        }
    }


    const path = comment ? comment?.participation?.media.type === GqlChallengeType.VIDEO ? ImageUtils.makeUrl(comment.participation.path)
        : ImageUtils.getResizedImageUrl(comment.participation.path, 200) : '';
    // const words = getWords(comment?.participation);
    return (
        /*@ts-ignore*/
        <Modal key={comment?.id} overrides={{ Root: { style: { zIndex: 999 } } }} isOpen={isOpen} closeable onClose={_ => close(false)} size={SIZE.full}>
            <BannishingReasonChoices close={onReasonClose} isVisible={showReasonModal} onValueChange={onReasonChange} isComment={true} />
            <ModalHeader>Détail des signalements</ModalHeader>
            <ModalBody>
                <Grid gridColumns={12} gridGutters={5} gridMargins={0}>
                    <Cell span={[12, 4, 4]}>
                        <LabelMedium> Média </LabelMedium>
                        <span style={{ margin: '0 auto' }}>{ }</span>
                        {comment?.participation?.media.type === GqlChallengeType.VIDEO ?
                            <video src={ImageUtils.makeUrl(comment.participation.path)} autoPlay muted controls style={{ boxShadow: '0 0 8px #bbb', width: '100%' }} />
                            :
                            <Image url={path || ''} style={{ boxShadow: '0 0 8px #bbb', width: '200px' }} />
                        }
                        {/* {words && "Mots: " + words} */}
                        <div>Utilisateur: <Username userId={comment?.accountId} /></div>
                        <LabelLarge marginTop={['20px']}>Commentaire</LabelLarge>
                        <Block $style={{ border: '1px solid' }} marginRight={['50px']} padding={['10px']}>{comment?.content}</Block>
                    </Cell>
                    <Cell span={[12, 8, 8]}>
                        <LabelMedium> Signalement(s)</LabelMedium>

                        {/*@ts-ignore*/}
                        <Table
                            isLoading={complaintsPerComment.loading}
                            overrides={{ TableBodyCell: { style: { minWidth: '130px', overflow: 'auto' } } }}
                            columns={["Date", "Utilisateur", "Autre détail"]}
                            data={complaintsPerComment.data?.commentComplaints.nodes.map(x => [
                                format(new Date(x.dateCreated), 'dd MMM yyyy', { locale: frCA }),
                                <Username userId={x.accountId} />,
                                x.otherDetails
                            ])}
                        ></Table>
                    </Cell>
                </Grid>
            </ModalBody>
            <ModalFooter>
                {/*@ts-ignore*/}
                <ModalButton isLoading={moderateComment[1].loading} disabled={moderateComment[1].loading} onClick={_ => moderate(true)}>Le commentaire est valide <IoIosCheckmark color="#66ff66" style={{ margin: -10, marginLeft: 5 }} size={40} /> </ModalButton>
                {/*@ts-ignore*/}
                <ModalButton isLoading={moderateComment[1].loading} disabled={moderateComment[1].loading} onClick={_ => setShowReasonModal(true)}>Bannir le commentaire <IoIosClose color="#f33" style={{ margin: -10 }} size={40} /></ModalButton>
            </ModalFooter>
        </Modal>
    )
}


function getWords(participation: any) {
    function mapWords(x: any) {
        return x.word.translations.filter(t => t.languageId === 'fr')[0].value
    }

    const result = (participation?.word_Participations?.length ?? []) > 0 ?
        participation?.word_Participations?.map(mapWords).join(' - ')
        : participation?.challenge?.word_Challenges?.map(mapWords).join(' - ') ?? '';

    console.log(result)
    return result ?? '';

}