import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { languageString } from "../../types/graphql/PeriodicChallengeInput";

const GET_Comments_WITH_COMPLAINTS = gql`
query commentsToModerate($cursor:String) {
    commentsToModerate(after:$cursor, first:20, excludeAlreadyModerated:true) {
        nodes {
            content
            id
            participationId
            accountId
            moderationInfos {
                complaintCount
                dateVerified
                isBanned
                verifierId
            }
            participation {
                path
                media { type }
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
        totalCount
    }
}
`;

const COMPLAINT_PER_COMMENT = gql`
query GetCommentComplaints($id:ID!){
    commentComplaints(where:{commentId: $id }) {
      nodes {
        id
        otherDetails
        dateCreated
        accountId
      }
    }
  }
`

const MODERATE_COMMENT = gql`
mutation moderateComment($id:ID!, $isValid: Boolean!, $bannedReason: BannedReason, $bannedReasonText:[LanguageStringInput]){
  moderateComment(commentId:$id, isValid:$isValid, bannedReason: $bannedReason, bannedReasonText: $bannedReasonText){
    id
    moderationInfos {
      complaintCount
      dateVerified
      isBanned
      verifierId
    }
  }
}
`

const COMMENT_COMPLAINTS_COUNT = gql`
{
  commentsToModerate(excludeAlreadyModerated:true) {
    totalCount
  }
}
`

export function useCommentComplaintQueries() {
    const commentsToModerate = useQuery<{commentsToModerate: any}, { cursor?: string }>(GET_Comments_WITH_COMPLAINTS, { fetchPolicy: 'standby', nextFetchPolicy: 'cache-and-network' });
    const complaintsPerComment = useQuery<{ commentComplaints: any }, { id: string }>(COMPLAINT_PER_COMMENT);
    const moderateComment = useMutation<any, { id: string, isValid: boolean, bannedReason?: string, bannedReasonText?: languageString[] }>(MODERATE_COMMENT);
    const moderationCountQuery = useQuery<{ commentsToModerate: { totalCount: number } }>(COMMENT_COMPLAINTS_COUNT, { fetchPolicy: 'standby' });

    return {
        commentsToModerate,
        complaintsPerComment,
        moderateComment,
        moderationCountQuery
    }
}