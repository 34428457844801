export interface UserInfos {
    email:string;
    username: string;
    id: string;
    roles: string | UserRoles | UserRoles[]
}

export enum UserRoles {
    admin = 0, mobile = 1
}

export type AccountRoles = "ADMIN" | "MOBILE"

export interface Account extends Omit<UserInfos, "roles"> {
    credits:        number
    firstname:      string
    lastname:       string
    roles: AccountRoles | AccountRoles[]
    media: {
        id
        path
    }
    preferredLanguage
    totalPoints:    number
}