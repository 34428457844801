import React, { useEffect } from 'react'

import Container from '../components/UiElements/Container/Container'
import usePlayoffQueries from '../hooks/queries/usePlayoffQueries'
import PlayoffListing from '../components/Playoffs/PlayoffListing'


export default function PlayoffsScreen() {
    const playoffQuery = usePlayoffQueries();

    useEffect(() => {
        playoffQuery.fetch();
    }, []);

    return (
        <Container>
            <PlayoffListing refresh={playoffQuery.query.refetch} loading={playoffQuery.query.loading} data={playoffQuery.query.data?.allPlayoffs} />
        </Container>
    )
}