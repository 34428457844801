import { gql, useMutation, useQuery } from '@apollo/client'
import { Accordion, Panel } from 'baseui/accordion';
import { Button } from 'baseui/button';
import { toaster } from 'baseui/toast';
import React, { useState } from 'react'
import CashSeasonEndSettingsTable from '../components/AppSettingsTables/CashSeasonEndSettingsTable';
import CashSingleChallengeSettingsTable from '../components/AppSettingsTables/CashSingleChallengeSettingsTable';
import CreditsTable from '../components/AppSettingsTables/CreditsTable';
import PointsTable from '../components/AppSettingsTables/PointsTable';
import PageWrapper from '../components/layout_old/PageWrapper'
import Loader from '../components/UiElements/Loader/Loader';
import { APP_CONFIGURATION_QUERY, GqlAppConfigurationQueryResult } from '../types/graphql/AppConfiguration';


const SAVE_MUTATION = gql`
    mutation saveData($newData:[KeyValuePairOfStringAndStringInput!] ){
        savePointsAndCreditsConfiguration(newData:$newData)
    }
`;

export default function AppSettingsScreen() {
    const settingsQuery = useQuery<GqlAppConfigurationQueryResult>(APP_CONFIGURATION_QUERY);
    const [saveData, saveMutation] = useMutation<boolean>(SAVE_MUTATION);
    const [modifiedFields, setModifiedFields] = useState<Map<string, string>>(new Map());
    const settings = settingsQuery.data?.settings;

    function onValueChange(key: string, value: string) {
        setModifiedFields(prev => {
            const newVal = new Map(prev).set(key, value);
            return newVal;
        });
    }

    async function onSaveClick() {
        const data: { key: string, value: string }[] = [];
        modifiedFields.forEach((value, key) => { data.push({ key, value }); });
        const res = await saveData({ variables: { newData: data } });

        if (res.data) {
            toaster.positive("Modification sauvegardées", {});
            setModifiedFields(new Map());
        } else {
            toaster.positive("Une erreur est survenue", {});
        }
    }

    return (
        <PageWrapper>
            {settingsQuery.loading || !settings ? <Loader />
                :
                (
                    <div style={{ margin: '0 auto' }}>
                        <Accordion >
                            <Panel title="Crédits" >
                                <CreditsTable onValueChange={onValueChange} loading={saveMutation.loading} settings={settings.credits} />
                            </Panel>
                            <Panel title="Points">
                                <PointsTable onValueChange={onValueChange} loading={saveMutation.loading} settings={settings.points} />
                            </Panel>
                            {/* <Panel title="Argent">
                                <CashSeasonEndSettingsTable onValueChange={onValueChange} loading={saveMutation.loading} settings={settings.cashPrizes} />
                                <CashSingleChallengeSettingsTable onValueChange={onValueChange} loading={saveMutation.loading} settings={settings.cashPrizes} />
                            </Panel> */}
                        </Accordion>
                        

                        <Button onClick={e => onSaveClick()} disabled={saveMutation.loading || modifiedFields.size == 0}>Enregister</Button>
                    </div>
                )
            }
        </PageWrapper>
    )
}


