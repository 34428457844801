import { gql, useMutation, useQuery } from "@apollo/client";
import { CreateFeaturedAppDTO, FeaturedApps, UpdateFeaturedAppDTO } from "../../types/graphql/FeaturedApp";
const fields = `
    id
    name
    order
    dateCreated
    dateDeleted
    description {
        language
        value
    }
    mediaId
    media {
        id
        path
    }
    webUrl
    androidUrl
    iOSUrl
`
const QUERY = gql`
{
    featuredApps {${fields}}
}
`;

const UPDATE = gql`
mutation updateFeaturedApp($dto: UpdateFeaturedAppDTOInput) {
    updateFeaturedApp(dto:$dto){${fields}}
  }
`;


const CREATE = gql`
mutation createFeaturedApp($dto: CreateFeaturedAppDTOInput) {
    createFeaturedApp(dto:$dto){${fields}}
  }
`;

const DELETE = gql`
mutation deleteFeaturedApp($id: ID!) {
    deleteFeaturedApp(id:$id){
        id
        dateDeleted
    }
  }
`;


export default function useFeaturedAppsQuery() {
    const query = useQuery<{ featuredApps: FeaturedApps[] }>(QUERY);

    return query;
}

export function useFeaturedAppsMutations() {
    const [update, updateMutation] = useMutation<{ featuredApp: FeaturedApps }, { dto: UpdateFeaturedAppDTO }>(UPDATE);
    const [create, createMutation] = useMutation<{ featuredApp: FeaturedApps }, { dto: CreateFeaturedAppDTO }>(CREATE);
    const [performDelete, deleteMutation] = useMutation<{ featuredApp: FeaturedApps }, { id: string }>(DELETE);

    return {
        update,
        updateMutation,
        create,
        createMutation,
        performDelete,
        deleteMutation
    }
}