import React, { useState } from 'react'
import WordsList from '../components/listings/WordsList'
import AddWordsModal from '../components/modals/AddWordsModal.web';
import { Plus } from 'baseui/icon'
import { Button } from 'baseui/button';
import Container from '../components/UiElements/Container/Container';


const WordsScreen = () => {
    const [showModal, setShowModal] = useState(false);
    const [refetchTrigger, setRefetchTrigger] = useState(false);
    const onModalHide = (refetch = false) => {
        setShowModal(false);
        if (refetch)
            setRefetchTrigger(!refetchTrigger)
    }

    return (
        <Container>
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                            <Button onClick={e => setShowModal(true)}><Plus title="Ajouter des mots" />Ajouter des mots</Button>
                    </div>

                    <WordsList refetchTrigger={refetchTrigger} style={{ flex: 1, paddingBottom: 30, }} />
            {showModal ? <AddWordsModal onHide={onModalHide} /> : <></>}
    </Container>
    )
}

export default WordsScreen