import React, { ReactElement, useState } from 'react'
import { Accordion, Panel } from 'baseui/accordion'
import DaterangePicker from '../UiElements/DaterangePicker/DaterangePicker'
import { StatefulSelect } from 'baseui/select'
import { LabelLarge } from 'baseui/typography'
import { Cell, Grid } from 'baseui/layout-grid'
import { useStyletron } from 'baseui'
import { GqlDurationType, GqlPeriodicChallengeFrame } from '../../types/graphql/PeriodicChallengeInput'
interface Props {
    onDurationTypeChange: (types: GqlDurationType[]) => void;
    onPeriodChange: (dates: Date[]) => void;
    onFrameChange: (frames: GqlPeriodicChallengeFrame[]) => void;
}

export default function PeriodicChallengeFilters({ onFrameChange, onPeriodChange, onDurationTypeChange }: Props): ReactElement {
    const [css] = useStyletron();

    const labelStyle = {
        display: 'flex',
        alignItems: 'center'
    }
    return (
        <Accordion>
            <Panel title="Filtrer" overrides={{ Header: { style: { background: 'lightgray' } } }}>
                <Grid gridColumns={12} gridGaps={0} gridGutters={0}>
                    <Cell span={2}>
                        <LabelLarge flex='1 100px' height="100%" display="flex" alignItems='center'>Date</LabelLarge>
                    </Cell>
                    <Cell span={10}>
                        <DaterangePicker clearable onChange={onPeriodChange} initialValue={[]} />
                    </Cell>

                    <Cell span={2}>
                        <LabelLarge flex='1 100px' height="100%" display="flex" alignItems='center'>Type</LabelLarge>
                    </Cell>
                    <Cell span={10}>
                        <StatefulSelect
                            multi={true}
                            value={[{ id: 'month', label: 'Mois' },]}
                            onChange={e => onDurationTypeChange(e.value.map(x => x.id as GqlDurationType))}
                            options={[
                                { id: GqlDurationType.Monthly, label: 'Mois' },
                                { id: GqlDurationType.Weekly, label: 'Semaine' },
                                { id: GqlDurationType.Daily, label: 'Jour' },
                                { id: GqlDurationType.Hourly, label: 'Heure' }
                            ]} />
                    </Cell>

                    <Cell span={2}>
                        <LabelLarge flex='1 100px' height="100%" display="flex" alignItems='center'>Frame</LabelLarge>
                    </Cell>
                    <Cell span={10}>
                        <StatefulSelect
                            onChange={e => onFrameChange(e.value.map(x => x.id as GqlPeriodicChallengeFrame))}
                            options={[
                                { id: GqlPeriodicChallengeFrame.Image, label: 'Image' },
                                { id: GqlPeriodicChallengeFrame.Words, label: 'Mots' },
                            ]} />
                    </Cell>


                </Grid>
            </Panel>
        </Accordion>
    )
}
