import React from 'react'
import { DatePicker } from 'baseui/datepicker'
import { FormControl } from 'baseui/form-control'
import { Radio, RadioGroup } from 'baseui/radio'
import frCA from 'date-fns/locale/fr-CA'
import { PlayoffChallengeFormType } from '../../../hooks/usePlayoffChallengeFormFields'
import Playoff from '../../../models/Dto/Playoff'
import { GqlPeriodicChallengeFrame } from '../../../types/graphql/PeriodicChallengeInput'
import { PlayoffChallengeInputType } from '../../../hooks/queries/usePlayoffQueries'

type Props = {
    formFields: PlayoffChallengeFormType
    playoff: Playoff | undefined,
    onChange: (key: keyof PlayoffChallengeFormType, value: any) => void
    errors?: Partial<Record<keyof PlayoffChallengeInputType,string[]>>
}

export default function PlayoffChallengeDatesForm({formFields, playoff, onChange, errors }: Props) {
    return (<>
        <FormControl label="Dates de participation" error={errors?.dateParticipationStart ?? errors?.dateParticipationEnd}>
            <DatePicker
                range
                startDateLabel='Du'
                endDateLabel='Au (exclu)'
                separateRangeInputs
                value={formFields.participationDates}
                minDate={playoff && new Date(playoff.dateStart)}
                maxDate={playoff && new Date(playoff.dateEnd)}
                highlightedDate={playoff && new Date(playoff.dateStart)}
                aria-label="Date"
                clearable={true}
                onChange={dates => onChange('participationDates', dates.date as Date[])}
                locale={frCA}
            />
        </FormControl>
        <FormControl label="Dates de vote" error={errors?.dateVoteStart ?? errors?.dateVoteEnd}>
            <DatePicker
                range
                startDateLabel='Du'
                endDateLabel='Au (exclu)'
                separateRangeInputs
                value={formFields.voteDates}
                minDate={playoff && new Date(playoff.dateStart)}
                maxDate={playoff && new Date(playoff.dateEnd)}
                highlightedDate={playoff && new Date(playoff.dateStart)}
                aria-label="Date"
                clearable={true}
                onChange={dates => onChange('voteDates', dates.date as Date[])}
                locale={frCA}
            />
        </FormControl>

        <FormControl label="Frame" error={errors?.periodicChallengeFrame}>
            <RadioGroup
                required
                // disabled={challengeMutation.loading}
                value={formFields.frame}
                align="horizontal"
                onChange={e => onChange('frame', e.target.value)}
            >
                <Radio value={GqlPeriodicChallengeFrame.Image}>Image</Radio>
                <Radio value={GqlPeriodicChallengeFrame.Words}>Mots</Radio>
            </RadioGroup>
        </FormControl>
    </>)
}