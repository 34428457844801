import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { EnvConstants } from './constants/EnvConstants';
import { apiServiceInstance } from './services/ApiService';

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = apiServiceInstance.getJwt();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });
  
  const httpLink = createHttpLink({
    uri: EnvConstants.getEnvConst().apiUrl + 'graph'
  });
  
  const apollo = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies:{
        Query:{
          fields:{
            allParticipations: relayStylePagination(),
            participationsToModerate: relayStylePagination(),
            accountListingItems: relayStylePagination(),
            commentsToModerate: relayStylePagination(),
          }
        }
      }
    }),
    
  });

  export default apollo;