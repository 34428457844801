// import { GoogleLogInConfig } from 'expo-google-app-auth';

// const env = 'dev'
 const env = 'staging'
// const env = 'prod'


//updated by deployment script. Do not touch
const version="0.20-6-g360b4bc";
const ngrok = "https://634a-96-20-246-251.ngrok.io";

export class EnvConstants {

    static getEnvConst(): envConst {
        if (env.indexOf('dev') === 0)
            return this.dev;

        if (env.indexOf('prod') !== -1)
            return this.prod

        if (env.indexOf('staging') !== -1)
            return this.staging

        return this.dev;
    }

    static dev: envConst = {
        envName: "DEVELOPMENT",
        version,
        apiUrl: `${ngrok}/api/`,
        facebookAppLoginConfig: {id:"2766323876958722", name: "PV Random Challenge"},
        cdnResizedImagesUrl: 'https://d1mb2sd06qch8v.cloudfront.net/image',
        cdnShareUrl: `${ngrok}/html/share`,
        cdnSourceUrl: 'https://d1j198nb075g62.cloudfront.net',
    }
    static staging: envConst = {
        envName: "STAGING",
        version,
        facebookAppLoginConfig: {id:"2766323876958722", name: "PV Random Challenge"},
        apiUrl: 'https://pv-api.dbern.net/api/',
        cdnResizedImagesUrl: 'https://d1mb2sd06qch8v.cloudfront.net/image',
        cdnShareUrl: 'https://share-staging.pvrandom-challenge.com',
        cdnSourceUrl:'https://d1j198nb075g62.cloudfront.net',
    }
    static prod: envConst = {
        envName: "PRODUCTION",
        version,
        facebookAppLoginConfig: {id:"2766323876958722", name: "PV Random Challenge"},
        apiUrl: 'https://api.pvrandom-challenge.com/api/',
        cdnShareUrl: 'https://share.pvrandom-challenge.com',
        cdnResizedImagesUrl: 'https://dj25v47b2v5t3.cloudfront.net/image',
        cdnSourceUrl:'https://d1dvrrukb5sikg.cloudfront.net',
    }
}

interface envConst {
    envName: "DEVELOPMENT" | "STAGING" | "PRODUCTION";
    apiUrl: string;
    version:string;
    cdnResizedImagesUrl:string;
    cdnSourceUrl:string;
    cdnShareUrl:string;
    facebookAppLoginConfig: {id:string, name: string};
}

