import React, { ReactElement } from 'react'
import { styled } from 'baseui';

export const PeriodicChallengeTableHeaderCell = styled('div', ({ $theme }) => ({
    flex: 1,
    border: '1px solid lightgray',
    padding: '8px 15px',
    margin: '-0.5px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: $theme.colors.buttonSecondaryText
}))
