import { Block } from 'baseui/block'
import React from 'react'

export default function UnauthorizedScreen() {
    return (
        <Block $style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '60vh' }}>
            You are not authorized to access this content<br /><br />
            This app is reserved to PVRC administrators only.
            <div style={{ marginTop: 40 }}>
                <a href="https://www.pvrandom-challenge.com">Visit website</a>
            </div>
        </Block>
    )
}
