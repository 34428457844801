import { Button } from 'baseui/button';
import { Input } from 'baseui/input';
import { StyledTableBodyCell } from 'baseui/table-semantic';
import React, { ReactElement, useState } from 'react'
import { FiRefreshCw } from 'react-icons/fi';

interface Props {
    step?: number,
    disabled: boolean,
    name: string,
    initialValue: string,
    colSpan?: number,
    onChange: (key: string, value: string) => void
}

export default function CellInput(props: Props) {
    const [value, setValue] = useState(Number.parseFloat(props.initialValue));
    function onChange(e: React.FormEvent<HTMLInputElement>) {
        setValue(e.currentTarget.valueAsNumber);
        props.onChange(props.name, e.currentTarget.value)
    }
    function reset() {
        setValue(Number.parseInt(props.initialValue));
        props.onChange(props.name, props.initialValue);
    }

    return (
        <StyledTableBodyCell colSpan={props.colSpan}>
            <div style={{ display: 'flex' }}>
                {/*@ts-ignore*/}
                <Input disabled={props.disabled} min={Number.MIN_SAFE_INTEGER} size="compact" type="number" step={props.step} value={value} onChange={onChange} />
                <Button size="mini" onClick={reset} disabled={props.disabled} $style={{ backgroundColor: 'lightgrey' }} >
                    <FiRefreshCw color="black" />
                </Button>
            </div>
        </StyledTableBodyCell>
    )

}
