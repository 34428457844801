import { styled } from "baseui";
import React, { useState } from "react";


const Container = styled('p', {
    display: 'inline',
    width: '100%'
})
const Toggler = styled('span', {
    color: 'rgb(192,192,192)',
    cursor: 'pointer',
})

const ReadMore = ({ children }) => {
    const text = children ?? '';
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <Container>
            {isReadMore ? text.slice(0, 150) : text}
            {text.length > 150 &&
                <Toggler onClick={toggleReadMore} className="read-or-hide">
                    <br />
                    {isReadMore ? "...voir plus" : " voir moins"}
                </Toggler>
            }
        </Container>
    );
};

export default ReadMore;