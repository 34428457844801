import React, { useRef, useState } from 'react'
import StandardWordScheduler from '../components/StandardWordScheduler/StandardWordScheduler'
import { addMonths, endOfMonth, startOfMonth, } from 'date-fns';
import DaterangePicker from '../components/UiElements/DaterangePicker/DaterangePicker';
import { LabelLarge, LabelSmall } from 'baseui/typography';
import { ALIGNMENT, BEHAVIOR, Cell, Grid } from 'baseui/layout-grid';
import { Button } from 'baseui/button';
import { FiPlus, FiSave } from 'react-icons/fi';
import { gql, useMutation } from '@apollo/client';
import { GQL_WordsAndPeriods_Input } from '../types/graphql/WordsAndPeriodsInput';
import { colors } from 'baseui/tokens';
import { toaster } from "baseui/toast";
import AddPeriodModal from '../components/StandardWordScheduler/AddPeriodsModal/AddPeriodModal';
import { GQLWordPeriod } from '../types/graphql/AllPeriods';
import PageWrapper from '../components/layout_old/PageWrapper';

const SAVE_MUTATION = gql`
mutation saveWords($periods: [PeriodWordsInputTypeInput]){
    assingWordsToMultiplePeriods(periods:$periods)
}
`;


const PlanificationScreen = () => {
    const [selectedRange, setSelectedRange] = useState<Date[]>([addMonths(startOfMonth(new Date()), -1), addMonths(endOfMonth(new Date()), 1)]);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [errors, setErrors] = useState<{ periodId: string, groupOrder: number, wordOrder: number }[]>([]);
    const [serverErrors, setServerErrors] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [refreshDataToggler, setrefreshDataToggler] = useState(false);
    const [saveWords, saveWordsResult] = useMutation<boolean, { periods: GQL_WordsAndPeriods_Input.PeriodWordsInputType[] }>(SAVE_MUTATION);
    const dataToSave = useRef<GQL_WordsAndPeriods_Input.PeriodWordsInputType[]>([]);
    const allData = useRef<GQL_WordsAndPeriods_Input.PeriodWordsInputType[]>([]);

    function onRangeValueChange(dates: Date[]) {
        setSelectedRange(dates);
    }
    function onDataChange(newData: GQL_WordsAndPeriods_Input.PeriodWordsInputType[], allPeriods: GQL_WordsAndPeriods_Input.PeriodWordsInputType[]) {
        setSaveDisabled(newData.length == 0);
        setServerErrors([])
        setErrors([]);
        dataToSave.current = newData;
        allData.current = allPeriods;
    }

    async function onSaveClicked() {
        setSaveDisabled(true);
        const periodIds = dataToSave.current.map(x => x.periodId);
        const data = allData.current.filter(x => periodIds.indexOf(x.periodId) >= 0);

        if (data.length == 0)
            return;

        const errors = detectErrors(data);

        if (errors.length === 0) {
            try {
                await saveWords({ variables: { periods: data }, });
                toaster.positive("Savegarde réussie", { autoHideDuration: 2000 });
            } catch (err) {
                setServerErrors(saveWordsResult.error?.message.split('\n') ?? []);
                toaster.negative("Erreur lors de la sauvegarde", {})
            }
        }
    }

    function detectErrors(data: GQL_WordsAndPeriods_Input.PeriodWordsInputType[]) {
        const newErrors: typeof errors = []
        //Look for missing words
        data.forEach(x => {
            [0, 1, 2, 3].forEach(groupOrder => {
                const currentGroupIdx = x.wordGroups.findIndex(g => g.order == groupOrder);
                if (currentGroupIdx === -1) {
                    [0, 1, 2].forEach(wordOrder => { newErrors.push({ periodId: x.periodId, groupOrder, wordOrder }) });
                } else {
                    const currentGroup = x.wordGroups[currentGroupIdx];
                    [0, 1, 2].forEach(wordOrder => {
                        const currentWordIdx = currentGroup.word_WordGroups.findIndex(w => w.order == wordOrder);
                        if (currentWordIdx == -1 || currentGroup.word_WordGroups[currentWordIdx].wordId == "") {
                            newErrors.push({ periodId: x.periodId, groupOrder: currentGroup.order, wordOrder });
                        }
                    })
                }
            })
        });

        //Look for duplicates
        const flattened = data.map(x => ({ periodId: x.periodId, words: x.wordGroups.flatMap(g => g.word_WordGroups.map(w => ({ groupOrder: g.order, wordOrder: w.order, wordId: w.wordId }))) }));
        flattened.forEach(period => {
            period.words.forEach(w => {
                const existing = period.words.filter(x => x.wordId == w.wordId && x.wordOrder != w.wordOrder && x.groupOrder != w.groupOrder);
                if (existing.length)
                    newErrors.push({ periodId: period.periodId, groupOrder: w.groupOrder, wordOrder: w.wordOrder });
            })
        })
        setErrors(newErrors)
        return newErrors;
    }

    function onPeriodCreated(p: GQLWordPeriod[]) {

        const periods = p.map(x => ({ ...x, dateStart: new Date(x.dateStart!), dateEnd: new Date(x.dateEnd!) }));
        periods.sort((a, b) => a.dateStart > b.dateStart ? 1 : -1);

        if (periods[0].dateStart >= selectedRange[0] && periods[0].dateStart <= selectedRange[1])
            setrefreshDataToggler(!refreshDataToggler);
    }

    return (
        <PageWrapper>
            <Grid gridColumns={12} align={ALIGNMENT.center} gridGutters={0} behavior={BEHAVIOR.fluid}>
                <Cell span={2} align={ALIGNMENT.center}>
                    <LabelLarge $style={{ whiteSpace: 'nowrap' }}>Filtre par date : </LabelLarge>
                </Cell>
                <Cell span={2}>
                    <DaterangePicker onChange={onRangeValueChange} />
                </Cell>
                <Cell span={3} skip={1}>
                    {serverErrors.map((e, idx) => <LabelSmall key={idx} color={colors.red600} >{e}</LabelSmall>)}
                </Cell>

                <Cell span={2} skip={2}>
                    <Button disabled={saveDisabled} type="submit" isLoading={saveWordsResult.loading} onClick={onSaveClicked}> <FiSave size="20" style={{ marginRight: 10 }} /> Enregistrer</Button>
                </Cell>
            </Grid>

            <StandardWordScheduler refreshToggler={refreshDataToggler} errors={errors} onDataChange={onDataChange} startDate={selectedRange[0]} endDate={selectedRange[1]} />

            <Button onClick={() => setIsModalOpen(true)}><FiPlus />&nbsp;&nbsp;Ajouter des périodes</Button>

            <AddPeriodModal isOpen={isModalOpen} close={() => setIsModalOpen(false)} onSave={onPeriodCreated} />
        </PageWrapper>

    )
}

export default PlanificationScreen

