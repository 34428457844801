import { useQuery } from '@apollo/client';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { Card, StyledAction, StyledBody } from 'baseui/card';
import gql from 'graphql-tag';
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom';
import Loader from '../UiElements/Loader/Loader';
const QUERY = gql`{activeAccountsCount(lastDays:30)}`;

export default function HomeActiveUsers(): ReactElement {
    const query = useQuery(QUERY, { fetchPolicy: 'cache-and-network' });

    return (
        <Card title="Comptes actifs"
            overrides={{
                Root: { style: { width: '200px', margin:'5px' } },
                Title: { style: { textAlign: 'center' } }
            }}
        >
            <StyledBody >
                {query.loading ?
                    <Loader />
                    :
                    <Block $style={{textAlign:'center'}}>
                        30 derniers jours
                        <Block display="flex" padding="20px" $style={{ fontSize: '30px' }} justifyContent="center">
                            {query.data?.activeAccountsCount} <br />
                        </Block>
                    </Block>
                }
            </StyledBody>
            <StyledAction>
                <Link to="/users">
                    <Button overrides={{ BaseButton: { style: { width: '100%' } } }}>
                        Voir tout
                    </Button>
                </Link>
            </StyledAction>
        </Card>
    )
}
