import { GqlWord } from "./AllPeriods"

export type WordsQueryResult = {
    allWords: {
        totalCount: number,
        nodes: GqlWord[]
    }
}
export enum GQLWordCount {
    Undefined = "UNDEFINED",
    One = "ONE",
    Two = "TWO",
    Three = "THREE",
    Four = "FOUR",
}