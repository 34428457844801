import { Button } from 'baseui/button'
import { DatePicker, StatefulDatepicker } from 'baseui/datepicker'
import { StyledErrorMessage } from 'baseui/file-uploader'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import { frCA } from 'date-fns/locale'
import { GraphQLError } from 'graphql'
import React, { useEffect, useState } from 'react'
import usePlayoffQueries from '../../hooks/queries/usePlayoffQueries'
import Playoff from '../../models/Dto/Playoff'
import { utcToZonedTime } from 'date-fns-tz';
import { toaster } from 'baseui/toast'

type Props = {
    show: boolean;
    close: () => void;
    onSave: () => void;
    currentPlayoff?: Playoff
}

export default function CreateEditPlayoffModal({ currentPlayoff, show, close, onSave }: Props) {
    const [dates, setDates] = useState<Date[]>([]);
    const { save } = usePlayoffQueries();
    const [errors, setErrors] = useState<readonly GraphQLError[]>([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setDates([]);
    }, [show]);

    useEffect(()=>{
        if(currentPlayoff)
            setDates([new Date(currentPlayoff.dateStart), new Date(currentPlayoff.dateEnd)]);
        else 
            setDates([]);
    },[currentPlayoff])

    function cancel(){
        setErrors([]);
        setDates([]);
        close();
    }

    async function performSave() {
        if (loading)
            return;

        setLoading(true);

        try {
            const result = await save({ variables: { dateStart: dates[0], dateEnd: dates[1], id: currentPlayoff?.id }, errorPolicy: 'all' });
            if (result.errors?.length) {
                setErrors(result.errors)
            } else {
                toaster.positive("Playoff sauvegardé",{})
                close();
                onSave();
                setDates([]);
                setErrors([])
            }
        } catch(err) {
            console.error(err)
        }
        finally {
            setLoading(false);
        }
    }

    function onDateChange(dates: Date[]){
        const values = dates.map(x=>utcToZonedTime(x, 'America/Toronto'));
        setDates(values)
    }


    return !show ? <></> :(
        <Modal isOpen={show} onClose={cancel}>
            <ModalHeader>{currentPlayoff ? '' :'Nouveaux'} Playoffs</ModalHeader>
            <ModalBody>
                <DatePicker
                    aria-label="Date"
                    clearable={true}
                    onChange={(dates) => onDateChange(dates.date as Date[]) }
                    startDateLabel="Premier jour"
                    endDateLabel="Date de fin (exclue)"
                    value={dates}
                    highlightedDate={new Date()}
                    range
                    separateRangeInputs
                    locale={frCA}
                />
            </ModalBody>
            {errors && errors.length > 0 && <div style={{textAlign:'center'}}>{errors.map((x, i) => (<StyledErrorMessage key={i}>{x.message}</StyledErrorMessage>))}</div>}
            <ModalFooter>
                <Button onClick={performSave} disabled={dates.length != 2} isLoading={loading}>Enregistrer</Button>
            </ModalFooter>

        </Modal>
    )
}