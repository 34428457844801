import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import Playoff, { PlayoffChallenge } from '../../models/Dto/Playoff'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import { periodToString } from '../../utils/DateUtils';
import { FaEye, FaPlusCircle } from 'react-icons/fa';
import CreateEditPlayoffModal from './CreateEditPlayoffModal';
import PlayoffChallengeModal from './PlayoffsChallenges/PlayoffChallengeModal';
import sortBy from 'lodash/sortBy'

type Props = {
    playoff: Playoff,
    refresh: () => void
}

export default function PlayoffListingRow({ playoff, refresh }: Props) {
    const [open, setOpen] = React.useState(false);
    const [selectedPlayoff, setSelectedPlayoff] = React.useState<Playoff>();
    const selectedChallenge = React.useRef<{ challenge?: PlayoffChallenge, playoff: Playoff }>();
    const [showChallengeModal, setShowChallengeModal] = React.useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, }}>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell colSpan={4}>
                    <Typography>{periodToString(playoff.dateStart, playoff.dateEnd)} <br /><small className="muted">(Date de fin exclue)</small></Typography>
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => setSelectedPlayoff(playoff)}>
                        <EditIcon />
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ width: '5px' }}></TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Box display='flex' alignItems="center">
                                <Typography variant="h6" gutterBottom component="div" marginRight={5}>
                                    Challenges
                                </Typography>
                                <IconButton onClick={() => { selectedChallenge.current = { challenge: undefined, playoff }; setShowChallengeModal(true) }}><FaPlusCircle /></IconButton>
                            </Box>
                            {
                                playoff.challenges.length == 0 ?
                                    <span>Aucun challenge</span>
                                    :
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Dates</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Round</TableCell>
                                                <TableCell>Participations</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                sortBy(playoff.challenges, 'round').map((challenge) => (
                                                    <TableRow key={challenge.id}>
                                                        <TableCell component="th" scope="row">
                                                            <div>Participation: {periodToString(challenge.dateParticipationStart, challenge.dateParticipationEnd)}</div>
                                                            <div>Vote: {periodToString(challenge.dateVoteStart, challenge.dateVoteEnd)}</div>
                                                        </TableCell>
                                                        <TableCell>
                                                            {challenge.periodicChallengeFrame}
                                                        </TableCell>
                                                        <TableCell>
                                                            {challenge.round}{challenge.isUltimate ? ' - Ultimate' : ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {challenge.participationCount}
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton onClick={() => alert('à venir')}>
                                                                <FaEye></FaEye>
                                                            </IconButton>
                                                            <IconButton disabled={(challenge.participationCount ?? 0) > 0} onClick={() => { selectedChallenge.current = { challenge: challenge, playoff }; setShowChallengeModal(true) }}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                            }
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            <CreateEditPlayoffModal onSave={refresh} show={selectedPlayoff !== undefined} currentPlayoff={selectedPlayoff} close={() => setSelectedPlayoff(undefined)} />
            {selectedChallenge.current && <PlayoffChallengeModal playoff={selectedChallenge.current!.playoff} show={showChallengeModal} challenge={selectedChallenge.current?.challenge} close={() => { selectedChallenge.current = undefined; setShowChallengeModal(false) }} />}
        </>
    )
}