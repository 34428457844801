import Axios, { AxiosResponse, CancelToken } from "axios";
import { EnvConstants } from "../constants/EnvConstants";
import { MediaUploadResult } from "../models/Dto/MediaUploadResult";
// import { AuthenticationData } from "../redux/reducers/authentication/types";


export const authDataStorageKey = 'authData'

class ApiService {
    private baseUrl = EnvConstants.getEnvConst().apiUrl
    private jwt: string | null = null;

    public getJwt(): string | null {
        return this.jwt;
    }

    public getHeaders() {
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.jwt ? 'Bearer ' + this.jwt : '',
        }
    }
    constructor() {
        const x = localStorage.getItem(authDataStorageKey);
        if (!x)
            return

        const data = JSON.parse(x) //as AuthenticationData;
        this.jwt = data.token

    }

    public async updateJWT(newVal: string) {
        // await SecureStore.setItemAsync('jwt', newVal)
        this.jwt = newVal;
    }

    async get<TReturn>(url: string) {
        const h = this.getHeaders();
        return fetch(this.baseUrl + url, {
            method: 'GET',
            headers: h,
        }).then(x => {
            if (x.ok)
                return x.json() as Promise<TReturn>;
            else throw x.statusText
        })
    }

    async post<TReturn>(url: string, body?: any) {
        return fetch(this.baseUrl + url, {
            method: 'POST',
            headers: this.getHeaders(),
            body: body ? JSON.stringify(body) : null
        }).then(x => {
            if (!x.ok)
                return x.text() as Promise<any>;
            return x.json() as Promise<TReturn>;
        })
    }

    public async uploadAdMedia(file: File, options?:{ onUploadProgress?: (event: ProgressEvent<EventTarget>) => void, cancelToken:CancelToken}) {

        // Upload the image using the fetch and FormData APIs
        const formData = new FormData();
        formData.append('file', file);

        try {
            return await axiosBase.post<FormData, AxiosResponse<MediaUploadResult>>('file/upload-ad-image', formData, {
                headers: {
                    ...instance.getHeaders(),
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: options?.onUploadProgress,
                cancelToken: options?.cancelToken
            }).then(x => {
                if (x.status == 200) {
                    return x.data
                }
                return x.statusText
            }).catch(e => {
                if(typeof e?.response?.data == "string")
                    return e.response.data;
            });
        } catch (e) {
            console.log(e)
        }
    }

    public async deleteAdImage(fileName:string){
        try {
            return await axiosBase.delete<FormData, AxiosResponse<boolean>>(`file/delete-ad-image/${fileName}`, {
                headers: {
                    ...instance.getHeaders(),
                },
            }).then(x => {
                if (x.status == 200) {
                    return x.data
                }
                return x.statusText
            }).catch(e => {
                console.log(e)
            });
        } catch (e) {
            console.log(e)
        }
    }

    public async uploadGenericMedia(directory:string, file: File, options?:{ onUploadProgress?: (event: ProgressEvent<EventTarget>) => void, cancelToken:CancelToken}) {

        // Upload the image using the fetch and FormData APIs
        const formData = new FormData();
        formData.append('file', file);

        try {
            return await axiosBase.post<FormData, AxiosResponse<MediaUploadResult>>(`file/upload-generic-media/${directory}`, formData, {
                headers: {
                    ...instance.getHeaders(),
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: options?.onUploadProgress,
                cancelToken: options?.cancelToken
            }).then(x => {
                if (x.status == 200) {
                    return x.data
                }
                return x.statusText
            }).catch(e => {
                if(typeof e?.response?.data == "string")
                    return e.response.data;
            });
        } catch (e) {
            console.log(e)
        }
    }

    public async deleteGenericMedia(directory:string, fileName:string){
        try {
            return await axiosBase.delete<FormData, AxiosResponse<boolean>>(`file/delete-generic-media/${directory}/${fileName}`, {
                headers: {
                    ...instance.getHeaders(),
                },
            }).then(x => {
                if (x.status == 200) {
                    return x.data
                }
                return x.statusText
            }).catch(e => {
                console.log(e)
            });
        } catch (e) {
            console.log(e)
        }
    }
}

const instance = new ApiService();
export const axiosBase = Axios.create({baseURL: EnvConstants.getEnvConst().apiUrl, headers:instance.getHeaders()})
export const apiServiceInstance: ApiService = instance;