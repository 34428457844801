import React from 'react';
import { GQL_WordsAndPeriods_Input } from '../types/graphql/WordsAndPeriodsInput'
import { GQLWordCount } from '../types/graphql/words';
import { GqlChallengeType, GqlPeriodicChallengeFrame } from '../types/graphql/PeriodicChallengeInput';
import { PlayoffChallenge } from '../models/Dto/Playoff';
import { PlayoffChallengeInputType } from './queries/usePlayoffQueries';
import { isBefore } from 'date-fns';
import { GqlMedia } from '../types/graphql/Media';

export type PlayoffChallengeFormType = {
    participationDates: Date[],
    voteDates: Date[],
    frame: GqlPeriodicChallengeFrame,
    instructions: { language: string, value: string }[],
    quote: { language: string, value: string }[],
    mediaId: string | undefined,
    mediaType: GqlChallengeType,
    wordCount: GQLWordCount,
    words: GQL_WordsAndPeriods_Input.Word_WordGroupInputType[],
    isUltimate: boolean,
    round: number,
}

const initialValue: PlayoffChallengeFormType = {
    participationDates: [],
    voteDates: [],
    frame: GqlPeriodicChallengeFrame.Image,
    instructions: [],
    quote: [],
    mediaId: undefined,
    mediaType: GqlChallengeType.UNDEFINED,
    wordCount: GQLWordCount.Undefined,
    words: [],
    isUltimate: false,
    round: 1,
}
export function usePlayoffChallengeFormFields() {

    const [formFields, setFormFields] = React.useState(initialValue);

    const getApiModel = (id: string | undefined, playoffId: string): PlayoffChallengeInputType => ({
        dateParticipationStart: formFields.participationDates[0]?.toISOString(),
        dateParticipationEnd: formFields.participationDates[1]?.toISOString(),
        dateVoteStart: formFields.voteDates[0]?.toISOString(),
        dateVoteEnd: formFields.voteDates[1]?.toISOString(),
        instructions: formFields.instructions ?? [],
        mediaType: formFields.mediaType,
        mediaId: formFields.mediaId,
        periodicChallengeFrame: formFields.frame,
        quote: formFields.quote,
        wordCount: formFields.wordCount,
        words: formFields.words.map(x => ({ order: x.order, wordId: x.wordId } as any)),
        isUltimate: formFields.isUltimate,
        round: formFields.round,
        playoffId,
    });

    const setValueFromChallenge = (challenge: PlayoffChallenge) => {
        setFormFields({
            frame: challenge.periodicChallengeFrame,
            isUltimate: challenge.isUltimate,
            mediaId: challenge.mediaId,
            mediaType: challenge.type,
            participationDates: [new Date(challenge.dateParticipationStart), new Date(challenge.dateParticipationEnd)],
            quote: (challenge.quote ?? []).map(x=> ({language:x.language, value:x.value})),
            instructions: (challenge.instructions ?? []).map(x=> ({language:x.language, value:x.value})),
            round: challenge.round,
            voteDates: [new Date(challenge.dateParticipationStart), new Date(challenge.dateVoteEnd)],
            wordCount: challenge.wordCount,
            words: (challenge.word_Challenges ?? []).map(x=>({wordId: x.wordId ?? x.word.id, order: x.order}))

        })
    }

    const getErrors = () => {
        const model = getApiModel(undefined, '');
        const errors: Partial<Record<keyof PlayoffChallengeInputType, string[]>> = {};

        if (!model.dateParticipationStart)
            errors.dateParticipationStart = ['Une date de départ pour les participations est requise'];
        if (!model.dateParticipationEnd)
            errors.dateParticipationEnd = ['Une date de fin pour les participations est requise'];

        if (model.dateParticipationEnd && model.dateParticipationStart && isBefore(Date.parse(model.dateParticipationEnd), Date.parse(model.dateParticipationStart)))
            errors.dateParticipationStart = [...(errors.dateParticipationStart ?? []), 'La date de départ participation doit être supérieur à celle de fin']

        if (!model.dateVoteStart)
            errors.dateVoteStart = ['Une date de départ pour les vote est requise'];
        if (!model.dateVoteEnd)
            errors.dateVoteEnd = ['Une date de fin pour les votes est requise'];

        if (model.dateVoteEnd && model.dateVoteStart && isBefore(Date.parse(model.dateVoteEnd), Date.parse(model.dateVoteStart)))
            errors.dateVoteStart = [...(errors.dateVoteStart ?? []), 'La date de départ des votes doit être supérieur à celle de fin']

        if (!model.instructions?.length)
            errors.instructions = ['Veuillez entrer les instructions']
        if (!model.quote?.length)
            errors.quote = ['Veuillez entrer une citation']

        if (model.periodicChallengeFrame == GqlPeriodicChallengeFrame.Image) {
            if (!model.mediaId)
                errors.mediaId = ['Veuillez sélectionner une image']

        } else {
            if (!model.wordCount || model.wordCount == GQLWordCount.Four || model.wordCount == GQLWordCount.Undefined)
                errors.wordCount = ['Veuillez sélectionner un nombre de mot'];
            else {
                let countNum = 0;
                switch (model.wordCount) {
                    case GQLWordCount.One: countNum = 1;
                        break;
                    case GQLWordCount.Two: countNum = 2;
                        break;
                    case GQLWordCount.Three: countNum = 3
                        break;
                }

                if ((model.words?.length ?? -1) < (countNum * 3)) {
                    errors.words = [`Veuillez corriger la sélections de mots`];
                }
            }
        }

        return errors;
    }

    const onChange = (key: keyof typeof formFields, value: any) => {

        setFormFields((prev: typeof formFields) => {
            const newVal = { ...prev, [key]: value }

            return { ...newVal }
        });
    };
    return {
        formFields,
        onChange,
        getApiModel,
        setFormFields,
        setValueFromChallenge,
        initialValue,
        getErrors,
    };
}