import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import React from 'react'

type Props = {
    isOpen: boolean,
    close: () => void,
    onConfirm: () => unknown,
    loading?:boolean
}

export default function DeletePrompt({loading, close, isOpen, onConfirm }: Props) {
    return (
        < Modal isOpen={isOpen} closeable={false} >
            <ModalHeader>Confirmation</ModalHeader>
            <ModalBody>
                Voulez-vous vraiment supprimer cet élément ?
            </ModalBody>
            <ModalFooter>
                <ModalButton isLoading={loading} kind="tertiary" onClick={close}>Non</ModalButton>
                <ModalButton isLoading={loading} onClick={onConfirm}>Oui</ModalButton>
            </ModalFooter>
        </Modal >)
}