import { StyledRoot, StyledTable, StyledTableBody, StyledTableBodyCell, StyledTableBodyRow, StyledTableHead, StyledTableHeadCell, StyledTableHeadRow } from 'baseui/table-semantic';
import React, { ReactElement } from 'react'
import { PointsSettings } from '../../types/graphql/AppConfiguration'
import CellInput from './CellInput'

interface Props {
    loading:boolean;
    settings: PointsSettings;
    onValueChange: (key:string, value:string) =>void;
}

export default function PointsTable({loading, onValueChange, settings}: Props): ReactElement {
    return (
<StyledRoot style={{ margin: '0 auto' }}>

<StyledTable >
    <StyledTableHead>
        <StyledTableHeadRow>
            <StyledTableHeadCell colSpan={2}>Points</StyledTableHeadCell>
        </StyledTableHeadRow>
        <StyledTableHeadRow>
            <StyledTableHeadCell>Challenge</StyledTableHeadCell>
            <StyledTableHeadCell>1ere place</StyledTableHeadCell>
            <StyledTableHeadCell>2e place</StyledTableHeadCell>
            <StyledTableHeadCell>3e place</StyledTableHeadCell>
        </StyledTableHeadRow>
    </StyledTableHead>
    <StyledTableBody>
        <StyledTableBodyRow >
            <StyledTableBodyCell>Mois</StyledTableBodyCell>
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.month.firstPlace" initialValue={settings!.month.firstPlace.value} />
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.month.secondPlace" initialValue={settings!.month.secondPlace.value} />
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.month.thirdPlace" initialValue={settings!.month.thirdPlace.value} />
        </StyledTableBodyRow>
        <StyledTableBodyRow >
            <StyledTableBodyCell>Semaine</StyledTableBodyCell>
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.week.firstPlace" initialValue={settings!.week.firstPlace.value} />
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.week.secondPlace" initialValue={settings!.week.secondPlace.value} />
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.week.thirdPlace" initialValue={settings!.week.thirdPlace.value} />
        </StyledTableBodyRow>
        <StyledTableBodyRow >
            <StyledTableBodyCell>Jour</StyledTableBodyCell>
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.day.firstPlace" initialValue={settings!.day.firstPlace.value} />
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.day.secondPlace" initialValue={settings!.day.secondPlace.value} />
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.day.thirdPlace" initialValue={settings!.day.thirdPlace.value} />
        </StyledTableBodyRow>
        <StyledTableBodyRow >
            <StyledTableBodyCell>Heure</StyledTableBodyCell>
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.hour.firstPlace" initialValue={settings!.hour.firstPlace.value} />
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.hour.secondPlace" initialValue={settings!.hour.secondPlace.value} />
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.hour.thirdPlace" initialValue={settings!.hour.thirdPlace.value} />
        </StyledTableBodyRow>
        <StyledTableBodyRow >
            <StyledTableBodyCell>Random</StyledTableBodyCell>
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.random.firstPlace" initialValue={settings!.random.firstPlace.value} />
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.random.secondPlace" initialValue={settings!.random.secondPlace.value} />
            <CellInput disabled={loading == true} onChange={onValueChange} name="points.random.thirdPlace" initialValue={settings!.random.thirdPlace.value} />
        </StyledTableBodyRow>
        {/* <StyledTableBodyRow >
            <StyledTableBodyCell>Bonus de première participation</StyledTableBodyCell>
            <CellInput disabled={loading == true} colSpan={3} onChange={onValueChange} name="points.firstParticipationBonus" initialValue={settings!.firstParticipationBonus.value} />
        </StyledTableBodyRow> */}
    </StyledTableBody>
</StyledTable>
</StyledRoot>
    )
}
