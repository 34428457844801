import { Option } from 'baseui/select';
import React from 'react';
import { getNextDayDates, getNextHourDates, getNextMonthDates, getNextWeekDates } from '../utils/PeriodicChallengeDateUtils';
import { GQL_WordsAndPeriods_Input } from '../types/graphql/WordsAndPeriodsInput'
import { GQLWordCount } from '../types/graphql/words';
import { GqlChallengeType, GqlDurationType, GqlPeriodicChallengeFrame, GqlPeriodicChallengeInputTypeInput } from '../types/graphql/PeriodicChallengeInput';
import { usePeriodicChallengeAvailableDates } from './usePeriodicChallengeAvailableDates';

export function usePeriodicChallengeFormFields() {
    const { dates } = usePeriodicChallengeAvailableDates();

    const [formFields, setFormFields] = React.useState({
        periodType: undefined as Option | undefined,
        dateParticipationStart: undefined as Date | undefined,
        dateParticipationEnd: undefined as Date | undefined,
        dateVoteStart: undefined as Date | undefined,
        dateVoteEnd: undefined as Date | undefined,
        frame: GqlPeriodicChallengeFrame.Image,
        instructions: [] as { language: string, value: string }[],
        quote: [] as { language: string, value: string }[],
        mediaId: undefined as string | undefined,
        mediaType: GqlChallengeType.UNDEFINED ,
        wordCount: GQLWordCount.Undefined,
        words: [] as GQL_WordsAndPeriods_Input.Word_WordGroupInputType[],
    });

    const getApiModel = (): GqlPeriodicChallengeInputTypeInput => ({
        dateParticipationEnd: formFields.dateParticipationEnd!,
        dateVoteEnd: formFields.dateVoteEnd!,
        dateParticipationStart: formFields.dateParticipationStart!,
        dateVoteStart: formFields.dateVoteStart!,
        durationType: formFields.periodType?.id as string as GqlDurationType,
        instructions: formFields.instructions ?? [],
        mediaType: formFields.mediaType,
        mediaId: formFields.mediaId,
        periodicChallengeFrame: formFields.frame,
        quote: formFields.quote,
        wordCount: formFields.wordCount,
        words: formFields.words,
    });

    function setDatesFromStart(startDate?: Date, durationType?: GqlDurationType) {
        const func = durationType == GqlDurationType.Monthly ? getNextMonthDates
            : durationType == GqlDurationType.Weekly ? getNextWeekDates
                : durationType == GqlDurationType.Daily ? getNextDayDates
                    : durationType == GqlDurationType.Hourly ? getNextHourDates
                        : () => { return [] };

        const date = durationType ? func(startDate ?? dates[durationType][0], 1)[0] : undefined;
        return {
            dateParticipationStart: date?.startDate,
            dateParticipationEnd: date?.participationEndExcluded,
            dateVoteEnd: date?.voteEnd,
            dateVoteStart: date?.startDate
        }
    }

    const onChange = (key: keyof typeof formFields, value: any) => {

        setFormFields((prev: typeof formFields) => {
            let newVal = { ...prev, [key]: value }

            if (key === 'periodType') {
                const val = (value as Option | undefined)?.id as GqlDurationType;
                newVal = {...newVal, ...setDatesFromStart(undefined,val)}
            } else if (key === 'dateParticipationStart') { 
                const val = formFields.periodType?.id as GqlDurationType;
                newVal = {...newVal, ...setDatesFromStart(value as Date,val)}
            }


            return { ...newVal }
        });
    };
    return {
        formFields,
        onChange,
        getApiModel,
        dates,
        setFormFields
    };
}