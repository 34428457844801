import {gql, useMutation} from "@apollo/client";

const DELETE_AD_MUTATION = gql`
    mutation deleteAd($id: ID!){
        deleteAd(adId:$id)
    }
  `;

export function DeleteAdMutation() {
    return useMutation<{ deleteAd: boolean }, { id: string }>(DELETE_AD_MUTATION);
}