import { DatePicker, ORIENTATION } from 'baseui/datepicker'
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, SIZE } from 'baseui/modal'
import { frCA } from 'date-fns/locale'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Label } from '../../UiElements/WidgetCard/WidgetCard.styled'
import { LabelSmall } from 'baseui/typography'
import { colors } from 'baseui/tokens'
import { Event } from 'react-big-calendar'
import { toaster } from 'baseui/toast'
import AdsPlannerModalContext from "../../../contexts/AdsPlanner/AdsPlannerModalContext";
import AdsDetailsImageSelection from "./AdsDetailsImageSelection";
import AdsDetailsImageInfos from "./AdsDetailsImageInfos";
import { CreateAdsMutation } from "../../../hooks/mutations/createAdsMutation";
import { UpdateAdMutation } from "../../../hooks/mutations/updateAdMutation";
import { DeleteAdMutation } from "../../../hooks/mutations/deleteAdMutation";
import { GqlAd } from '../../../types/graphql/Ads'

export interface AdDetailsModalProps {
    start?: Date,
    end?: Date,
    isOpened?: boolean,
    event?: Event,
    close: () => void;
    saved: (ads: GqlAd[]) => void;
    deleted: (id: string) => void;
}

export default function AdDetailsModal(props: AdDetailsModalProps): ReactElement {
    const { start, end } = props;
    const [selectedRange, setSelectedRange] = useState(props.start && props.end ? [props.start, props.end] : []);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
    const { selectedMedia, setSelectedMedia, setIsDirty, isDirty } = useContext(AdsPlannerModalContext);

    const [createAds, createAdsQuery] = CreateAdsMutation();
    const [updateAd, updateAdQuery] = UpdateAdMutation();
    const [deleteAd, deleteAdQuery] = DeleteAdMutation();

    useEffect(() => {
        setSelectedMedia(props.event?.resource.media);
    }, [props.event?.resource])

    useEffect(() => {
        const newRange: Date[] = [];
        if (start)
            newRange.push(start);
        if (end)
            newRange.push(end)
        if (newRange.length === 1)
            newRange.push(newRange[0])

        setSelectedRange(newRange)
    }, [start, end])


    function close() {
        setErrorMessages([]);
        setSelectedRange([]);
        setSelectedMedia(undefined);
        setIsDirty(false);
        props.close();
    }

    function onSaveClicked() {
        if (props.event?.resource.ad) {
            saveEditedAd();
        } else {
            saveNewAd();
        }
    }

    async function saveEditedAd() {
        if (!selectedMedia?.id)
            return;

        try {
            const result = await updateAd({
                variables: {
                    ad:
                    {
                        name: selectedMedia.name,
                        keywords: selectedMedia.keywords,
                        id: props.event?.resource.ad.id,
                        dateStart: selectedRange[0].toISOString(),
                        dateEnd: selectedRange[1].toISOString(),
                        mediaId: selectedMedia.id!,
                    }
                }
            });

            props.saved([result.data!.editAd])
            toaster.positive("Modifiée avec succès", {});
            close();
        } catch (err) {
            const errors = updateAdQuery.error?.message.split('\n') ?? [];
            setErrorMessages(errors);
        }
    }

    async function saveNewAd() {
        try {
            if (selectedMedia) {

                const result = await createAds({
                    variables: {
                        ads: [
                            {
                                name: selectedMedia.name,
                                keywords: selectedMedia.keywords,
                                dateStart: selectedRange[0].toISOString(),
                                dateEnd: selectedRange[1].toISOString(),
                                mediaId: selectedMedia.id!,
                            }]
                    },
                });

                props.saved(result.data!.addMultipleAds)
                toaster.positive("Ajoutée avec succès", {});
                close();
            }
        } catch (err) {
            const errors = createAdsQuery.error?.message.split('\n') ?? [];
            setErrorMessages(errors);
        }
    }

    async function onDeleteConfirmClicked() {
        const id = props.event?.resource.ad.id!;
        setIsDeleteConfirmModalOpen(false);
        try {
            const res = await deleteAd({ variables: { id } });
            if (res.data?.deleteAd) {
                props.deleted(id);
                toaster.positive("Suprimmée avec succès", {});
                close();
            } else
                toaster.negative("Une erreur est survenue", {});
        } catch (err) {
            const errors = deleteAdQuery.error?.message.split('\n') ?? [];
            toaster.negative("Une erreur est survenue", {});
            setErrorMessages(errors);
        }
    }

    return (
        /*@ts-ignore*/
        <Modal size={SIZE.auto} isOpen={props.isOpened} onClose={_ => close()} >
            <ModalHeader>
                {props.event ? "Modifier" : 'Ajouter'} une image ou une pub <br />

                {
                    /*@ts-ignore*/
                    props.event && <ModalButton isLoading={deleteAdQuery?.loading} size="compact" onClick={_ => setIsDeleteConfirmModalOpen(true)} overrides={{ BaseButton: { style: { background: colors.red400 } } }}>Supprimer</ModalButton>}
            </ModalHeader>
            <ModalBody>

                <Label>Période d'affichage
                    {/*@ts-ignore*/}
                    <DatePicker locale={frCA} range={true} onChange={({ date }) => setSelectedRange(Array.isArray(date) ? date : [date])} monthsShown={2} value={selectedRange} orientation={ORIENTATION.horizontal} />
                </Label>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                    <AdsDetailsImageSelection existingMedia={props.event?.resource.media} />
                    <AdsDetailsImageInfos />
                </div>

            </ModalBody>
            <ModalFooter>
                {errorMessages?.map((x, i) => <LabelSmall key={i} color={colors.red400}>{x}</LabelSmall>)}
                {/*@ts-ignore*/}
                <ModalButton kind="tertiary" onClick={close}>Annuler</ModalButton>
                {/*@ts-ignore*/}
                <ModalButton disabled={!isDirty || selectedRange.length < 2 || !selectedMedia?.id} onClick={onSaveClicked} isLoading={createAdsQuery.loading}>Enregistrer</ModalButton>
            </ModalFooter>


            {/* Confirm deletion modal */}
            {/*@ts-ignore*/}
            <Modal isOpen={isDeleteConfirmModalOpen} closeable={false}>
                <ModalHeader>Confirmation</ModalHeader>
                <ModalBody>
                    Voulez-vous vraiment supprimer cet élément ?
                </ModalBody>
                <ModalFooter>
                    {/*@ts-ignore*/}
                    <ModalButton kind="tertiary" onClick={_ => setIsDeleteConfirmModalOpen(false)}>Non</ModalButton>
                    {/*@ts-ignore*/}
                    <ModalButton onClick={onDeleteConfirmClicked}>Oui</ModalButton>
                </ModalFooter>
            </Modal>
        </Modal>
    )
}
