import React, { ReactElement } from "react";
import { Card, StyledBody } from "baseui/card";
import { Block } from "baseui/block";
import { useParams } from "react-router-dom";
import { UseUserSubscriptionQuery } from "../../hooks/queries/useUserSubscriptionQuery";
import { Table } from "baseui/table-semantic";
import { Spinner } from "baseui/spinner";
import format from "date-fns/format";

export default function UserSubscription(): ReactElement {
    const params = useParams<{ id }>();
    const query = UseUserSubscriptionQuery(params.id);
    const subscriptions = query.data?.membershipSubscriptions
    return (
        <Block display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Card>
                <StyledBody>
                    {query.loading && <Spinner />}
                    {subscriptions && (subscriptions!.length ? <SubscriptionsTable subscriptions={subscriptions} /> : <h2>Aucun abonnement</h2>)}
                </StyledBody>
            </Card>
        </Block>
    )
}

function SubscriptionsTable({ subscriptions }): ReactElement {
    return (
        <Table
            columns={[
                "Date de début",
                "Date de fin",
                "Date crée",
                "Produit id",
                "Date annulée",
                "Date révoquée ou remboursée",
            ]}

            data={subscriptions.map((x) => [
                format(new Date(x.dateStart), 'yyyy/MM/dd'),
                format(new Date(x.dateEnd), 'yyyy/MM/dd'),
                format(new Date(x.dateCreated), 'yyyy/MM/dd'),
                x.inAppPurchaseProductId,
                x.dateCanceled ? format(new Date(x.dateCanceled), 'yyyy/MM/dd') : "N/A",
                x.dateRevokedOrRefund ? format(new Date(x.dateRevokedOrRefund), 'yyyy/MM/dd') : "N/A"
            ])}
        />
    )
}