import { Input, InputProps } from 'baseui/input';
import React, { useEffect, useState } from 'react'
import useDebouncedCallback from '../../hooks/useDebounce';


interface Props extends Omit<InputProps, 'onChange'> {
    debounceTime?: number;
    onChange: (value:string)=>void
}

export default (props: Props)=> {
    const [localValue, setLocalValue] = useState(props.value);
    const debounced = useDebouncedCallback((e: React.FormEvent<HTMLInputElement>) => { props.onChange && props.onChange(localValue?.toString() ?? '') }, props.debounceTime ?? 250);

    useEffect(() => {
        setLocalValue(props.value);
    }, [props.value]);

    return (
        <Input
            {...props}
            onChange={(e) => {
                setLocalValue(e.currentTarget.value);
                debounced(e)
            }}
            value={localValue}
        />
    )
}
