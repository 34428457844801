import { Block } from 'baseui/block'
import { Input } from 'baseui/input';
import { KEY_STRINGS } from 'baseui/menu';
import React, { KeyboardEvent, ReactElement, useState } from 'react'
import { FiEdit2, FiRefreshCcw, FiSave } from 'react-icons/fi';
import IconButton from '../UiElements/IconButton/IconButton';
import Loader from '../UiElements/Loader/Loader';

interface Props {
    value: string,
    onSave: (newVal: string) => void,
    loading?: boolean
}

export default function EditableString({ value, onSave, loading }: Props): ReactElement {
    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    function save(){
        if(currentValue.trim() === value.trim()){
            reset();
        } else {
            onSave(currentValue.trim()); 
            setIsEditing(false);
        }
    }

    function reset(){
        setCurrentValue(value);
        setIsEditing(false);
    }

    function onKeyPress (e: KeyboardEvent<HTMLInputElement>){
        if(e.key === KEY_STRINGS.Enter)
            save();
        else if (e.key === KEY_STRINGS.Escape)
            reset();
    }

    return (
        <Block display="flex" width='100%'>
            {isEditing ? <>
                <Input autoFocus onKeyUp={onKeyPress} value={currentValue} onChange={e => setCurrentValue(e.currentTarget.value)} size="compact" overrides={{ Root: { style: { flex: 1, margin: '-2px 0', } } }} />
                <IconButton onClick={reset}>
                    <FiRefreshCcw />
                </IconButton>
                <IconButton onClick={save}>
                    <FiSave />
                </IconButton>
            </> :
                <>
                    <Block minWidth="200px" display="flex" alignItems="center" paddingLeft={'16px'} height='100%' flex={1}>
                        {currentValue}
                    </Block>
                    {loading ? <Loader /> :
                        <IconButton>
                            <FiEdit2 onClick={() => setIsEditing(true)} />
                        </IconButton>
                    }
                </>
            }
        </Block>
    )
}
