import React from 'react';
import { EnvConstants } from '../../constants/EnvConstants';
import Container from '../UiElements/Container/Container';
import FooterWrapper, { Text } from './Footer.styled';

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <Container>
        <Text>pv-admin ©{new Date().getFullYear()} Created by Dynamic Nerds Solutions - {EnvConstants.getEnvConst().version}</Text>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
