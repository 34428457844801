import React, { ReactElement } from "react";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";

interface Props {
    label
    name
    value
}

const DisabledInput = (props: Props) => {
    return (
        /*@ts-ignore*/
        < FormControl label={props.label} >
            {/*@ts-ignore*/}
            < Input
                name={props.name}
                value={props.value}
                disabled
                overrides={{
                    Input: {
                        style: {
                            cursor: 'normal'
                        }
                    }
                }
                }
            />
        </FormControl >
    )
}

export default DisabledInput