declare global {
    interface String {
        diacriticLess(): string;
    }
}



String.prototype.diacriticLess = function () {
    const d = String(this)
    return d.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
};



export { }