import React, { useContext } from 'react';
import DefaultHeader from './DefaultHeader/DefaultHeader';
import MobileHeader from './MobileHeader/MobileHeader';
import HeaderWrapper, { HideInMobile, ShowInMobile } from './Header.styled';
import { AuthContext } from '../../contexts/AuthContext';

const Header: React.FC = () => {
  const [_, isSignedIn] = useContext(AuthContext);

  return (
    isSignedIn ? (
      <HeaderWrapper>
        {/* <HideInMobile> */}
          {/* <DefaultHeader /> */}
        {/* </HideInMobile> */}
        {/* <ShowInMobile> */}
          <MobileHeader />
        {/* </ShowInMobile> */}
      </HeaderWrapper>)
      : <></>

  );
};

export default Header;
