import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Playoff, { PlayoffChallenge } from "../../models/Dto/Playoff";
import { GqlChallengeType, GqlPeriodicChallengeFrame, languageString } from "../../types/graphql/PeriodicChallengeInput";
import GqlPaginatedQueryResult from "../../types/graphql/QueryResult";
import { GQLWordCount } from "../../types/graphql/words";

const challengeContent = `
    id
    round
    isUltimate
    periodicChallengeFrame
    dateParticipationStart
    dateParticipationEnd
    dateVoteStart
    dateVoteEnd
    durationType
    computedChallengeType
    playoffId
    type
    wordCount
    participationCount
    media {
        id
        path
        type
    }
    mediaId
    instructions {
        value
        language
    }
    quote {
        value
        language
    }
    word_Challenges {
        order
        word {
            id
            translations{
            id
            value
            languageId
            }
        }
    }
`
const playoffContent = `
    id
    dateStart
    dateEnd
    challenges {
        ${challengeContent}
    }
`

const QUERY_ALL = gql`
{ 
    allPlayoffs(order_by:{dateStart:DESC},) {
      nodes {
        ${playoffContent}
      }
      totalCount,
      pageInfo{
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      
    }
  }
`
const QUERY_PER_ID = gql`
query GetPlayoffPerId($id:ID!) {
    playoff(id:$id){
        ${playoffContent}
    }
}`

const MUTATION = gql`
mutation addPlayoffs($dateStart: DateTime!, $dateEnd: DateTime!, $id: ID) {
    upsertPlayoffs(dateStart:$dateStart,dateEnd:$dateEnd, id:$id){
        id
        dateStart
        dateEnd
    }
}
`

const UPSERT_CHALLENGE = gql`
    mutation UpsertPlayoffChallenge($challenge: PlayoffChallengeInputTypeInput!, $id: ID) {
        upsertPlayoffChallenge(challenge:$challenge, existingChallengeId: $id){
            ${challengeContent}
        }
    }
`

export type PlayoffChallengeInputType = {
    playoffId: string
    mediaId?: string;

    round: number;
    isUltimate: boolean;
    dateParticipationStart: string;
    dateParticipationEnd: string;
    dateVoteStart: string;
    dateVoteEnd: string;

    instructions?: languageString[];
    quote?: languageString[];

    mediaType: GqlChallengeType;
    periodicChallengeFrame: GqlPeriodicChallengeFrame;

    wordCount?: GQLWordCount;
    words?: { order: number, wordId: string }[];
}

export default function () {
    const [fetch, query] = useLazyQuery<{ allPlayoffs: GqlPaginatedQueryResult<Playoff> }>(QUERY_ALL);
    const [save, mutation] = useMutation<{ addPlayoff: Playoff }, { dateStart: Date, dateEnd: Date, id?: string }>(MUTATION);
    const [saveChallenge, challengeMutation] = useMutation<{ upsertPlayoffChallenge: PlayoffChallenge }, { challenge: PlayoffChallengeInputType, id?: string }>(UPSERT_CHALLENGE);
    const [getPlayoffPerId, playoffPerIdQuery] = useLazyQuery<{ playoff: Playoff }, { id: string }>(QUERY_PER_ID);
    return {
        fetch,
        query,
        save,
        mutation,
        saveChallenge,
        challengeMutation,
        getPlayoffPerId,
        playoffPerIdQuery
    };
}