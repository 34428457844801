import React, { ReactElement, useContext } from "react";
import { LabelLarge } from "baseui/typography";
import { Input } from "baseui/input";
import { StatefulTooltip } from "baseui/tooltip";
import AdsPlannerModalContext from "../../../contexts/AdsPlanner/AdsPlannerModalContext";


export default function (): ReactElement {
    const { setIsDirty, setSelectedMedia, selectedMedia } = useContext(AdsPlannerModalContext)

    function onNameChange(val: string) {
        setSelectedMedia(s => { return { ...s, name: val } })
        setIsDirty(true);
    }
    function onKeywordsChange(val: string) {
        setSelectedMedia(s => { return { ...s, keywords: val } })
        setIsDirty(true);
    }

    return (
        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
            <h2 style={{ marginBottom: '20px' }}>Informations</h2>
            <LabelLarge>Nom du fichier</LabelLarge>
            {/*@ts-ignore*/}
            <Input type='text' value={selectedMedia?.name ?? ''} onChange={(e) => onNameChange(e.currentTarget.value)} />
            <StatefulTooltip
                accessibilityType={'tooltip'}
                content="E.g.: mot-cle1;mot-cle2;mot-cle3;"
            >
                <LabelLarge>Mot(s)-clé(s)</LabelLarge>
            </StatefulTooltip>
            {/*@ts-ignore*/}
            <Input type='text' value={selectedMedia?.keywords ?? ''} onChange={(e) => onKeywordsChange(e.currentTarget.value)} />
        </div>
    )
}