import { Block } from 'baseui/block';
import { StatefulTextarea, Textarea, TextareaProps } from 'baseui/textarea';
import React, { ReactElement, useEffect, useState } from 'react'
import { languageString } from '../../types/graphql/PeriodicChallengeInput'
import { Text } from '../Footer/Footer.styled';

interface Props extends Omit<TextareaProps, keyof{onChange, value}> {
    languages: string[],
    value: languageString[],
    disabled?:boolean
    required?:boolean
    onChange: (value: languageString[]) => void
}

export default function MultipleLanguageTextArea({ languages, onChange, value, ...rest }: Props): ReactElement {
    const [currentLang, setCurrentLang] = useState<string>(languages[0]);
    const mapped = languages.reduce((prev, curr) => {
        prev[curr] = value.filter(x => x.language == curr)[0]?.value ?? '';
        return prev;
    }, {});

    function onTextChange(e:React.FormEvent<HTMLTextAreaElement>){
        const newVal= {...mapped};
        newVal[currentLang] = e.currentTarget.value;
        const eventVal = languages.map(x=>{return {language:x, value: newVal[x]}});
        onChange(eventVal);
    }

    return (
        <Block position="relative">
            <Textarea {...rest} value={mapped[currentLang]} size='compact' onChange={onTextChange} />
            <Block position='absolute' bottom={0} right={0} display="flex" height="20px">
                {languages
                    .join(" | ")
                    .split(' ')
                    .map(l =>
                        <Text onClick={l == '|' ? undefined : e=>setCurrentLang(l)} $as="span" color={currentLang == l ? 'black' : 'link'} style={{fontWeight:currentLang == l ? 'bold' : 'normal',padding:0, margin:'0 3px',cursor: l == '|' ? undefined : 'pointer'}}>{l}</Text>
                    )}
            </Block>
        </Block>
    )
}
