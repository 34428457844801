import { DatePicker } from 'baseui/datepicker'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import { Select } from 'baseui/select'
import React, { ReactElement, useEffect, useRef } from 'react'
import { FormControl } from "baseui/form-control";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Button, } from 'baseui/button'
import ChallengeImageBasedFrame from './ChallengeImageBasedFrame'
import PeriodicChallengeWordBasedFrame from './PeriodicChallengeWordBasedFrame'
import { Radio, RadioGroup } from 'baseui/radio';
import { usePeriodicChallengeFormFields } from '../../../hooks/usePeriodicChallengeFormFields';
import { GqlDurationType, GqlPeriodicChallengeFrame, GqlPeriodicChallengeInputTypeInput } from '../../../types/graphql/PeriodicChallengeInput';
import { LabelMedium } from 'baseui/typography';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toaster } from 'baseui/toast';
import { GqlPeriodicChallenge, GqlPeriodicChallengeQueryResult } from '../../../types/graphql/PeriodicChallengeListingItem';
import { Spinner } from 'baseui/spinner';
import { GqlMedia } from '../../../types/graphql/Media';
import MultipleLanguageTextArea from '../../MultipleLanguageTextArea/MultipleLanguageTextArea';
import { Block } from 'baseui/block';
import { colors } from 'baseui/tokens';

const SAVE_CHALLENGE_MUTATION = gql`
    mutation SaveChallenge($input: PeriodicChallengeInputTypeInput, $existingChallengeId: ID){
        upsertPeriodicChallenge(challenge:$input, existingChallengeId: $existingChallengeId){
            id
            periodicChallengeFrame
            dateParticipationStart
            dateVoteEnd
            durationType
            wordCount
            type
            participationCount
        }
    }
`;
const DELETE_CHALLENGE_MUTATION = gql`
    mutation DeleteChallenge($id: ID!){
        deletePeriodicChallenge(id:$id)
    }
`;

const GET_CHALLENGE_DETAILS = gql`
query getSingleChallenge($id: ID!){
    periodicChallenges(where:{id:$id}) {
        nodes{
            id
            periodicChallengeFrame
            dateParticipationStart
            dateVoteEnd
            durationType
            wordCount
            type
            participationCount
            dateParticipationEnd
            dateVoteStart
            instructions {language,value}
            mediaId
            media {id path}
            quote {language,value}
            word_Challenges {
                order
                wordId
            }
        }
    }
}
`

interface Props {
    isOpen: boolean,
    close: () => void,
    editedChallengeId?: string,
    onSaveSuccess: () => void,
    onDeleteSuccess: () => void
}

export default function PeriodicChallengeModal({ isOpen, editedChallengeId, close, onSaveSuccess, onDeleteSuccess }: Props): ReactElement {
    const existingMedia = useRef<GqlMedia>();
    const existingChallenge = useRef<GqlPeriodicChallenge>();
    const { formFields, onChange, dates, getApiModel, setFormFields } = usePeriodicChallengeFormFields();
    const [saveChallenge, challengeMutation] = useMutation<any, { input: GqlPeriodicChallengeInputTypeInput, existingChallengeId: string | null }>(SAVE_CHALLENGE_MUTATION, { errorPolicy: 'all' });
    const [deleteChallenge, deleteMutation] = useMutation<any, { id: string }>(DELETE_CHALLENGE_MUTATION, { errorPolicy: 'all' });
    const challengeQuery = useQuery<GqlPeriodicChallengeQueryResult<GqlPeriodicChallenge>, { id: string }>(GET_CHALLENGE_DETAILS, { variables: { id: editedChallengeId || '' }, fetchPolicy: 'network-only' });
    const existingChallengeIsStarted = existingChallenge.current && new Date(existingChallenge.current.dateParticipationStart) < new Date();

    useEffect(() => {
        existingMedia.current = undefined;
        if (editedChallengeId) {
            challengeQuery.refetch({ id: editedChallengeId }).then(res => {
                if (res?.data) {
                    existingChallenge.current = res.data.periodicChallenges.nodes[0];
                    const ch = existingChallenge.current!;
                    existingMedia.current = ch.media;
                    setFormFields({
                        dateParticipationEnd: new Date(ch.dateParticipationEnd),
                        dateParticipationStart: new Date(ch.dateParticipationStart),
                        dateVoteEnd: new Date(ch.dateVoteEnd),
                        dateVoteStart: new Date(ch.dateVoteStart),
                        frame: ch.periodicChallengeFrame,
                        instructions: ch.instructions,
                        mediaId: ch.mediaId,
                        mediaType: ch.type,
                        periodType: { id: ch.durationType as GqlDurationType },
                        quote: ch.quote,
                        wordCount: ch.wordCount,
                        words: ch.word_Challenges
                    })
                }
            });
        }
    }, [editedChallengeId])

    async function save(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const model = JSON.parse(JSON.stringify(getApiModel()).replaceAll(/\"__typename\":\"[^";]+\",/g, ''));
        const res = await saveChallenge({ variables: { input: model, existingChallengeId: editedChallengeId || null } });

        if (res.errors) {
            console.error(res.errors);
            const messages = res.errors.map(x => x.message);
            toaster.negative(messages, {});
        } else {
            toaster.positive("Sauvegardé avec succès", {});
            onSaveSuccess();
            close();
        }
    }

    async function onDeleteClick() {

        const res = await deleteChallenge({ variables: { id: existingChallenge.current!.id } });

        if (res.errors) {
            console.error(res.errors);
            const messages = res.errors.map(x => x.message);
            toaster.negative(messages, {});
        } else {
            if (res.data) {
                toaster.positive("Supprimé avec succès", {});
                onDeleteSuccess();
                close();
            } else {
                toaster.negative('Une erreur est survenur', {});
            }
        }
    }


    return (
        <Modal isOpen={isOpen} size="auto" onClose={close} overrides={{ Root: { style: { zIndex: 110 } } }}>
            
            {/* Hacky but working. Donot try at home */}
            <style>{`
            #periodeType-popover{z-index:120;}
            div[data-baseweb='popover']{z-index: 120;}
            `}</style>

            <ModalHeader>Challenge périodique</ModalHeader>
            <form onSubmit={save}>
                <ModalBody>
                    {editedChallengeId && (challengeQuery.loading || !challengeQuery.data) ? <Spinner /> :
                        <FlexGrid flexDirection='row' flexWrap flexGridColumnCount={[1, 1, 2]} flexGridColumnGap={[0, 0, '30px']}>
                            <FlexGridItem flex="1 0 280px">
                                <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'10px'}>
                                    <FlexGridItem>
                                        <FormControl label="Type de période">
                                            <Select required disabled={existingChallengeIsStarted || challengeMutation.loading} value={formFields.periodType ? [formFields.periodType] : undefined}
                                                onChange={e => onChange("periodType", e.option)}
                                                labelKey='label'
                                                valueKey='id'
                                                overrides={{Popover: {props: { id: 'periodeType-popover', }}}}
                                                options={[
                                                    { id: GqlDurationType.Monthly, label: 'Mois' },
                                                    { id: GqlDurationType.Weekly, label: 'Semaine' },
                                                    { id: GqlDurationType.Daily, label: 'Jour' },
                                                    { id: GqlDurationType.Hourly, label: 'Heure' },
                                                ]}
                                            />
                                        </FormControl>
                                    </FlexGridItem>
                                    <FlexGridItem>
                                        <FormControl label="Frame">
                                            <RadioGroup
                                                required
                                                disabled={challengeMutation.loading}
                                                value={formFields.frame}
                                                align="horizontal"
                                                onChange={e => onChange('frame', e.target.value)}
                                            >
                                                <Radio value={GqlPeriodicChallengeFrame.Image}>Image</Radio>
                                                <Radio value={GqlPeriodicChallengeFrame.Words}>Mots</Radio>
                                            </RadioGroup>
                                        </FormControl>
                                    </FlexGridItem>
                                </FlexGrid>

                                <FlexGrid flexGridColumnCount={2} flexGridColumnGap='scale800'>
                                    <FlexGridItem>
                                        <LabelMedium>Participation</LabelMedium>
                                    </FlexGridItem>
                                    <FlexGridItem></FlexGridItem>
                                    <FlexGridItem>
                                        <FormControl label="du">
                                            <DatePicker required
                                                disabled={formFields.periodType == undefined || challengeMutation.loading || existingChallengeIsStarted}
                                                includeDates={formFields.periodType && dates[formFields.periodType.id as GqlDurationType]}
                                                value={formFields.dateParticipationStart}
                                                timeSelectStart={formFields.periodType?.id === GqlDurationType.Hourly}
                                                formatString={formFields.periodType?.id === GqlDurationType.Hourly ? 'yyyy/MM/dd HH:mm' : undefined}
                                                onChange={e => onChange('dateParticipationStart', e.date)} />
                                        </FormControl>
                                    </FlexGridItem>
                                    <FlexGridItem>
                                        <FormControl label="au (exclu)">
                                            <DatePicker required value={formFields.dateParticipationEnd} disabled onChange={e => onChange('dateParticipationEnd', e.date)} />
                                        </FormControl>
                                    </FlexGridItem>

                                    <FlexGridItem>
                                        <LabelMedium>Vote</LabelMedium>
                                    </FlexGridItem>
                                    <FlexGridItem></FlexGridItem>
                                    <FlexGridItem>
                                        <FormControl label="du">
                                            <DatePicker required value={formFields.dateVoteStart} disabled onChange={e => onChange('dateVoteStart', e.date)} />
                                        </FormControl>
                                    </FlexGridItem>
                                    <FlexGridItem>
                                        <FormControl label="au (exclu)">
                                            <DatePicker required value={formFields.dateVoteEnd} disabled onChange={e => onChange('dateVoteEnd', e.date)} />
                                        </FormControl>
                                    </FlexGridItem>
                                </FlexGrid>


                                <FormControl label="Instructions">
                                    <MultipleLanguageTextArea languages={['fr', 'en']} value={formFields.instructions} onChange={e => onChange('instructions', e)} />
                                </FormControl>
                                <FormControl label="Citation">
                                    <MultipleLanguageTextArea languages={['fr', 'en']} value={formFields.quote} onChange={e => onChange('quote', e)} />
                                </FormControl>
                            </FlexGridItem>


                            {/* FRAME */}
                            {formFields.frame == GqlPeriodicChallengeFrame.Image && <ChallengeImageBasedFrame {...formFields} destinationDirectory="periodic-challenge" media={existingMedia.current} onChange={onChange} />}
                            {formFields.frame == GqlPeriodicChallengeFrame.Words && <PeriodicChallengeWordBasedFrame {...formFields} onChange={onChange} />}
                        </FlexGrid>
                    }
                </ModalBody>

                <ModalFooter >
                    <Block justifyContent="space-between" display="flex" width='100%'>

                        {editedChallengeId && existingChallenge.current &&
                            <Button type="button" disabled={existingChallengeIsStarted || challengeMutation.loading || deleteMutation.loading} onClick={onDeleteClick} overrides={{ BaseButton: { style: { background: colors.red400 } } }} isLoading={deleteMutation.loading}>Supprimer</Button>
                        }
                        <Button $style={{ marginLeft: 'auto' }} disabled={challengeMutation.loading || deleteMutation.loading} isLoading={challengeMutation.loading}>Enregistrer</Button>
                    </Block>
                </ModalFooter>
            </form>
        </Modal>
    )
}
