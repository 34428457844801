import { gql, useMutation } from '@apollo/client';
import { StyledCell, StyledRow } from 'baseui/table';
import React, { useState } from 'react'
import { GqlTranslation, GqlWord } from '../../../types/graphql/AllPeriods';
import EditableString from '../../singleComponents/EditableString';


const SaveTranslationMutaiton = gql`
mutation editTranslation($id: ID, $languageId: ID!, $newVal: String) {
    editTranslation(value:{
        id: $id,
        languageId: $languageId
        value: $newVal
      }){
        value
        id
      }
}
`

interface Props {
    item: GqlWord,
    striped: boolean,
    langs: string[],
    className?: string,
    showId: boolean
}

export default function WordListingRow({ item, striped, langs, className, showId }: Props) {
    const cells: JSX.Element[] = [];
    const [callMutation, mutation] = useMutation<{ editTranslation: GqlTranslation }, { id: string, languageId: string, newVal: string }>(SaveTranslationMutaiton);
    const [savingIds, setSavingIds] = useState([] as string[]);

    async function saveTranslation(tr: GqlTranslation, newVal: string) {
        setSavingIds(s=>[...s, tr.id!]);
        await callMutation({ variables: { id: tr.id!, languageId: tr.languageId!, newVal } });
        setSavingIds(s=> s.filter(x=> x !== tr.id!));
    }


    langs.forEach(l => {
        const val = item.translations!.filter(x => x.languageId == l)[0];
        if (val) {
            const el = 
            <StyledCell style={{ maxHeight: '50px' }} key={val.languageId + '-' + item.id} $striped={striped}>
                <EditableString loading={savingIds.some(x=> x === val.id!)} value={val.value ?? ''} onSave={e => saveTranslation(val, e)} />
            </StyledCell>
            cells.push(el);
        }
    });

    if (showId)
        cells.push(<StyledCell key={'id-' + item.id}>{item.id}</StyledCell>)

    return <StyledRow key={'row-' + item.id} className={className}>{cells}</StyledRow>;
}
