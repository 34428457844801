import { useStyletron } from 'baseui';
import { Block } from 'baseui/block'
import React, { ReactElement } from 'react'

interface Props {
    headers: (string | React.ReactNode)[],
    data: (string | React.ReactNode)[],
    rowIndex: number
    onRowClick: () => void
}

export default function PeriodicChallengeTableRow({ headers, data, rowIndex, onRowClick }: Props) {
    const [css, $theme] = useStyletron();
    return (
        <div onClick={onRowClick} className={css({width:'100%', height:'100%'})}>
        <Block flexDirection={['column', 'column', 'row']} marginBottom={['10px', '10px', 0]} className={css({
            display: 'flex',
            cursor: 'pointer',
            backgroundColor:
                rowIndex % 2
                    ? $theme.colors.backgroundPrimary
                    : $theme.colors.backgroundSecondary,
            ':hover': {
                backgroundColor: $theme.colors.backgroundTertiary,
            }
        })}>
                {/* CELLL */}
                {headers.map((header, i) => {
                    return (
                        <Block
                            key={i}
                            padding={["4px 8px", "4px 8px", "8px 15px",]}
                            className={css({
                                maxWidth:i === 0 ? '50px' : undefined,
                                flex: 1,
                                border: '1px solid lightgray',
                                margin: '-.5px',
                                color: $theme.colors.linkText
                            })}
                        >
                            <Block display={['inline-block', 'inline-block', 'none']}> <b>{header}</b> :&nbsp;</Block>
                            <Block display={['inline-block']}>{data[i]}</Block>
                        </Block>
                    );
                })}
        </Block>
            </div>
    )
}
