import { GqlWord } from "../../../types/graphql/AllPeriods";
import { GQL_WordsAndPeriods_Input } from "../../../types/graphql/WordsAndPeriodsInput";
import { onWordChangeEvent } from "./WordSchedulerEventTypes";

export const mapGqlWordToSelectWord = (word: GqlWord) => {
    return {
        id: word.id!,
        fr: word.translations?.filter(x => x.languageId == 'fr')[0]?.value ?? '',
        en: word.translations?.filter(x => x.languageId == 'en')[0]?.value ?? '',
    }
}

export const getPeriodNumberFromDate = (date: Date): number => {
    const from = date;
    const periodsPerMonth = 3;
    const currentMonth = from.getMonth();
    const currDate = from.getDate();
    const currWeek = currentMonth * periodsPerMonth + (currDate >= 30 ? 2 : Math.floor(currDate / 10))
    return currWeek + 1;
}

export const addWordSelectorEventToWordSchedulerModel = (event: Readonly<onWordChangeEvent>, dataToSave: Readonly<GQL_WordsAndPeriods_Input.PeriodWordsInputType[]>) => {
    const newDataToSave = [...dataToSave];
    let existingPeriod: GQL_WordsAndPeriods_Input.PeriodWordsInputType = newDataToSave.filter(x => x.periodId == event.periodId)[0];

    if (existingPeriod == null) {
        existingPeriod = { periodId: event.periodId, wordGroups: [] }
        newDataToSave.push(existingPeriod);
    }
    let wordGroup = existingPeriod.wordGroups.filter(x => x.order == event.wordGroupOrder)[0];
    if (wordGroup == null) {
        wordGroup = {
            word_WordGroups: [],
            order: event.wordGroupOrder,
        }
        existingPeriod.wordGroups.push(wordGroup)
    }

    let word_wordGroup = wordGroup.word_WordGroups.filter(x => x.order == event.wordOrder)[0];
    if (word_wordGroup == null) {
        word_wordGroup = {
            order: event.wordOrder,
            wordId: ""
        }
        wordGroup.word_WordGroups.push(word_wordGroup);
    }

    if (event.wordId)
        word_wordGroup.wordId = event.wordId;
    else {
        wordGroup.word_WordGroups.splice(wordGroup.word_WordGroups.indexOf(word_wordGroup), 1);
        if (wordGroup.word_WordGroups.length == 0) {
            existingPeriod.wordGroups.splice(existingPeriod.wordGroups.indexOf(wordGroup), 1);
            if (existingPeriod.wordGroups.length == 0)
                newDataToSave.splice(newDataToSave.findIndex(d => d.periodId == existingPeriod.periodId), 1);
        }
    }


    return newDataToSave;

}


