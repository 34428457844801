import {gql, useMutation} from "@apollo/client";
import {AddMultipleAdsResult, AdInput, GqlAd} from "../../types/graphql/Ads";

const CREATE_ADS_MUTATION = gql`
mutation saveAds($ads:[AdInputTypeInput]) {
    addMultipleAds(ads:$ads) {
      id
      dateStart
      dateEnd
      media {
        id
        path
        name
        keywords
      }
    }
  }`;

  

export function CreateAdsMutation() {
    return useMutation<AddMultipleAdsResult, { ads: AdInput[] }>(CREATE_ADS_MUTATION);

}