import { EnvConstants } from "../constants/EnvConstants";

export type CDNImageSize = 720|500|400|300|200|100|50;

function getResizedImageUrl(imageUrl: string, width:CDNImageSize){
    const url = imageUrl.replace('/ad/', '/ads/').substr(imageUrl.indexOf('/api/file/') + 10).replace('generic-media/', 'generic-medias/');
    const splitted = url.split('/');
    const fileName = splitted.pop();
    const filePrefix = splitted.join('/');
    const urlPrefix = EnvConstants.getEnvConst().cdnResizedImagesUrl;


    return `${urlPrefix}/${width}/${filePrefix}/${fileName}`;
}

function makeUrl(imageUrl:string | undefined){
    const newUrl = imageUrl?.replace("http://localhost:5000/api/", EnvConstants.getEnvConst().apiUrl).replace('generic-media/', 'generic-medias/');
    return newUrl?.replace(EnvConstants.getEnvConst().apiUrl + 'file', EnvConstants.getEnvConst().cdnSourceUrl);
}

function makeShareUrl(id:string){
    return EnvConstants.getEnvConst().cdnShareUrl + '/' + id;
}

export const ImageUtils = {
    getResizedImageUrl,
    makeUrl,
    makeShareUrl,
}