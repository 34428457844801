import { useQuery } from '@apollo/client'
import { Block } from 'baseui/block'
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import { Radio, RadioGroup } from 'baseui/radio'
import gql from 'graphql-tag'
import React, { ReactElement, useEffect, useState } from 'react'
import { languageString } from '../../../types/graphql/PeriodicChallengeInput'
import MultipleLanguageTextArea from '../../MultipleLanguageTextArea/MultipleLanguageTextArea'
import Loader from '../../UiElements/Loader/Loader'

const REASON_QUERY = gql`
{
    bannedReasons
}
`
interface Props {
    isVisible,
    onValueChange: (reason: any, textReason?: languageString[]) => void
    close: (banish: boolean) => void
    isComment: boolean
}

export default function BannishingReasonChoices({ isVisible, close, onValueChange, isComment }: Props): ReactElement {
    const reasonsQuery = useQuery(REASON_QUERY);
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [reasonText, setReasonText] = useState<languageString[]>([]);

    useEffect(() => {
        setSelectedValue('');
        setReasonText([]);
    }, [isVisible]);

    function onReasonChange(reason: string) {
        setSelectedValue(reason);
        onValueChange(reason, reason === "OTHER" ? reasonText : undefined);
    }

    function onReasonTextChange(value: languageString[]) {
        setReasonText(value);
        onValueChange(selectedValue, value);
    }

    const isValid = selectedValue !== '' && (selectedValue !== 'OTHER' || reasonText.map(x => x.value).filter(x => x.trim()).length == 2)
    return (
        /*@ts-ignore*/
        <Modal isOpen={isVisible} overrides={{ Root: { style: { zIndex: 9999 } } }} onClose={_ => close(false)}>
            <ModalHeader>
                Sélectionner une raison:
            </ModalHeader>
            <ModalBody>
                <Block width="100%">
                    {reasonsQuery.loading ? <Loader /> :
                        /*@ts-ignore*/
                        <RadioGroup value={selectedValue} onChange={e => onReasonChange(e.target.value)} overrides={{ RadioGroupRoot: { style: { width: '100%' } } }}>
                            {reasonsQuery.data.bannedReasons.map(x => {
                                if (!isComment || (x !== 'INVALID' && x !== 'DUPLICATA')) {/*@ts-ignore*/
                                    return <Radio key={x} value={x} checked={selectedValue === x} labelPlacement="right">{getLabelFromReasonValue(x)}</Radio>
                                }
                            })}

                        </RadioGroup>
                    }
                    <MultipleLanguageTextArea
                        error={selectedValue == 'OTHER' && reasonText.map(x => x.value).filter(x => x.trim()).length !== 2}
                        disabled={selectedValue !== 'OTHER'}
                        languages={['fr', 'en']}
                        value={reasonText} onChange={onReasonTextChange}
                    />
                </Block>
            </ModalBody>

            <ModalFooter>
                {/*@ts-ignore*/}
                <ModalButton onClick={_ => close(true)} disabled={!isValid}>Bannir</ModalButton>
            </ModalFooter>
        </Modal >
    )
}

function getLabelFromReasonValue(reason: string) {
    switch (reason) {
        case "INAPPROPRIATECONTENT": return 'Contenu inapproprié';
        case 'INVALID': return "Non valide - ne répond pas au défi";
        case 'DUPLICATA': return 'Duplicata';
        case 'OTHER': return 'Autre';
        default: return ''

    }
}