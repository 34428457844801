import Container from "../UiElements/Container/Container";
import React, {ReactElement, useState} from "react";
import {Account} from "../../models/Dto/UserInfos";
import {Tabs, Tab} from "baseui/tabs-motion";
import UserInfos from "./UserInfos";
import UserSubscription from "./Usersubscription";
import {Block} from "baseui/block";
import UserParticipation from "./UserParticipations";

interface Props {
    user?: Account
}

export default function UserDetail(props : Props) : ReactElement {
    const [activeKey, setActiveKey] = React.useState<React.Key>("0");

    return (
            <Container>
                <Block padding={'30px'}>
                    <Tabs
                        activeKey={activeKey}
                        onChange={({ activeKey }) => {
                            setActiveKey(activeKey);
                        }}>
                        <Tab title="Informations" >
                            <UserInfos />
                        </Tab>
                        <Tab title="Abonnement">
                            <UserSubscription />
                        </Tab>
                        <Tab title="Participations">
                            <UserParticipation />
                        </Tab>
                    </Tabs>
                </Block>
            </Container>
    )
}