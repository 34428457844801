import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { FileUploader } from "baseui/file-uploader";
import { Label } from '../../UiElements/WidgetCard/WidgetCard.styled'
import { apiServiceInstance } from '../../../services/ApiService';
import { MediaUploadResult } from '../../../models/Dto/MediaUploadResult';
import Axios, { CancelTokenSource } from 'axios';
import { Block } from 'baseui/block';
import { FiX } from 'react-icons/fi';
import { EnvConstants } from '../../../constants/EnvConstants';
import { Img } from 'react-image';
import { Button } from 'baseui/button';
import { GqlMedia } from '../../../types/graphql/Media';
import { colors } from 'baseui/tokens';
import { LabelMedium } from 'baseui/typography';
import AdsPlannerModalContext from '../../../contexts/AdsPlanner/AdsPlannerModalContext';

interface Props {
    onFileDeleted?: () => void;
}

export default function AdImageUploader(props: Props): ReactElement {
    const [progress, setProgress] = useState(0)
    const [uploadedFileId, setUploadedFileId] = useState<string>();
    const [uploadError, setUploadError] = useState<string>();
    const selectedFile = useRef<File>();
    const cancelTokenSource = useRef<CancelTokenSource>();
    const uploadedExtension = useRef<string>();
    const { setSelectedMedia, selectedMedia } = useContext(AdsPlannerModalContext);

    useEffect(() => {
        const splitted = selectedMedia?.path?.split('.');
        uploadedExtension.current = splitted?.pop();
        setUploadedFileId(splitted?.pop()?.split('/').pop());

    }, [selectedMedia])


    async function upload(file: File) {
        setUploadError(undefined);
        setUploadedFileId(undefined);

        selectedFile.current = file;
        cancelTokenSource.current = Axios.CancelToken.source();
        try {

            const result = await apiServiceInstance.uploadAdMedia(file, {
                onUploadProgress: e => setProgress(e.loaded / e.total * 100),
                cancelToken: cancelTokenSource.current.token
            });

            if (typeof result == "string" || !result)
                setUploadError(result || "Une erreur est survenue");
            else if ((result as MediaUploadResult).id) {
                uploadedExtension.current = file.name.split('.').pop();
                const fileId = (result as MediaUploadResult).id
                const path = (result as MediaUploadResult).path
                setUploadedFileId(p=> fileId);
                setSelectedMedia(s => ({
                    ...s,
                    id: fileId,
                    path
                 }));
            }
        } catch (e: any) {
            setUploadError(e);
        }
    }

    function cancelUpload() {
        cancelTokenSource.current?.cancel();
        setProgress(0);
    }

    async function deleteFile() {
        if (uploadedFileId === selectedMedia?.id) {
            //dont delete the image for real when editing an existing Ad
            setUploadedFileId(undefined);
            uploadedExtension.current = undefined;
            setProgress(0);

        } else {

            const res = await apiServiceInstance.deleteAdImage(`${uploadedFileId}.${uploadedExtension.current}`);
            if (res === true) {
                selectedFile.current = undefined;
                setUploadedFileId(undefined);
                uploadedExtension.current = undefined;
                setProgress(0);

                props.onFileDeleted && props.onFileDeleted();
            }
        }
    }

    const imageSource = `${EnvConstants.getEnvConst().apiUrl}file/ad/${uploadedFileId}.${uploadedExtension.current!}`;
    console.log(uploadedFileId)
    return (
        <>
            <Label>
                Image (.png)
            </Label>
            {!uploadedFileId ?
                <FileUploader

                    errorMessage={uploadError}
                    progressAmount={Math.floor(progress)}
                    accept="image/png"
                    onDropAccepted={e => upload(e[0])}
                    onRetry={() => upload(selectedFile.current!)}
                    onCancel={cancelUpload}
                    overrides={{
                        ContentMessage: { component: () => <Label style={{ textAlign: 'center' }}>Déposer un fichier<br />ou</Label> },
                        ErrorMessage: {
                            component: (p) => {
                                return (
                                    <Block flexDirection="column" alignItems="center" justifyItems="center" display="flex">
                                        <LabelMedium color={colors.red400} $style={{ textAlign: 'center' }} alignItems='center'>{uploadError}</LabelMedium>
                                        <Button onClick={() => { setUploadError(undefined); setProgress(0); }} size="compact" kind='secondary'>Changer d'image</Button>
                                    </Block>
                                )
                            }
                        }
                    }}
                />
                :
                <Block alignItems="center" justifyItems="center" $style={{ border: '1px solid lightgrey' }} width="100px" padding="0">
                    <Button size="mini" kind="tertiary" onClick={deleteFile}>
                        <FiX size={16} />
                    </Button>
                    <Block height="80px">
                        <Img
                            // height={80}
                            src={imageSource}
                            style={{ margin: "0 10px", height: '80px', width: 'auto' }}
                        />
                    </Block>
                </Block>
            }
        </>
    )
}
