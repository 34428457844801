import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag'
import React from 'react'
import GqlPaginatedQueryResult from '../../types/graphql/QueryResult';


const QUERY = gql`
query AccountListingItems($cursor:String, $sort: UserListingItemDTOSort, $where: UserListingItemDTOFilter ) {
    accountListingItems(first: 30, after: $cursor, order_by: $sort, where: $where){
    nodes {
        id
        cashPrizeUnpaid
        email
        firstname
        lastname
        roles      
        lastConnection
        isPremium
        isFreeTrial
    }
      pageInfo{
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export function useUsersListingQuery() {
    const [fetch, query] = useLazyQuery<{ accountListingItems: GqlPaginatedQueryResult<any> }, { cursor?: string, sort?: Record<string, "ASC" | "DESC">, where?: { OR: Record<string, string>[] } }>(QUERY);

    return {
        fetch,
        ...query,
        fetchMore: (cursor: string, search?: string, sortColumn?: string, sortOrder?: string | null) => {
            return query.fetchMore({
                variables: {
                    cursor,
                    sort: sortColumn && sortOrder ? { [sortColumn]: sortOrder } : null,
                    where: !search ? undefined : {
                        OR: [
                            { username_contains: search },
                            { firstname_contains: search },
                            { lastname_contains: search },
                            { email_contains: search }
                        ]
                    }
                }
            })
        },
        refetch: (all = true, search?: string, sortColumn?: string, sortOrder?: string | null) => {
            const sort = sortColumn && sortOrder ? { [sortColumn]: sortOrder } : {}
            const where = !search ? undefined : {
                OR: [
                    { username_contains: search },
                    { firstname_contains: search },
                    { lastname_contains: search },
                    { email_contains: search }
                ]
            }
            return query.fetchMore({ variables: { sort, where, ...(all ? { cursor: undefined } : {}) } });
        },

    };
}