import { format, parse, startOfWeek, getDay, } from 'date-fns/esm'
import { frCA } from 'date-fns/locale'
import React, { ReactElement } from 'react'
import { Calendar, CalendarProps, dateFnsLocalizer, Event } from 'react-big-calendar'
// import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

type toOmit = "localizer" | "messages" | 'culture';

interface Props extends Omit<CalendarProps<Event>, toOmit> {
    views?: ("month" | "week" | "work_week" | "day" | "agenda")[];
    // dragAndDrop?:boolean;
}
// const DnDCalendar = withDragAndDrop(Calendar as any);

export default function BigCalendar<TEvent extends Event>(props: Props): ReactElement {
    const locales = { frCA }
    const messages = {
        allDay: 'Toute la journée',
        day: 'Jour',
        month: 'Mois',
        noEventsInRange: "Rien dans cette période",
        next: 'Suivant',
        today: "Aujourd'hui",
        time: "Heure",
        tomorrow: "Demain",
        showMore: (count) => `Afficher ${count} de plus`,
        week: "Semaine",
        yesterday: 'Hier',
        previous: "Précédent",

    }
    const localizer = dateFnsLocalizer({ format, parse, startOfWeek, getDay, locales })
    return (
        // props.dragAndDrop ?
        // <DnDCalendar
        //     culture="frCA"
        //     localizer={localizer}
        //     messages={messages}
        //     defaultView="month"
        //     {...props}
        //     onDropFromOutside={(e:any)=>{console.log(e)}}
        // />
        // :
        <Calendar
            culture="frCA"
            localizer={localizer}
            messages={messages}
            defaultView="month"
            {...props}
        />

    )
}
