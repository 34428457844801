import React, { ReactElement, useContext, useEffect, useRef } from "react";
import { Tab, Tabs } from "baseui/tabs-motion";
import { Block } from "baseui/block";
import AdImageGallery from "./AdImageGallery";
import { Label } from "../../UiElements/WidgetCard/WidgetCard.styled";
import AdImageUploader from "./AdImageUploader";
import AdsPlannerModalContext from "../../../contexts/AdsPlanner/AdsPlannerModalContext";
import { GqlMedia } from "../../../types/graphql/Media";


export default function (props: { existingMedia: GqlMedia | undefined }): ReactElement {

    const [activeKey, setActiveKey] = React.useState<React.Key>("0");
    const { setSelectedMedia, selectedMedia } = useContext(AdsPlannerModalContext);
    const previouslyUploadedMedia = useRef<GqlMedia>();
    return (
        <div>
            <Tabs
                activeKey={activeKey}
                onChange={({ activeKey }) => {
                    setActiveKey(activeKey);
                    if(activeKey == "0"){
                        previouslyUploadedMedia.current = selectedMedia;
                        setSelectedMedia(props.existingMedia);
                    }
                    if(activeKey == "1"){
                        setSelectedMedia(previouslyUploadedMedia.current);
                    }
                }}
                renderAll>
                <Tab title="Galerie" >
                    <Block width="100%" minWidth='300px;' minHeight="300px;">
                        <AdImageGallery existingMedia={props.existingMedia} onFileDeleted={() => setSelectedMedia(undefined)} />
                    </Block>
                </Tab>
                <Tab title="Upload">
                    <Block width="100%" minWidth='300px;' minHeight="300px;">
                        <Label>
                            <AdImageUploader onFileDeleted={() => setSelectedMedia(undefined)} />
                        </Label>
                    </Block>
                </Tab>
            </Tabs>
        </div>
    )

}