import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { gql, useQuery } from '@apollo/client'
import { GQLWordCount, WordsQueryResult } from '../../../types/graphql/words';
import { Spinner } from 'baseui/spinner';
import WordSelector from '../../StandardWordScheduler/WordSelector/WordSelector';
import { mapGqlWordToSelectWord } from '../../StandardWordScheduler/models/WordSchedulerUtils';
import { LabelSmall } from 'baseui/typography';
import { FormControl } from 'baseui/form-control';
import { RadioGroup, Radio } from 'baseui/radio'
import { GqlChallengeType } from '../../../types/graphql/PeriodicChallengeInput';
import { GQL_WordsAndPeriods_Input } from '../../../types/graphql/WordsAndPeriodsInput';

interface Props {
    mediaType: GqlChallengeType,
    wordCount: GQLWordCount,
    words: GQL_WordsAndPeriods_Input.Word_WordGroupInputType[],
    onChange: (key: 'mediaType' | 'words' | 'wordCount', value: GqlChallengeType | (GQL_WordsAndPeriods_Input.Word_WordGroupInputType | undefined)[] | GQLWordCount | undefined) => void

}

const WORDS_QUERY = gql`query {
    allWords{
      totalCount
        nodes {
          id
          translations {
            languageId
            value
          }
        }
      }
}
`;

export default function PeriodicChallengeWordBasedFrame({ words, ...props }: Props): ReactElement {
    const [allWords, setAllwords] = useState<{ id: string; fr: string; en: string; }[]>([]);
    const wordsQuery = useQuery<WordsQueryResult>(WORDS_QUERY, { fetchPolicy: 'cache-and-network', onCompleted: (d) => { setAllwords(d.allWords.nodes.map(mapGqlWordToSelectWord)); } });
    const [localWordSelection, setLocalWordSelection] = useState<typeof allWords>([]);

    useEffect(() => {
        const newSelection: typeof allWords = new Array(9);
        if (words && allWords) {

            words.forEach((w, i) => {
                const word = allWords.filter(x => x.id == w?.wordId)[0];
                newSelection[w?.order ?? i] = word;
            });
        }

        setLocalWordSelection(newSelection);
    }, [words.length, allWords.length]);

    function onWordSelectionChange(idx: number, id?: string) {
        const newValue: (GQL_WordsAndPeriods_Input.Word_WordGroupInputType | undefined)[] = [...words];
        newValue[idx] = id ? { order: idx, wordId: id } : undefined;

        props.onChange('words', newValue);
    }

    const selectedIds = (words || []).filter(w => w != undefined).map(x => x.wordId);

    return (
        <FlexGridItem minWidth="300px" flex={1}>
            {wordsQuery.loading ? <Spinner /> :
                <>
                    <FormControl label="Nbr. Mots ">
                        <RadioGroup
                            required
                            align="horizontal"
                            name="horizontal"
                            value={props.wordCount}
                            onChange={e => props.onChange('wordCount', e.target.value as GQLWordCount)}
                        >
                            <Radio value={GQLWordCount.One}>1</Radio>
                            <Radio value={GQLWordCount.Two}>2</Radio>
                            <Radio value={GQLWordCount.Three}>3</Radio>
                        </RadioGroup>
                    </FormControl>
                    <FormControl label="Type de média du challenge">
                        <RadioGroup value={props.mediaType} required onChange={e => props.onChange('mediaType', e.target.value as GqlChallengeType)}>
                            <Radio value={GqlChallengeType.PHOTO}>Photo</Radio>
                            <Radio value={GqlChallengeType.VIDEO}>Vidéo</Radio>
                            <Radio value={GqlChallengeType.UNDEFINED}>Les deux</Radio>
                        </RadioGroup>
                    </FormControl>

                    <LabelSmall marginBottom="5px">Mots</LabelSmall>
                    <FlexGrid flexDirection={"column"} gridRowGap={'20px'}>

                        <FlexGridItem maxWidth="200px">
                            <FlexGrid gridRowGap={'5px'} >
                                <FlexGridItem>
                                    <WordSelector lang="fr" usedIds={selectedIds} value={localWordSelection[0]} onValueChange={e => onWordSelectionChange(0, e)} allwords={allWords} loading={wordsQuery.loading} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <WordSelector lang="fr" usedIds={selectedIds} value={localWordSelection[1]} onValueChange={e => onWordSelectionChange(1, e)} allwords={allWords} loading={wordsQuery.loading} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <WordSelector lang="fr" usedIds={selectedIds} value={localWordSelection[2]} onValueChange={e => onWordSelectionChange(2, e)} allwords={allWords} loading={wordsQuery.loading} />
                                </FlexGridItem>
                            </FlexGrid>
                        </FlexGridItem>

                        <FlexGridItem maxWidth="200px">
                            <FlexGrid gridRowGap={'5px'} >
                                <FlexGridItem>
                                    <WordSelector lang="fr" usedIds={selectedIds} value={localWordSelection[3]} onValueChange={e => onWordSelectionChange(3, e)} allwords={allWords} loading={wordsQuery.loading} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <WordSelector lang="fr" usedIds={selectedIds} value={localWordSelection[4]} onValueChange={e => onWordSelectionChange(4, e)} allwords={allWords} loading={wordsQuery.loading} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <WordSelector lang="fr" usedIds={selectedIds} value={localWordSelection[5]} onValueChange={e => onWordSelectionChange(5, e)} allwords={allWords} loading={wordsQuery.loading} />
                                </FlexGridItem>
                            </FlexGrid>
                        </FlexGridItem>
                        <FlexGridItem maxWidth="200px">
                            <FlexGrid gridRowGap={'5px'}>
                                <FlexGridItem>
                                    <WordSelector lang="fr" usedIds={selectedIds} value={localWordSelection[6]} onValueChange={e => onWordSelectionChange(6, e)} allwords={allWords} loading={wordsQuery.loading} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <WordSelector lang="fr" usedIds={selectedIds} value={localWordSelection[7]} onValueChange={e => onWordSelectionChange(7, e)} allwords={allWords} loading={wordsQuery.loading} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <WordSelector lang="fr" usedIds={selectedIds} value={localWordSelection[8]} onValueChange={e => onWordSelectionChange(8, e)} allwords={allWords} loading={wordsQuery.loading} />
                                </FlexGridItem>
                            </FlexGrid>
                        </FlexGridItem>
                    </FlexGrid>
                </>
            }
        </FlexGridItem>
    )
}
