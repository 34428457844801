import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "baseui/button";
import {
    StyledRoot,
    StyledTableHeadCell,
    StyledTableHeadRow,
    StyledTableBodyRow,
    StyledTable,
    StyledTableBodyCell,
    StyledTableBody,
    StyledTableHead,
} from 'baseui/table-semantic';
import { HeadingSmall } from "baseui/typography";
import { format } from "date-fns";
import { frCA } from "date-fns/locale";
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { FiSave } from "react-icons/fi";
import { AllPeriodQueryResponse, GQLWordPeriod } from "../../types/graphql/AllPeriods";
import { WordsQueryResult } from "../../types/graphql/words";
import { GQL_WordsAndPeriods_Input } from "../../types/graphql/WordsAndPeriodsInput";
import Loader from "../UiElements/Loader/Loader";
import { WordSchedulerDisplayData, WordSchedulerDisplayDataContainer } from "./models/WordSchedulerDisplayData";
import { onWordChangeEvent } from "./models/WordSchedulerEventTypes";
import { WordSchedulerFlattenWord } from "./models/WordSchedulerFlattenWord";
import { addWordSelectorEventToWordSchedulerModel, getPeriodNumberFromDate, mapGqlWordToSelectWord } from "./models/WordSchedulerUtils";
import WordSelector from "./WordSelector/WordSelector";

const PERIOD_QUERY = gql`query periods($start:DateTime, $end:DateTime){
  allWordPeriods(where:{ dateStart_gte:$start, dateStart_lte:$end, dateDeleted:null }, order_by:{dateStart:ASC}){
    nodes {
      id
      dateStart
      dateEnd
      wordGroups {
        id
        order
        word_WordGroups {
          order
          word {
            id
            translations {
              languageId
              value
            }
          }
        }
      }
    }
  }
}`;

const WORDS_QUERY = gql`query {
      allWords{
        totalCount
          nodes {
            id
            translations {
              languageId
              value
            }
          }
        }
  }
  `;


interface Props {
    startDate: Date,
    endDate: Date,
    errors: { periodId: string, groupOrder: number, wordOrder: number }[],
    onDataChange?: (data: GQL_WordsAndPeriods_Input.PeriodWordsInputType[], allData: GQL_WordsAndPeriods_Input.PeriodWordsInputType[]) => void;
    refreshToggler: boolean;
}

export default function StandardWordScheduler(props: Props): ReactElement {
    const [data, setData] = useState<WordSchedulerDisplayDataContainer>(new WordSchedulerDisplayDataContainer([]));
    const [allWords, setAllWords] = useState<{ id: string, fr: string, en: string }[]>([])
    const dataToSave = useRef<GQL_WordsAndPeriods_Input.PeriodWordsInputType[]>([]);
    const query = useQuery<AllPeriodQueryResponse, { start: string, end: string }>(
        PERIOD_QUERY,
        {
            variables: { start: props.startDate.toISOString(), end: props.endDate.toISOString() },
            onCompleted: (d) => setData(new WordSchedulerDisplayDataContainer(d.allWordPeriods.nodes)),
        });
    const wordsQuery = useQuery<WordsQueryResult>(WORDS_QUERY, { onCompleted: (d) => { setAllWords(d.allWords.nodes.map(mapGqlWordToSelectWord)); } });

    const { refreshToggler } = props;
    let lastMonthLabel = '';

    useEffect(() => {
        if (query) {
            query.fetchMore<AllPeriodQueryResponse, any>({
                variables: {
                    start: props.startDate,
                    end: props.endDate
                },
            }).then(res => {
                setData(new WordSchedulerDisplayDataContainer(res.data?.allWordPeriods.nodes ?? []))
            });
        }
    }, [refreshToggler])

    function onWordChange(infos: onWordChangeEvent, item: WordSchedulerFlattenWord) {
        dataToSave.current = addWordSelectorEventToWordSchedulerModel(infos, dataToSave.current);

        const newData = data.editWord(infos, allWords);
        const newDataContainer = data.fromDisplayData(newData);

        setData(newDataContainer);
        if (props.onDataChange) {
            props.onDataChange(dataToSave.current, data.convertToSaveable());
        }
    }

    return (
        <StyledRoot isLoading={query?.loading} emptyMessage="Aucune période pour la sélection" >
            <StyledTable >
                <StyledTableHead>
                    <StyledTableHeadRow>
                        <StyledTableHeadCell>#</StyledTableHeadCell>
                        <StyledTableHeadCell>Période</StyledTableHeadCell>
                        <StyledTableHeadCell colSpan={3}>Bloc 1</StyledTableHeadCell>
                        <StyledTableHeadCell colSpan={3}>Bloc 2</StyledTableHeadCell>
                        <StyledTableHeadCell colSpan={3}>Bloc 3</StyledTableHeadCell>
                        <StyledTableHeadCell colSpan={3}>Bloc 4</StyledTableHeadCell>
                    </StyledTableHeadRow>
                </StyledTableHead>
                <StyledTableBody overrides={{ Root: { style: { maxHeight: '100px' } } }}>
                    {query?.loading ? <Loader /> :
                        data?.data?.map(row => {
                            const isPast = row.dateStart < new Date();
                            const currentMonth = format(new Date(row.dateStart!), 'MMMM yyyy', { locale: frCA });
                            const showMonthLabel = currentMonth != lastMonthLabel;
                            lastMonthLabel = currentMonth;
                            return (
                                <React.Fragment key={row.id}>
                                    {showMonthLabel &&
                                        <StyledTableBodyRow key={lastMonthLabel}>
                                            <StyledTableBodyCell colSpan={14}><HeadingSmall>{currentMonth}</HeadingSmall></StyledTableBodyCell>
                                        </StyledTableBodyRow>
                                    }
                                    <StyledTableBodyRow key={row.id!}>
                                        <StyledTableBodyCell>{getPeriodNumberFromDate(row.dateStart!)}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{format(new Date(row.dateStart!), 'dd.MM')}-{format(new Date(row.dateEnd!), 'dd.MM.yyyy')}</StyledTableBodyCell>
                                        {
                                            row.flattenWordGroups.map(word => (
                                                <StyledTableBodyCell style={{ borderLeft: word.wordOrder == 0 ? '1px solid' : undefined }} key={word.wordGroupOrder + '.' + word.wordOrder}>
                                                    {
                                                        <WordSelector
                                                            lang="fr"
                                                            disabled={isPast && word.word != null && !word.isDirty}
                                                            allwords={allWords}
                                                            showError={props.errors.some(x => x.periodId == row.id && x.groupOrder == word.wordGroupOrder && x.wordOrder == word.wordOrder)}
                                                            loading={wordsQuery.loading}
                                                            value={word.word ? allWords?.filter(x => x.id == word.word!.id)[0] ?? undefined : undefined}
                                                            onValueChange={id => onWordChange({
                                                                wordId: id,
                                                                groupId: word?.wordGroupId,
                                                                wordGroupOrder: word.wordGroupOrder!,
                                                                periodId: row.id!,
                                                                wordOrder: word.wordOrder!
                                                            }, word)}
                                                        />
                                                    }
                                                </StyledTableBodyCell>))
                                        }
                                    </StyledTableBodyRow>
                                </React.Fragment>
                            );
                        })
                    }
                </StyledTableBody>
            </StyledTable>
        </StyledRoot>
    )

}


