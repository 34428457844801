import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import FileInput from '../singleComponents/FileInput';
import { ExcelParser } from '../../utils/ExcelParser.web';
import { Button } from 'baseui/button';
import Loader from '../UiElements/Loader/Loader';
import { StyledBodyCell, StyledHeadCell, StyledTable } from 'baseui/table-grid';
import { GQL_WordsAndPeriods_Input } from '../../types/graphql/WordsAndPeriodsInput'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    SIZE,
    ROLE
} from "baseui/modal";
import { useStyletron } from 'styletron-react';
import Container from '../UiElements/Container/Container';
import { GqlTranslation } from '../../types/graphql/AllPeriods';

const LANG_QUERY = gql`
  query {
    allLanguages {
      id
      name
    }
  }
`;

const SAVE_WORD_MUT = gql`
  mutation addWords($items:  [WordInputWithRowInput]) {
      addWords(values: $items){
        error
        row
        translations {
          languageId
          value
        }
      }
  }
`;

interface Props {
    onHide?: (refetch: boolean) => void;
    onShow?: () => void;
}

const AddWordsModal = (props: Props) => {
    const langQuery = useQuery<{ allLanguages: { id: string, name: string }[] }>(LANG_QUERY);
    const [addWords] = useMutation<{ addWords: GQL_WordsAndPeriods_Input.WordInputWithRowInput[] }, { items: GQL_WordsAndPeriods_Input.WordInputWithRowInput[] }>(SAVE_WORD_MUT, { errorPolicy: 'all' });
    const [isVisible, setIsVisible] = useState(false);
    const [wordsFromExcel, setWordsFromExcel] = useState<{ row: number, state?: any, words: GqlTranslation[] }[]>();
    const [isSaving, setIsSaving] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [css] = useStyletron();
    const [showUpload, setShowUpload] = useState(true);
    const { onShow } = props;
    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
            onShow && onShow();
        }, 100);
    }, []);

    const onFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowUpload(false)
        setIsSaving(true)
        const fileUpload = event.target;
        const excelParser = new ExcelParser();
        const file = fileUpload.files && fileUpload.files[0];
        try {
            const result = file ? await excelParser.parseFile(file, fileUpload.value) : null;
            if (!result)
                return;

            const header = result[0];
            const invalidHeaders: string[] = [];
            const allLAngs = langQuery.data!.allLanguages;
            header.forEach(h => {
                if (allLAngs.findIndex(x => x.id.toLowerCase() == h.toLowerCase()) === -1) {
                    invalidHeaders.push(h);
                }
            });
            if (invalidHeaders.length > 0) {
                alert("Entêtes invalides: " + header.join(', ') + ". Voici ceux attendus: " + allLAngs.map(x => x.id).join(", "));
                return;
            } else {
                const allWords = result
                    .slice(1)
                    .filter(x => x.filter(w => w != undefined && w.trim().length > 0).length == header.length)
                    .map((x, i) => {
                        const words: { languageId: string, value: string }[] = [];
                        header.forEach((h, i) => words.push({ languageId: h, value: x[i] }));
                        return { row: i, words };
                    });
                setWordsFromExcel(allWords);
            }
        } catch (err) {
            alert(err);
        }

        setIsSaving(false)
    }
    function closeModal() {
        setIsVisible(false);
        setTimeout(() => { props.onHide && props.onHide(true); }, 200)
    }

    const saveWords = async () => {
        if (!wordsFromExcel || wordsFromExcel.length == 0 || isSaving) {
            return;
        }

        setIsSaving(true);
        const words: GQL_WordsAndPeriods_Input.WordInputWithRowInput[] = wordsFromExcel.map(x => ({ row: x.row, translations: x.words }));
        try {
            const res = await addWords({ variables: { items: words } });
            if (res.data) {
                const newWords = res.data.addWords.map(x => ({ row: x.row, words: x.translations!, state: x.error }))
                setWordsFromExcel(newWords);
            }
        } catch (err) {
            console.log(err);
        }
        setIsSaving(false);
        setIsDone(true);
    };

    const allLAngs = langQuery.data!.allLanguages;
    const gridTemplateColumns = "10% " + allLAngs.map(x => (70 / allLAngs.length) + '%').join(' ') + ' 20%';
    return (
        <Container>
            {/*@ts-ignore*/}
            <Modal isOpen={isVisible} onClose={closeModal} closeable animate autoFocus size={SIZE.full} role={ROLE.dialog}>

                <ModalHeader >
                    Ajout de mots
                </ModalHeader>

                <ModalBody>
                    <span>{wordsFromExcel && ("Total: " + wordsFromExcel.length)}</span>
                    <div className={css({ height: '300px' })}>

                        <StyledTable $gridTemplateColumns={gridTemplateColumns} style={{ gridTemplateRows: "repeat(1000, min-content)" }}>
                            {/* Header */}
                            <StyledHeadCell key={"e-idx"}>#</StyledHeadCell>
                            {langQuery.data?.allLanguages.map(l => {
                                return (
                                    <StyledHeadCell key={l.id}>{l.name}</StyledHeadCell>
                                );
                            })}
                            <StyledHeadCell key={"e-state"} ></StyledHeadCell>

                            {/* Body */}
                            {
                                wordsFromExcel?.map((x, idx) => {
                                    return (
                                        <div role="row" key={"e-row-" + idx} className={css({ display: 'contents' })}>
                                            <StyledBodyCell $striped={idx % 2 !== 0} key={"e-idx"}>{idx}</StyledBodyCell>

                                            {allLAngs.map((l) => {
                                                const word = x.words.filter(w => w.languageId!.toLowerCase() == l.id.toLowerCase())[0];
                                                return (
                                                    <StyledBodyCell $striped={idx % 2 !== 0} key={"e-" + idx + '-' + word.languageId} >
                                                        {word.value}
                                                    </StyledBodyCell>
                                                )
                                            })}

                                            <StyledBodyCell $striped={idx % 2 !== 0} key={"e-" + idx + '-state'} >

                                                {x.state && <>
                                                    <span style={{ color: 'red', fontSize: 20 }} children="⚠" />
                                                    <span style={{ color: 'red' }}>{x.state}</span>
                                                </>}
                                            </StyledBodyCell>
                                        </div>
                                    );
                                })
                            }
                        </StyledTable>
                    </div>
                </ModalBody>
                <ModalFooter style={{ flexDirection: 'row', marginTop: 10 }}>
                    {isSaving && <Loader />
                        ||
                        <>
                            {showUpload &&
                                <FileInput onFileSelected={onFileSelected}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    style={{ width: 250, maxWidth: '100%', marginBottom: 20 }}>
                                    Importer un fichier Excel
                                </FileInput>
                                ||
                                <Button disabled={isSaving} onClick={saveWords}>Enregistrer</Button>
                            }
                        </>
                    }

                </ModalFooter>
            </Modal>
        </Container>
    )
}

export default AddWordsModal



function wait(time: number): Promise<any> {
    return new Promise(resolve => {
        setTimeout(resolve, time);
    })
}