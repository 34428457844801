import React from 'react'
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Spinner } from 'baseui/spinner'
import GqlPaginatedQueryResult from '../../types/graphql/QueryResult'
import Playoff from '../../models/Dto/Playoff'
import PlayoffListingRow from './PlayoffListingRow'
import { FaPlusCircle } from 'react-icons/fa'
import CreateEditPlayoffModal from './CreateEditPlayoffModal'

type Props = {
    loading: boolean;
    data?: GqlPaginatedQueryResult<Playoff>
    refresh: ()=>void
}

// const style = {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

export default function PlayoffListing({ data, loading, refresh }: Props) {
    const [showModal, setShowModal] = React.useState(false);

    return (
        <>
            <TableContainer>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }} colSpan={6}>
                                <Typography>
                                    Playoffs - Dates
                                    <IconButton style={{ marginLeft: 10 }} onClick={() => setShowModal(true)}><FaPlusCircle /></IconButton>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading ? <Spinner /> :
                                data?.nodes.map((x, i) => <PlayoffListingRow refresh={refresh} playoff={x} key={x.id} />)
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <CreateEditPlayoffModal onSave={refresh} show={showModal} close={()=>setShowModal(false)} />
        </>
    )
}