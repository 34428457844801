import React from 'react';
import { GqlMedia } from '../../types/graphql/Media';

interface AdsPlannerModalContextType {
    selectedMedia: GqlMedia | undefined,
    setSelectedMedia: React.Dispatch<React.SetStateAction<GqlMedia | undefined>>
    setIsDirty: React.Dispatch<React.SetStateAction<boolean>>
    isDirty: boolean
}

const AdsPlannerModalContext = React.createContext<AdsPlannerModalContextType>({
    selectedMedia: undefined,
    setSelectedMedia: (media) => {return},
    isDirty: false,
    setIsDirty: (value) => {return},
});

export default AdsPlannerModalContext