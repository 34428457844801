import { styled } from 'baseui';
import * as React from 'react'

interface IHighLighterProps {
    searchTerm: string;
    text: string;
    wrapperClass?: string;
    itemClass?: string;
}

const HighlightedItem = styled('span', {
    backgroundColor:'orange',
    color:'black'
})

export default React.memo(function HighLighter(props: IHighLighterProps) {

    const renderName = React.useCallback((name: string) => {

        const splitPattern = props.searchTerm ? new RegExp("(" + props.searchTerm + ")", "i") : '';
        const miniWords = name.split(splitPattern);

        const html: JSX.Element[] = [];

        miniWords.forEach((miniWord, index) => {
            if (props.searchTerm != null && miniWord.toLowerCase() == props.searchTerm.toLowerCase()) {
                html.push(<HighlightedItem>{miniWord}</HighlightedItem>)
            } else {
                html.push(<span className={props.itemClass}>{miniWord}</span>)
            }

        });

        return <span> {html}</span>;
    },[props.searchTerm]);

    
    return (
        <div className={props.wrapperClass}>{renderName(props.text ?? '')}</div>
    )
});