import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { FileUploader } from "baseui/file-uploader";
import { Label } from '../UiElements/WidgetCard/WidgetCard.styled'
import { apiServiceInstance } from '../../services/ApiService';
import { MediaUploadResult } from '../../models/Dto/MediaUploadResult';
import Axios, { CancelTokenSource } from 'axios';
import { Block } from 'baseui/block';
import { FiX } from 'react-icons/fi';
import { EnvConstants } from '../../constants/EnvConstants';
import { Img } from 'react-image';
import { Button } from 'baseui/button';
import { GqlMedia } from '../../types/graphql/Media';
import { colors } from 'baseui/tokens';
import { LabelMedium } from 'baseui/typography';

interface Props {
    onUploaded?: (id: string) => void;
    onFileDeleted?: () => void;
    existingMedia?: GqlMedia;
    destinationDirectory: string;
    disabled?:boolean
}

export default function ImageUploader(props: Props): ReactElement {

    const { existingMedia } = props;
    const [progress, setProgress] = useState(0)
    const [uploadedFileId, setUploadedFileId] = useState<string>();
    const [uploadError, setUploadError] = useState<string>();
    const selectedFile = useRef<File>();
    const cancelTokenSource = useRef<CancelTokenSource>();
    const uploadedExtension = useRef<string>();

    useEffect(() => {
        console.log('existing media', existingMedia)
        const splitted = existingMedia?.path?.split('.');
        if(splitted)
            uploadedExtension.current = splitted?.pop();
        setUploadedFileId(splitted?.pop()?.split('/').pop() ?? existingMedia?.id);

    }, [existingMedia])


    async function upload(file: File) {
        setUploadError(undefined);
        setUploadedFileId(undefined);

        selectedFile.current = file;
        cancelTokenSource.current = Axios.CancelToken.source();
        try {

            const result = await apiServiceInstance.uploadGenericMedia(props.destinationDirectory,file, {
                onUploadProgress: e => setProgress(e.loaded / e.total * 100),
                cancelToken: cancelTokenSource.current.token
            });
console.log(result)
            if (typeof result == "string" || !result)
                setUploadError(result || "Une erreur est survenue");
            else if ((result as MediaUploadResult).id) {
                console.log('success upload')
                uploadedExtension.current = file.name.split('.').pop();
                props.onUploaded && props.onUploaded((result as any).id);
                setUploadedFileId((result as MediaUploadResult).id);
            }
        } catch (e : any) {
            setUploadError(e);
        }
    }

    function cancelUpload() {
        cancelTokenSource.current?.cancel();
        setProgress(0);
    }

    async function deleteFile() {
        if (uploadedFileId?.replaceAll("-",'') == props.existingMedia?.id?.replaceAll("-","")) {
            //dont delete the image for real when editing an existing Ad
            setUploadedFileId(undefined);
            uploadedExtension.current = undefined;
            setProgress(0);
            props.onFileDeleted && props.onFileDeleted();

        } else {

            const res = await apiServiceInstance.deleteGenericMedia(props.destinationDirectory,`${uploadedFileId}.${uploadedExtension.current}`);
            if (res === true) {
                selectedFile.current = undefined;
                setUploadedFileId(undefined);
                uploadedExtension.current = undefined;
                setProgress(0);

                props.onFileDeleted && props.onFileDeleted();
            }
        }
    }

    const imageSource =  `${EnvConstants.getEnvConst().apiUrl}file/generic-media/${props.destinationDirectory}/${uploadedFileId}.${uploadedExtension.current!}`;
    
    return (
        <>
            {!uploadedFileId ?
                <FileUploader
                    disabled={props.disabled}
                    errorMessage={uploadError}
                    progressAmount={Math.floor(progress)}
                    accept={"image/png"}
                    onDropAccepted={e => upload(e[0])}
                    onRetry={() => upload(selectedFile.current!)}
                    onCancel={cancelUpload}
                    overrides={{
                        ContentMessage: { component: () => <Label style={{ textAlign: 'center' }}>Déposer un fichier<br />ou</Label> },
                        ErrorMessage: {
                            component: (p) => {
                                return (
                                <Block flexDirection="column" alignItems="center" justifyItems="center" display="flex">
                                    <LabelMedium color={colors.red400} $style={{textAlign:'center'}} alignItems='center'>{uploadError}</LabelMedium>
                                    <Button onClick={()=>{setUploadError(undefined);setProgress(0);}} size="compact" kind='secondary'>Changer d'image</Button>
                                </Block>
                                )
                            }
                        }
                    }}
                />
                :
                <Block alignItems="center" justifyItems="center" $style={{ border: '1px solid lightgrey' }} width="100px" padding="0">
                    <Button size="mini" kind="tertiary" type="button" onClick={deleteFile}>
                        <FiX size={16} />
                    </Button>
                    <Block height="80px">
                        <Img
                            // height={80}
                            src={imageSource}
                            style={{ margin: "0 10px", height: '80px', width: 'auto' }}
                        />
                    </Block>
                </Block>
            }
        </>
    )
}
