import React, { useContext, } from 'react';
import LoginScreen from '../screens/LoginScreen.web';
import HomeScreen from '../screens/HomeScreen';
import { AuthContext } from '../contexts/AuthContext';
import WordsScreen from '../screens/WordsScreen';
import PlanificationScreen from '../screens/PlanificationScreen';
import { Route, Routes } from 'react-router-dom';
import Loader from '../components/UiElements/Loader/Loader';
import { AdsScreen } from '../screens/AdsScreen';
import AppSettingsScreen from '../screens/AppSettingsScreen';
import PeriodicChallengesScreen from '../screens/PeriodicChallengesScreen';
import ModerationScreen from '../screens/ModerationScreen';
import UsersScreen from '../screens/users/UsersScreen';
import UserDetailsScreen from '../screens/users/UserDetailScreen';
import { UserRoles } from '../models/Dto/UserInfos';
import UnauthorizedScreen from '../screens/UnauthorizedScreen';
import PlayoffsScreen from '../screens/PlayoffsScreen';
import FeaturedAppsScreen from '../screens/FeaturedAppsScreen';

const Navigation = () => {
    const [authContextTools, isSignedIn, setIsSignedIn] = useContext(AuthContext);
    const roles = authContextTools?.getUserInfos()?.roles ?? [];
    const isAdmin = Array.isArray(roles) && roles.some(x => x === UserRoles.admin) || roles === UserRoles.admin || roles === 'admin';
    console.log("ICI", roles, isAdmin, isSignedIn)
    if (isSignedIn === undefined) {
        return <div style={{ justifyContent: 'center', height: '100%' }}><Loader /></div>
    }
    if (!isSignedIn) {
        return (
            <Routes >
                <Route path="/" element={<LoginScreen />} />
            </Routes>
        )
    }


    // if (isSignedIn && !isAdmin) {
    //     console.log("navigation unauth")
    //     return <Switch>
    //         <Route name="Unauthorized" component={UnauthorizedScreen} />
    //     </Switch>
    // }

    return (
        <Routes>
            <Route path="/words" element={<WordsScreen />} />
            <Route path="/planification" element={<PlanificationScreen />} />
            <Route path="/ads" element={<AdsScreen />} />
            <Route path="/app-settings" element={<AppSettingsScreen />} />
            <Route path="/periodic-challenges" element={<PeriodicChallengesScreen />} />
            <Route path="/playoffs" element={<PlayoffsScreen />} />
            <Route path="/moderation" element={<ModerationScreen />} />
            <Route path="/featured-apps" element={<FeaturedAppsScreen />} />
            <Route
                path="/users"
                children={[
                    <Route key="users" index element={<UsersScreen />} />,
                    <Route key="userDetails" path=":id" element={<UserDetailsScreen />} />
                ]}
            />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/" element={<HomeScreen />} />
        </Routes>

    );
}

export default Navigation;