import { addDays, addHours, addMonths, differenceInDays, differenceInMonths, startOfDay } from "date-fns";
import { GqlDurationType } from "../types/graphql/PeriodicChallengeInput";

const periodicMonthStart = new Date("2021-01-01T05:00:00Z");
const periodicWeekStart = new Date("2021-01-01T05:00:00Z");
const periodicDayStart = new Date("2021-01-09T05:00:00Z");
const periodicHourStart = new Date("2021-01-17T18:00:00Z");

const periodicMonthConfig = { getParticipationEndExcluded: (date: Date) => addMonths(new Date(date), 1), getVoteEndExcluded: (date: Date) => addMonths(new Date(date), 1) }
const periodicWeekConfig =  { getParticipationEndExcluded: (date: Date) => addDays(new Date(date), 7), getVoteEndExcluded: (date: Date) => addDays(new Date(date), 21) }
const periodicDayConfig =   { getParticipationEndExcluded: (date: Date) => addDays(new Date(date), 1), getVoteEndExcluded: (date: Date) => addDays(new Date(date), 21) }
const periodicHourConfig =  { getParticipationEndExcluded: (date: Date) => addHours(new Date(date), 1), getVoteEndExcluded: (date: Date) => addDays(new Date(date), 21) }

function getAllDates(firstDate: Date, occurences: number, config: typeof periodicMonthConfig, datesArray: { startDate: Date, participationEndExcluded: Date, voteEnd: Date, }[] = []) {
    if (occurences == 0)
        return [];
    const newDate = config.getVoteEndExcluded(firstDate);
    datesArray.push({ startDate: firstDate, participationEndExcluded: config.getParticipationEndExcluded(firstDate), voteEnd: new Date(newDate), });
    getAllDates(newDate, occurences - 1, config, datesArray);
    return datesArray;
}

function getFirstDateFromType(type:GqlDurationType){
    return type === GqlDurationType.Monthly ? periodicMonthStart
    : type === GqlDurationType.Weekly ? periodicWeekStart
    : type === GqlDurationType.Daily ? periodicDayStart
    : periodicHourStart;
}

const getNextMonthDates = (firstDate: Date, count: number) => getAllDates(firstDate, count, periodicMonthConfig).map((x,i)=> ({...x, index: i}));
const getNextWeekDates = (firstDate: Date, count: number) => getAllDates(firstDate, count, periodicWeekConfig).map((x,i)=> ({...x, index: i}));
const getNextDayDates = (firstDate: Date, count: number) => getAllDates(firstDate, count, periodicDayConfig).map((x,i)=> ({...x, index: i}));
const getNextHourDates = (firstDate: Date, count: number) => getAllDates(firstDate, count, periodicHourConfig).map((x,i)=> ({...x, index: i}));


const getChallengeNumber = (durationType: GqlDurationType, dateStart: Date):string =>{
    const prefix = {[GqlDurationType.Monthly]: 'M', [GqlDurationType.Weekly]: "S", [GqlDurationType.Daily]: "J", [GqlDurationType.Hourly]: 'H'}[durationType];
    let number = 0;

    if(durationType === GqlDurationType.Monthly){
        const diffMonth = differenceInMonths(dateStart, getFirstDateFromType(durationType));
        number = diffMonth;
    }
    else{
        const diffDate = differenceInDays( startOfDay(dateStart),  startOfDay(getFirstDateFromType(durationType)));
        number = Math.ceil(diffDate / 21);
        if(durationType == GqlDurationType.Daily)
        console.log(diffDate)
    }

    return `${prefix}${number + 1}`
}

export {
    periodicMonthConfig,
    periodicWeekConfig,
    periodicDayConfig,
    periodicHourConfig,
    periodicMonthStart,
    periodicWeekStart,
    periodicDayStart,
    periodicHourStart,

    getNextMonthDates,
    getNextWeekDates,
    getNextDayDates,
    getNextHourDates,
    getChallengeNumber,
}