import { Datepicker, ORIENTATION } from 'baseui/datepicker'
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { frCA } from 'date-fns/locale'
import React, { ReactElement, useState } from 'react'

interface Props {
    onChange: (dates: Date[]) => void;
    initialValue?: Date[]
    clearable?:boolean
}

export default function DaterangePicker(props: Props): ReactElement {
    const [rangeValue, setRangeValue] = useState<Date[]>(props.initialValue || [addMonths(startOfMonth(new Date()),-1), addMonths(endOfMonth(new Date()),1)]);

    function onRangeValueChange(date: Date | Date[]) {
        const newRangeValue = Array.isArray(date) ? date : [date];

        setRangeValue(newRangeValue);
        if (newRangeValue.length === 2) {
            props.onChange(newRangeValue);
        }
    }
    return (
        <Datepicker
            locale={frCA}
            value={rangeValue}
            onChange={({ date }) => onRangeValueChange(date)}
            range
            clearable={props.clearable}
            orientation={ORIENTATION.horizontal}
            monthsShown={2}
        />
    )
}
