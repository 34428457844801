import React, { Children } from 'react';
import { NavLink } from 'react-router-dom';

type ActiveLinkProps = {
  children: React.ReactNode;
  activeClassName: string;
  href: string;
};

const ActiveLink = ({
  children,
  activeClassName,
  href,
  ...props
}: ActiveLinkProps) => {
  // const child: any = Children.only(children);
  // const childClassName = child.props.className || '';
  // const newpathname = location.pathname.split('/').slice(1, 2);
  // const path = `/${newpathname[0]}`;

  // const className =
  //   path === href
  //     ? `${childClassName} ${activeClassName}`.trim()
  //     : childClassName;

  return (
    <NavLink to={href} {...props}>
      {children}
    </NavLink>
  );
};

export default ActiveLink;
