import { GQLWordCount } from "./words"
import { GQL_WordsAndPeriods_Input } from "./WordsAndPeriodsInput"

export type GqlPeriodicChallengeInputTypeInput = {
    durationType: GqlDurationType,
    dateParticipationStart: Date,
    dateParticipationEnd: Date,
    dateVoteStart: Date,
    dateVoteEnd: Date,
    periodicChallengeFrame: GqlPeriodicChallengeFrame,
    instructions: languageString[],
    quote: languageString[],
    mediaId?: string, 
    mediaType: GqlChallengeType,
    wordCount: GQLWordCount,
    words: GQL_WordsAndPeriods_Input.Word_WordGroupInputType[]
}
export enum GqlChallengeType {
    PHOTO="PHOTO",
    VIDEO="VIDEO",
    UNDEFINED="UNDEFINED",
}
export type languageString = {
    language: string,
    value: string
}

export enum GqlDurationType {
    NewPv = "NEWPV",
    Monthly = "MONTHLY",
    Weekly = "WEEKLY",
    Daily = "DAILY",
    Hourly = "HOURLY",
    Playoff = "PLAYOFF"
}

export enum GqlPeriodicChallengeFrame {
    Image = "IMAGE",
    Words = "WORDS",
    Undefined = "UNDEFINED",
}

export enum GqlCompositeChallengeType {
    P3 = "P3",
    P4 = "P4",
    V3 = "V3",
    V4 = "V4",
    MONTH = "MONT",
    WEEK = "WEEK",
    DAY = "DAY",
    HOUR = "HOUR",
    PLAYOFF = "PLAYOFF",
}