import { useQuery } from '@apollo/client';
import gql from 'graphql-tag'
import React, { useEffect } from 'react'


const QUERY = gql`
query GetAccountParticipations($id: ID!, $cursor: String) {
  allParticipations(
    where: { accountId: $id }
    includeBanned: true
    first: 30
    after: $cursor
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
    nodes {
      id
      accountId
      title
      dateCreated
      challengeId
      likeCount
      commentCount
      winningPlace
      isWinner
      viewCount
      winningPoints
      word_Participations {
        order
        word {
          id
          translations {
            languageId
            value
          }
        }
      }
      challenge {
        id
        durationType
        type
        wordCount
        discriminator
        durationType
        type
        word_Challenges {
          word {
            id
            translations {
              languageId
              value
            }
          }
        }
      }
      media {
        path
        id
        type
      }
      moderationInfos {
        isBanned
        bannedReason
        otherBannedReason {
          language
          value
        }
      }
    }
  }
}

`;

type UserParticipationQueryResult = {
    allParticipations: {
        pageInfo: any,
        totalCount: number,
        nodes: Participation[],
    }
}

export function UseUserParticipationQuery(userId: string) {
    const query = useQuery<UserParticipationQueryResult>(QUERY, {variables: {id: userId}, fetchPolicy:'network-only'},);
    useEffect(()=>{
       query.refetch({id: userId, cursor: null}) 
    },[userId])
    return query;
}