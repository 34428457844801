import { gql, useQuery } from '@apollo/client';
import { LayersManager } from 'baseui/layer';
import { colors } from 'baseui/tokens';
import { endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import React, { ReactElement, useState } from 'react'
import { Event, stringOrDate } from 'react-big-calendar';
import { GqlAd } from '../../types/graphql/Ads';
import { ImageUtils } from '../../utils/ImageUtils';
import BigCalendar from '../UiElements/BigCalendar/BigCalendar'
import AdsPlannerModalProvider from "../../contexts/AdsPlanner/AdsPlannerModalProvider";
import { AdDetailsModalProps } from "./AdDetailsModal/AdDetailsModal";

const GET_ADS_QUERY = gql`
query getAds($start:DateTime!, $end:DateTime!){
    allAds(where:{dateStart_gte:$start, dateEnd_lte:$end}){
        id
        dateEnd
        dateStart
        media {
          id
          path
          name
          keywords
        }
    }
}
`;


export default function AdsPlannerCalendar(): ReactElement {
    const [creationModalProps, setCreationModalProps] = useState<{ start?: Date, end?: Date, isOpened: boolean } | AdDetailsModalProps>();
    const allAdsQuery = useQuery<{ allAds: GqlAd[] }, { start: Date, end: Date }>(GET_ADS_QUERY, { fetchPolicy: 'cache-first', variables: { start: startOfMonth(new Date()), end: endOfMonth(new Date()) } });

    function onSelectSlot(e) {
        if (startOfDay(e.start) <= startOfDay(new Date()))
            return;

        setCreationModalProps(s => ({
            end: e.end,
            start: e.start,
            isOpened: true,
        }))
    }

    function closeCreationModal() {
        setCreationModalProps(s => ({ ...s, isOpened: false }))
    }

    async function onRangeChange(e: Date[] | { start: stringOrDate, end: stringOrDate }) {
        if (Array.isArray(e))
            e = { start: e[0], end: e[1] };

        await allAdsQuery.refetch({ start: new Date(e.start), end: new Date(e.end) });
    }

    function onAdSaved(newAds: GqlAd[]) {
        allAdsQuery.updateQuery((prev: { allAds: GqlAd[] }, options: any) => {
            const allAdsClone = [...prev.allAds];

            newAds.forEach(newAd => {
                const existingAdIdx = allAdsClone.findIndex(x => x.id === newAd.id);
                if (existingAdIdx > -1)
                    allAdsClone[existingAdIdx] = newAd;
                else
                    allAdsClone.push(newAd);
            });

            return { allAds: allAdsClone };
        })

    }

    function onAdDeleted(id: string) {
        allAdsQuery.updateQuery((prev: { allAds: GqlAd[] }, options: any) => {
            const allAdsClone = [...prev.allAds];

            const existingAdIdx = allAdsClone.findIndex(x => x.id === id);
            if (existingAdIdx > -1) {
                allAdsClone.splice(existingAdIdx, 1);
            }

            return { allAds: allAdsClone };
        });
    }

    function onEventSelected(event: Event) {
        if (event.start! < new Date())
            return;
        setCreationModalProps(p => {
            return {
                ...p,
                isOpened: true,
                end: event.end,
                start: event.start,
                event: event
            }

        })
    }

    return (
        <>
            <BigCalendar
                views={['month', 'agenda']}
                events={allAdsQuery.data?.allAds.map(ad => ({ start: new Date(ad.dateStart!), end: new Date(ad.dateEnd!), allDay: true, resource: { id: ad.id, media: ad.media, ad } })) ?? []}
                eventPropGetter={(e) => ({ style: { backgroundColor: e.start! < new Date() ? colors.gray200 : colors.blue300, flexDirection: 'row', display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-start', width: '100' } })}
                components={{
                    event: (e) => <img width={50} alt="Ad of the day" src={ImageUtils.getResizedImageUrl(e.event.resource.media?.path, 50)} />,
                }}
                // dragAndDrop
                popup
                onRangeChange={onRangeChange}
                selectable
                onSelectEvent={onEventSelected}
                onSelectSlot={onSelectSlot}
            />
            {/*@ts-ignore*/}
            <LayersManager zIndex={5}>
                <AdsPlannerModalProvider {...creationModalProps} close={closeCreationModal} saved={onAdSaved} deleted={onAdDeleted} />
            </LayersManager>
        </>
    )
}
