import { useQuery } from '@apollo/client';
import gql from 'graphql-tag'
import React from 'react'
import {Account} from "../../models/Dto/UserInfos";


const QUERY = gql`
    query GetSingleUser($id: ID!) {
      allAccounts(where: {id: $id}) {
        nodes{
          id
          email
          credits
          username
          firstname
          lastname
          roles
          media {
            id
            path
          }
          preferredLanguage
          totalPoints
        }
      }
    }
`;

interface UserQueryResponse {
    allAccounts: {
        nodes: Account[]
    }
}

export function useUserQuery(userId: string) {
    return useQuery<UserQueryResponse>(QUERY, {variables: {id: userId}});
}