import React from 'react';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider, LightTheme, DarkTheme } from 'baseui';
import { styletron, debug } from './styletron';
import Layout from './components/Layout/Layout';
import { ThemeSwitcherProvider, THEME } from './contexts/theme/theme.provider';
import { ApolloProvider } from '@apollo/client';
// import { useApollo } from 'apollo/github.client';
// external css
import '@glidejs/glide/dist/css/glide.core.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './assets/css/reset.css';
import 'react-flexbox-grid/dist/react-flexbox-grid.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'typeface-open-sans';
import { NavigationType, Router } from 'react-router-dom';
import Navigation from './navigation/Navigation';
import apollo from './apollo';
import { createBrowserHistory } from "history";
import { AuthProvider } from './contexts/AuthContext';
import './utils/Extensions/StringsExtension';
import { PLACEMENT, ToasterContainer } from 'baseui/toast';
import { BrowserRouter } from 'react-router-dom';
const history = createBrowserHistory();

export default function App() {
    const [theme, setTheme] = React.useState(THEME.light);
    React.useEffect(() => {
        let SAVED_THEME = localStorage.getItem('theme');
        if (SAVED_THEME === null) {
            SAVED_THEME = THEME.light;
        }
        setTheme(SAVED_THEME);
    }, []);

    return (
        <StyletronProvider value={styletron} debug={debug} debugAfterHydration>
            <AuthProvider>
                <ThemeSwitcherProvider value={{ theme, setTheme }}>
                    <ApolloProvider client={apollo}>
                        <BaseProvider
                            theme={
                                theme === THEME.light
                                    ? { ...LightTheme, direction: 'ltr' }
                                    :
                                    { ...DarkTheme, direction: 'ltr' }
                            }
                        >
                            <BrowserRouter>
                                <Layout>
                                    <Navigation />
                                </Layout>
                            </BrowserRouter>
                            {/*@ts-ignore*/}
                            <ToasterContainer placement={PLACEMENT.topRight} autoHideDuration={3000} overrides={{ Root: { style: { zIndex: 99999 } } }} />
                        </BaseProvider>
                    </ApolloProvider>
                </ThemeSwitcherProvider>
            </AuthProvider>
        </StyletronProvider>

    );
}
