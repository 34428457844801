import XLSX from 'xlsx';
// import { writeFile, readFile } from 'react-native-fs';

export class ExcelParser {

    async parseFile(file: File, path: string): Promise<string[][]> {
        const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
        const promise = new Promise<string[][]>((resolve, reject)=>{

            if (regex.test(path.toLowerCase())) {
                const fr = new FileReader();
                const rABS = !!fr.readAsBinaryString;
                fr.onload = function () {
                    //Both "event.target.result" and "fr.result" contain the file's contents (because "event.target" is === "fr")
                    const workbook = XLSX.read(fr.result, { type: 'binary' });
                    const firstSheetName = workbook.SheetNames[0]
                    const sheet = workbook.Sheets[firstSheetName]
                    const data = XLSX.utils.sheet_to_json(sheet, {
                        header:1,
                        blankrows:false,
                    }) as string[][];
                    resolve(data);
                }

                if(rABS) 
                    fr.readAsBinaryString(file); 
                else 
                    fr.readAsArrayBuffer(file);

            } else {
                alert("Please upload a valid Excel file.");
                reject("Please upload a valid Excel file.")
            }
        });
        return promise;
    }
}