import React from "react";
import { GqlMedia } from "../types/graphql/Media";

const initialValue = {
    description: [
        { language: 'en', value: '', },
        { language: 'fr', value: '', },
    ],
    androidUrl:'',
    iOSUrl:'',
    name:'',
    order: 0,
    webUrl: '',
    mediaId: '',
    media: {} as GqlMedia
}
export default function () {
    const [formFields, setFormFields] = React.useState(initialValue);

    const onChange = (key: keyof typeof formFields, value: any) => {

        setFormFields((prev: typeof formFields) => {
            const newVal = { ...prev, [key]: value }

            return { ...newVal }
        });
    };

    const getCreateDTO = ()=>{
        const {media, ...data} = formFields;
        data.mediaId = data.mediaId?.replaceAll("-","");
        data.order = +(data.order || 0)
        return data;
    }

    const getUpdateDto = ()=>{
        const {media, dateCreated, dateDeleted, __typename, ...data} = formFields as any;
        data.description = data.description.map(x=> {const {__typename, ...l} = x; return l;});
        data.mediaId = data.mediaId?.replaceAll("-","");
        data.order = +(data.order || 0)
        return data;
    }

    function getIsValid(){
        return formFields.mediaId != undefined && formFields.name && formFields.order >= 0 && (
            formFields.androidUrl || formFields.iOSUrl || formFields.webUrl
        );
    }

    return {
        formFields,
        getCreateDTO,
        getUpdateDto,
        onChange,
        setFormFields,
        initialValue,
        isValid: getIsValid()
    };
}