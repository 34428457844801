import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { languageString } from "../../types/graphql/PeriodicChallengeInput";

const GET_PARTICIPATIONS_WITH_COMPLAINTS = gql`
query participationsToModerate($cursor:String) {
    participationsToModerate(after:$cursor, first:20, excludeAlreadyModerated:true) {
      nodes{
        id
        title
        word_Participations{
            word{
              translations{
                value
                languageId
              }
            }
          }
          challenge{
            word_Challenges{
              word{
                translations{
                  value
                  languageId
                }
              }
            }
          }
        moderationInfos {
          complaintCount
          dateVerified
          isBanned
          verifierId
        }
        media {type}
        path
        accountId
      }
      pageInfo{
          hasNextPage
          endCursor
        }
      totalCount
    }
  }
`;

const COMPLAINT_PER_PARTICIPATION = gql`
query GetParticipationComplaints($id:ID!){
    participationComplaints(where:{participationId: $id }) {
      nodes {
        id
        otherDetails
        dateCreated
        accountId
      }
    }
  }
`

const MODERATE_PARTICIPATION = gql`
mutation moderateParticipation($id:ID!, $isValid: Boolean!, $bannedReason: BannedReason, $bannedReasonText:[LanguageStringInput]){
  moderateParticipation(participationId:$id, participationIsValid:$isValid, bannedReason: $bannedReason, bannedReasonText: $bannedReasonText){
    id
    moderationInfos {
      complaintCount
      dateVerified
      isBanned
      verifierId
    }
  }
}
`

const PARTICIPATION_COMPLAINTS_COUNT = gql`
{
  participationsToModerate(excludeAlreadyModerated:true) {
    totalCount
  }
}
`

export function useComplaintQueries() {
  const participationsToModerate = useLazyQuery<any, { cursor?: string }>(GET_PARTICIPATIONS_WITH_COMPLAINTS, { nextFetchPolicy:'cache-and-network' });
  const complaintsPerParticipation = useLazyQuery<{ participationComplaints: any }, { id: string }>(COMPLAINT_PER_PARTICIPATION, { nextFetchPolicy:'cache-and-network' });
  const moderateParticipation = useMutation<any, { id: string, isValid: boolean, bannedReason?:string, bannedReasonText?:languageString[] }>(MODERATE_PARTICIPATION);
  const moderationCountQuery = useLazyQuery<{participationsToModerate: {totalCount:number}}>(PARTICIPATION_COMPLAINTS_COUNT);

  return {
    participationsToModerate,
    complaintsPerParticipation,
    moderateParticipation,
    moderationCountQuery
  }
}