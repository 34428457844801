import React from 'react'
import PageWrapper from '../components/layout_old/PageWrapper'
// import HomePrizeSum from '../components/HomePage/HomePrizeSum'
import HomeActiveUsers from '../components/HomePage/HomeActiveUsers'


const HomeScreen = () => {
    return (
        <PageWrapper 
            scrollViewStyle={{ flex: 1, display:'flex', alignItems:'flex-start', justifyContent: 'center'}} 
            >
                <HomeActiveUsers />
            {/* <HomePrizeSum /> */}
        </PageWrapper>
    )
}

export default HomeScreen

