import { useQuery } from '@apollo/client'
import { Block } from 'baseui/block';
import gql from 'graphql-tag'
import React, { ReactElement } from 'react'
import { ImageUtils } from '../utils/ImageUtils';
import TextHighlighter from './singleComponents/TextHighlighter';
import Image from './UiElements/Image/Image';

interface Props {
    userId: string,
    searchTerm?:string
}

const USER_QUERY = gql`
query getUserName($id:ID!){
    userInfos(userId:$id){
      avatar
      username
    }
  }`

export default function Username({ searchTerm, userId }: Props): ReactElement {
    const query = useQuery(USER_QUERY, {variables:{id:userId}});

    return (
        <Block display="flex" alignItems="center">
            {query.data?.userInfos.avatar &&  <Image style={{borderRadius:'100%', border:' 1px solid', height:'30px', marginRight:'10px'}} url={ImageUtils.getResizedImageUrl(query.data?.userInfos.avatar, 50)} />}
            <TextHighlighter text={query.data?.userInfos.username} searchTerm={searchTerm ?? ''} />
        </Block>
    )
}
