import React, { PropsWithChildren, useState } from 'react';
import AdsPlannerModalContext from "./AdsPlannerModalContext";
import AdDetailsModal, { AdDetailsModalProps } from "../../components/AdsPlanner/AdDetailsModal/AdDetailsModal";
import { GqlMedia } from '../../types/graphql/Media';


export default function (props: AdDetailsModalProps) {
    const [selectedMedia, setSelectedMedia] = useState<GqlMedia | undefined>(props.event && undefined);
    const [isDirty, setIsDirty] = useState<boolean>(false)

    return (
        <AdsPlannerModalContext.Provider value={{ setSelectedMedia, selectedMedia, isDirty, setIsDirty }}>
            <AdDetailsModal {...props} />
        </AdsPlannerModalContext.Provider>
    )
}