import { Block } from 'baseui/block'
import React, { ReactElement, useState } from 'react'
import PageWrapper from '../components/layout_old/PageWrapper'
import PeriodicChallengeListingContainer from '../components/PeriodicChallenges/PeriodicChallengeContainer';
import Container from '../components/UiElements/Container/Container';
import { Plus } from 'baseui/icon';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import PeriodicChallengeModal from '../components/modals/PeriodicChallengeModal/PeriodicChallengeModal';
import PeriodicChallengeFilters from '../components/PeriodicChallenges/PeriodicChallengeFilters';
import { GqlDurationType, GqlPeriodicChallengeFrame } from '../types/graphql/PeriodicChallengeInput';


export default function PeriodicChallengesScreen(): ReactElement {
    const [css, theme] = useStyletron();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingId, setEditingId] = useState<string>();
    const [filters, setFilters] = useState({ durationTypes: [] as GqlDurationType[], frames: [] as GqlPeriodicChallengeFrame[], dates: [] as Date[] })
    const [refetchTrigger, setRefetchTrigger] = useState(Number.MIN_SAFE_INTEGER);
    function onAddClick() {
        setEditingId('');
        setIsModalOpen(true)
    }
    function onRowClick(id: string) {
        setEditingId(id);
        setIsModalOpen(true);
    }


    return (
        <PageWrapper scrollViewStyle={{ width: '100vw', flexDirection: 'column', paddingTop: '30px' }}>
            <Container>
                <Block display="flex" marginBottom="10px">
                    <Button shape="circle" $style={{ marginLeft: 'auto' }} onClick={onAddClick}>
                        <Plus size="30px" color={theme.colors.contentInversePrimary} />
                    </Button>
                </Block>
                <PeriodicChallengeFilters
                    onDurationTypeChange={durationTypes => setFilters(f => ({ ...f, durationTypes }))}
                    onFrameChange={frames => setFilters(f => ({ ...f, frames }))}
                    onPeriodChange={dates => setFilters(f => ({ ...f, dates }))}
                />

                <PeriodicChallengeListingContainer refetchTrigger={refetchTrigger} onRowClick={onRowClick} filters={filters} />
            </Container>


            {isModalOpen ? <PeriodicChallengeModal onDeleteSuccess={()=>setRefetchTrigger(refetchTrigger+1)} onSaveSuccess={()=>setRefetchTrigger(refetchTrigger+1)}  editedChallengeId={editingId} isOpen={isModalOpen} close={() => {setIsModalOpen(false);setEditingId(undefined)}} /> : <></>}
        </PageWrapper>
    )
}
