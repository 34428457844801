import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { StyledTableBodyRow, TableBuilder, TableBuilderColumn } from 'baseui/table-semantic'
import { format } from 'date-fns'
import { frCA } from 'date-fns/locale'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import Username from '../../components/Username'
import { useUsersListingQuery } from '../../hooks/queries/useUsersListingQuery'
import { useNavigate } from "react-router-dom";
import Container from '../../components/UiElements/Container/Container'
import DebouncedInput from '../../components/singleComponents/DebouncedInput'
import TextHighlighter from '../../components/singleComponents/TextHighlighter'


export default function UsersScreen(): ReactElement {
    const query = useUsersListingQuery();
    const [nodes, setNodes] = useState<any[]>([]);
    const [sortColumn, setSortColumn] = useState<string>('username');
    const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | null | undefined>(null);
    const [search, setSearch] = useState('');
    const navigate = useNavigate()

    useEffect(() => {
        query.fetch().then(x => setNodes(x.data?.accountListingItems.nodes ?? []))
    }, []);

    const fetchMore = useCallback(() => {
        if (query.data?.accountListingItems?.pageInfo?.hasNextPage) {
            const cursor = query.data!.accountListingItems!.pageInfo!.endCursor!;
            query.fetchMore(cursor, search, sortColumn, sortOrder).then(x => {

                setNodes([...nodes, ...((x.data as any)?.accountListingItems.nodes ?? [])]);
            });
        }
    }, [nodes]);

    const onColClick = useCallback((col: string) => {
        if (col != sortColumn) {
            setSortColumn(col);
            setSortOrder('ASC');
            query.refetch(true, search, col, 'ASC').then(x => {
                setNodes((x.data as any).accountListingItems.nodes);
            });
        } else {
            let newOrder;
            switch (sortOrder) {
                case "ASC": newOrder = "DESC";
                    break;
                case "DESC": newOrder = null;
                    break;
                default: newOrder = "ASC";
                    break;
            }
            setSortOrder(newOrder);
            query.refetch(true, search, col, newOrder).then(x => {
                setNodes((x.data as any).accountListingItems.nodes);
            });

        }
    }, [sortColumn, sortOrder]);

    const onSearch = useCallback((val: string) => {
        setSearch(val);
        query.refetch(true, val, sortColumn, sortOrder).then(x => {
            setNodes((x.data as any).accountListingItems.nodes);
        });
    }, [sortColumn, sortOrder]);

    return (
        <Container>
            <Block display="flex" justifyContent="space-between" alignItems="center" padding="15px">
                <DebouncedInput overrides={{ Root: { style: { maxWidth: '250px' } } }} name="search" placeholder='Recherche' onChange={e => onSearch(e)} />
                {query.data?.accountListingItems && <span style={{ fontSize: 20 }}>Total : {query.data?.accountListingItems.totalCount}</span>}

            </Block>
            <hr />
            <TableBuilder
                data={nodes ?? []}
                isLoading={query.loading}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSort={onColClick}
                overrides={{
                    TableBodyRow: {
                        component: (props: any) => {
                            return (
                                <StyledTableBodyRow {...props} style={{ cursor: 'pointer' }} onClick={() => navigate('/users/' + props.$row.id.replace(/-/g, ''))} />
                            )
                        },
                    }
                }}
            >
                <TableBuilderColumn header="Utilisateur" id='username' sortable overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => <Username searchTerm={search} userId={x.id} />}
                </TableBuilderColumn>
                <TableBuilderColumn header="Nom" sortable id="firstname" overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => <TextHighlighter text={`${x.firstname ?? ''} ${x.lastname ?? ''}`} searchTerm={search} />}
                </TableBuilderColumn>
                <TableBuilderColumn header="Courriel" sortable id="email" overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => <TextHighlighter text={x.email} searchTerm={search} />}
                </TableBuilderColumn>
                <TableBuilderColumn header="Prem." sortable id="isPremium" overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => x.isPremium ? 'Oui' : 'Non'}
                </TableBuilderColumn>
                <TableBuilderColumn header="Essai Grat." sortable id="isFreeTrial" overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => x.isFreeTrial ? 'Oui' : 'Non'}
                </TableBuilderColumn>
                <TableBuilderColumn header="Roles" sortable id="roles" overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => x.roles.join(', ')}
                </TableBuilderColumn>
                {/* <TableBuilderColumn header="Montant dû" numeric overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                        {x => x.cashPrizeUnpaid + ' $'}
                    </TableBuilderColumn> */}
                <TableBuilderColumn header="Dernier accès" sortable id="lastConnection" numeric overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => x.lastConnection ? format(new Date(x.lastConnection), 'dd MMM yyyy', { locale: frCA }) : 'N/A'}
                </TableBuilderColumn>
            </TableBuilder>


            {query.data?.accountListingItems.pageInfo!.hasNextPage && <Button onClick={() => fetchMore()}>Afficher plus</Button>}
        </Container >
    )
}
