import { gql, useMutation, useQuery } from '@apollo/client';
import { Input } from 'baseui/input'
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import { toaster } from 'baseui/toast';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { addDays, format, startOfMonth } from 'date-fns';
import React, { ReactElement, useEffect, useState } from 'react'
import { AllPeriodQueryResponse, GQLWordPeriod } from '../../../types/graphql/AllPeriods';
import Loader from '../../UiElements/Loader/Loader';


const LAST_PERIOD_QUERY = gql`
query lastPeriod { 
  allWordPeriods(where:{dateDeleted:null},order_by:{dateStart:DESC}, first:1) {
    nodes{
      id
      dateStart
      dateEnd
    }
  }
}`;

const ADD_PERIODs_MUTATION = gql`
mutation addPeriods($count: Int!, $start: DateTime!){
    addMultipleWordPeriods(numberOfPeriodToCreate:$count, start:$start){
   id
   dateStart
   dateEnd
 }
}
`;



interface Props {
    close: () => void;
    isOpen: boolean;
    onSave: (newPeriods: GQLWordPeriod[]) => void;
}

export default function AddPeriodModal({ isOpen, close, onSave }: Props): ReactElement {
    const lastPeriodQuery = useQuery<AllPeriodQueryResponse>(LAST_PERIOD_QUERY);
    const [addPeriods] = useMutation<{ addMultipleWordPeriods: GQLWordPeriod[] }, { count: number, start: Date }>(ADD_PERIODs_MUTATION);
    const [inputValue, setInputValue] = useState<number>();

    async function save() {
        try {

            const result = await addPeriods({ variables: { count: inputValue!, start: newPeriodsStartDate! as Date } });
            toaster.positive("Périodes ajoutées", { autoHideDuration: 2000 });
            onSave(result.data?.addMultipleWordPeriods || []);
            close();
        } catch (err) {
            toaster.negative("Une erreur est survenue", { autoHideDuration: 2000 });
        }
    }

    let newPeriodsStartDate = lastPeriodQuery.data?.allWordPeriods.nodes[0]?.dateStart
        && addDays(new Date(lastPeriodQuery.data?.allWordPeriods.nodes[0]?.dateStart), 10);

    if (newPeriodsStartDate) {
        const dayOfmOnth = newPeriodsStartDate.getDate();
        if (dayOfmOnth == 31)
            newPeriodsStartDate = addDays(newPeriodsStartDate, 1);
        else if (dayOfmOnth > 1 && dayOfmOnth < 11)
            newPeriodsStartDate = startOfMonth(newPeriodsStartDate)
    }

    return (
        <Modal isOpen={isOpen} closeable={true} onClose={close}>
            <ModalHeader>Ajout de périodes</ModalHeader>
            <ModalBody>
                <LabelMedium>Les nouvelles périodes commenceront le {newPeriodsStartDate ? format(newPeriodsStartDate, 'dd MMM yyyy') : <Loader />}</LabelMedium>
                <ParagraphSmall>
                    Chaque période commence un jour finissant par "1" (sauf le 31) et se termine un mois plus tard à la même date.
            </ParagraphSmall>
                <br />

                <Input type="number" onChange={e => setInputValue(e.currentTarget.valueAsNumber)} required value={inputValue} min={0} max={200} step={1} placeholder="Combien de périodes voulez-vous ajouter ?" />

                <ModalFooter>
                    <ModalButton onClick={close} kind="tertiary">
                        Annuler
                </ModalButton>
                    <ModalButton disabled={!inputValue} onClick={save}>Enregister</ModalButton>
                </ModalFooter>
            </ModalBody>
        </Modal>
    )
}
