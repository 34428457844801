import { Button } from 'baseui/button';
import React, { createRef, CSSProperties } from 'react'

interface Props {
    children: string;
    style?: CSSProperties;
    accept?:string;
    onFileSelected?:(event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileInput = (props: Props) => {
    const inputEl = createRef<HTMLInputElement>();
    const onClick = (): void => {
        if(inputEl.current){
            inputEl.current.click();
        }
    };

    return (
        <div style={props.style}>
            <input onChange={props.onFileSelected} accept={props.accept} ref={inputEl} style={{ left: -2000, position: 'absolute' }} type="file" />
            <Button onClick={onClick} >{props.children}</Button>
        </div>
    )
}

export default FileInput