import React, { CSSProperties } from 'react';
import { Img } from 'react-image';
import placeholderImg from '../../../assets/images/placeholder.jpg';
const Placeholder = ({style}:{style?:CSSProperties}) => (
  <img
    src={placeholderImg}
    alt="img loader"
    style={style}
  />
);

export default function Image({
  url,
  alt = 'placeholder',
  className,
  style,
}: {
  url: string | [string];
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <Img
      draggable={false}
      style={style}
      src={url}
      alt={alt}
      loader={<Placeholder style={style} />}
      unloader={<Placeholder style={style} />}
      className={className}
    />
  );
}
