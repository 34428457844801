import React, { ReactElement } from 'react'
import UserDetail from '../../components/UserDetail/UserDetail'


export default function UserDetailScreen(): ReactElement {

    // if loading ??

    return (
        <UserDetail/>
    )
}
