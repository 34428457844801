import { GqlWord, GqlWordGroup, GQLWordPeriod } from "../../../types/graphql/AllPeriods";
import { GQL_WordsAndPeriods_Input } from "../../../types/graphql/WordsAndPeriodsInput";
import { onWordChangeEvent } from "./WordSchedulerEventTypes";
import { WordSchedulerFlattenWord } from "./WordSchedulerFlattenWord";

export class WordSchedulerDisplayDataContainer {

    private _data: WordSchedulerDisplayData[] = [];
    public get data(): WordSchedulerDisplayData[] { return this._data }
    public set data(v: WordSchedulerDisplayData[]) { this._data = v; }

    constructor(data: GQLWordPeriod[] = []) {
        this._data = this.convertGqlToDisplay(data)
    }
    public fromDisplayData(d: WordSchedulerDisplayData[]): WordSchedulerDisplayDataContainer {
        const newContainer = new WordSchedulerDisplayDataContainer([]);
        newContainer.data = d;
        return newContainer;
    }

    public convertGqlToDisplay(data: GQLWordPeriod[]): WordSchedulerDisplayData[] {
        const sorted = this.sortData(data);
        return sorted.map(x => {
            return {
                id: x.id!,
                dateEnd: new Date(x.dateEnd!),
                dateStart: new Date(x.dateStart!),
                flattenWordGroups: new Array(4).fill(0, 0, 4).flatMap<WordSchedulerFlattenWord>((_, idx) => {
                    const groupOrder = idx
                    let existingGroup: GqlWordGroup = x.wordGroups.filter(g => g.order == groupOrder)[0];

                    if (existingGroup == null) {
                        existingGroup = {
                            word_WordGroups: [
                                { order: 0 },
                                { order: 1 },
                                { order: 2 },
                            ],
                            order: groupOrder,
                        }
                    }

                    return existingGroup.word_WordGroups.map(wwg => ({
                        wordGroupOrder: groupOrder,
                        wordOrder: wwg.order!,
                        wordGroupId: existingGroup.id,
                        word: wwg.word
                    }));

                }),
            }
        });
    }
    public convertDisplayToGql(data: WordSchedulerDisplayData[]): GQLWordPeriod[] {
        return data.map(d => {
            const item = {
                id: d.id,
                dateStart: d.dateStart.toISOString(),
                dateEnd: d.dateEnd.toISOString(),
                wordGroups: d.flattenWordGroups.reduce<GqlWordGroup[]>((prev, curr) => {
                    let existingGroup = prev.filter(x => x.order == curr.wordGroupOrder)[0];
                    if (existingGroup == null) {
                        existingGroup = {
                            id: curr.wordGroupId,
                            order: curr.wordGroupOrder,
                            word_WordGroups: []
                        };
                        prev.push(existingGroup);
                    }

                    existingGroup.word_WordGroups.push({
                        order: curr.wordOrder,
                        wordId: curr.word?.id,
                        word: curr.word,
                    });

                    return prev;
                }, [])

            };
            return item;
        })

    }

    public convertToSaveable(): GQL_WordsAndPeriods_Input.PeriodWordsInputType[]{

        return this.data.map(d => {
            const item = {
                periodId: d.id,
                wordGroups: d.flattenWordGroups.reduce<GQL_WordsAndPeriods_Input.WordGroupInputType[]>((prev, curr) => {
                    let existingGroup = prev.filter(x => x.order == curr.wordGroupOrder)[0];
                    if (existingGroup == null) {
                        existingGroup = {
                            order: curr.wordGroupOrder,
                            word_WordGroups: []
                        };
                        prev.push(existingGroup);
                    }

                    existingGroup.word_WordGroups.push({
                        order: curr.wordOrder,
                        wordId: curr.word?.id ?? '',
                    });

                    return prev;
                }, [])

            };
            return item;
        })

    }

    public editWord(infos: onWordChangeEvent, allWords: GqlWord[]): WordSchedulerDisplayData[] {
        const newData = [...this.data];
        const word = allWords.filter(x => x.id == infos.wordId)[0];
        const period = newData.filter(x => x.id === infos.periodId)[0];
        const entry = period.flattenWordGroups.filter(x => x.wordGroupOrder == infos.wordGroupOrder && x.wordOrder == infos.wordOrder)[0];
        entry.word = word;
        entry.isDirty = true;

        return newData;
    }

    private sortData(data: GQLWordPeriod[]) {
        return data.map(x => {
            return {
                ...x,
                wordGroups: [...x.wordGroups].map(wg => {
                    return {
                        ...wg,
                        word_WordGroups: [...wg.word_WordGroups].sort((a, b) => a.order! - b.order!)
                    }
                }).sort((a, b) => a.order! - b.order!),
            }
        });
    }

}

export type WordSchedulerDisplayData = {
    id: string;
    dateStart: Date;
    dateEnd: Date;
    flattenWordGroups: WordSchedulerFlattenWord[];
}