import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Grid, Cell } from 'baseui/layout-grid';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, SIZE } from 'baseui/modal'
import { Table } from 'baseui/table-semantic';
import { format } from 'date-fns';
import { frCA } from 'date-fns/locale';
import { IoIosCheckmark, IoIosClose } from 'react-icons/io';
import { ImageUtils } from '../../../utils/ImageUtils';
import { useComplaintQueries } from '../../Complaints/useComplaintQueries'
import Image from '../../UiElements/Image/Image';
import Username from '../../Username';
import { Toast, toaster } from 'baseui/toast';
import { GqlChallengeType, languageString } from '../../../types/graphql/PeriodicChallengeInput';
import { LabelMedium } from 'baseui/typography';
import BannishingReasonChoices from './BannishingReasonChoices';

interface Props {
    isOpen: boolean,
    participation?: any,
    imagePath?: string,
    close: (refetch: boolean) => void
}

export default function ComplaintDetailsModal({ isOpen, close, participation }: Props): ReactElement {
    const { complaintsPerParticipation, moderateParticipation } = useComplaintQueries();
    const [showReasonModal, setShowReasonModal] = useState(false);
    const bannishReason = useRef({ reason: '', reasonText: [] as languageString[] });

    useEffect(() => {
        if (participation)
            complaintsPerParticipation[1].refetch({ id: participation.id }).then(x => {
                console.log(x);
            })
    }, [participation]);

    function onReasonChange(reason: string, reasonText: languageString[] = []) {
        bannishReason.current = { reason, reasonText };
    }

    function onReasonClose(bannish: boolean) {
        setShowReasonModal(false);
        if (bannish)
            moderate(false)
    }
    async function moderate(isValid: boolean) {
        try {
            const result = await moderateParticipation[0]({
                variables: {
                    id: participation.id,
                    isValid,
                    bannedReason: isValid ? undefined : bannishReason.current.reason,
                    bannedReasonText: isValid ? undefined : bannishReason.current.reasonText
                }
            });
            if (result.errors?.length) {
                toaster.negative(result.errors.map(x => x.message), {});
            } else {
                toaster.positive(`Participation ${isValid ? 'acceptée' : 'bannie'} avec succès`, {});
                close(true);
            }
        }
        catch (err: any) {
            toaster.negative(err.message, {})
        }
    }


    const path = participation ? participation?.media.type === GqlChallengeType.VIDEO ? ImageUtils.makeUrl(participation.path)
        : ImageUtils.getResizedImageUrl(participation.path, 500) : '';
    const words = getWords(participation);
    return (
        /*@ts-ignore*/
        <Modal key={participation?.id} isOpen={isOpen} closeable onClose={_ => close(false)} size={SIZE.auto}>
            <BannishingReasonChoices close={onReasonClose} isVisible={showReasonModal} onValueChange={onReasonChange} isComment={false} />
            <ModalHeader>Détail des signalements</ModalHeader>
            <ModalBody>
                <Grid gridColumns={12} gridGutters={5} gridMargins={0}>
                    <Cell span={[12, 4, 4]}>
                        <LabelMedium> Média </LabelMedium>
                        <span style={{ margin: '0 auto' }}>{ }</span>
                        {participation?.media.type === GqlChallengeType.VIDEO ?
                            <video src={ImageUtils.makeUrl(participation.path)} autoPlay muted controls style={{ boxShadow: '0 0 8px #bbb', width: '100%' }} />
                            :
                            <Image url={path || ''} style={{ boxShadow: '0 0 8px #bbb', width: '100%' }} />
                        }
                        {words && "Mots: " + words}
                        <div>Utilisateur: <Username userId={participation?.accountId} /></div>
                    </Cell>
                    <Cell span={[12, 8, 8]}>
                        <LabelMedium> Signalement(s)</LabelMedium>

                        {/*@ts-ignore*/}
                        <Table
                            isLoading={complaintsPerParticipation[1].loading}
                            overrides={{ TableBodyCell: { style: { minWidth: '130px', overflow: 'auto' } } }}
                            columns={["Date", "Utilisateur", "Autre détail"]}
                            data={complaintsPerParticipation[1].data?.participationComplaints.nodes.map(x => [
                                format(new Date(x.dateCreated), 'dd MMM yyyy', { locale: frCA }),
                                <Username userId={x.accountId} />,
                                x.otherDetails
                            ])}
                        ></Table>
                    </Cell>
                </Grid>
            </ModalBody>
            <ModalFooter>                
                <ModalButton isLoading={moderateParticipation[1].loading} disabled={moderateParticipation[1].loading} onClick={_ => moderate(true)}>La participation est valide <IoIosCheckmark color="#66ff66" style={{ margin: -10, marginLeft: 5 }} size={40} /> </ModalButton>
                <ModalButton isLoading={moderateParticipation[1].loading} disabled={moderateParticipation[1].loading} onClick={_ => setShowReasonModal(true)}>Bannir la participation <IoIosClose color="#f33" style={{ margin: -10 }} size={40} /></ModalButton>
            </ModalFooter>
        </Modal>
    )
}


function getWords(participation: any) {
    function mapWords(x: any) {
        return x.word.translations.filter(t => t.languageId === 'fr')[0].value
    }

    const result = participation?.word_Participations.length > 0 ?
        participation.word_Participations.map(mapWords).join(' - ')
        : participation?.challenge.word_Challenges?.map(mapWords).join(' - ') ?? '';

    console.log(result)
    return result;

}