import React, { useRef, useState } from 'react'
import Container from '../components/UiElements/Container/Container'
import { DataGrid } from '@mui/x-data-grid';
import useFeaturedAppsQuery, { useFeaturedAppsMutations } from '../hooks/queries/useFeaturedAppsQuery';
import { IconButton, Typography } from '@mui/material';
import { FaPlusCircle, FaTrash } from 'react-icons/fa';
import FeaturedAppModal from '../components/FeaturedApps/FeaturedAppModal';
import MultipleLanguageTextArea from '../components/MultipleLanguageTextArea/MultipleLanguageTextArea';
import Edit from '@mui/icons-material/Edit';
import { FeaturedApps } from '../types/graphql/FeaturedApp';
import DeletePrompt from '../components/modals/Dialogs/DeletePrompt';

export default function FeaturedAppsScreen() {
    const query = useFeaturedAppsQuery()
    const [modalOpen, setModalOpen] = useState(false);
    const [toDeleteId, setToDeleteId] = useState<string>();
    const editingRow = useRef<FeaturedApps>()
    const { performDelete, deleteMutation } = useFeaturedAppsMutations();

    const openEdit = (id: string) => {
        editingRow.current = query.data?.featuredApps.filter(x => x.id == id)[0];
        setModalOpen(true);
    }

    const onDeleteClick = async () => {

        await performDelete({ variables: { id: toDeleteId! } })
            .then(x => { setToDeleteId(undefined) });
    }

    return (
        <Container>
            <Typography>
                Ajouter
                <IconButton onClick={() => setModalOpen(true)}>
                    <FaPlusCircle />
                </IconButton>
            </Typography>
            <DataGrid
                rows={(query.data?.featuredApps ?? []).filter(x => x.dateDeleted == null)}
                paginationMode="client"
                pageSize={20}
                rowHeight={100}
                columns={[
                    { flex: 1, headerName: 'Image', field: 'mediaId', renderCell: (e) => <img style={{ width: '80px' }} src={e.row.media.path} /> },
                    { flex: 1, field: 'name', headerName: 'Nom' },
                    { flex: 1, field: 'order', headerName: 'Position' },
                    // { flex: 1, field: 'dateCreated', headerName: 'Date d\'ajout' },
                    // { flex: 1, field: 'dateDeleted', headerName: 'Date de sup.' },
                    { flex: 1, field: 'description', headerName: 'Description', renderCell: (e) => <MultipleLanguageTextArea onChange={() => {return }} value={e.row.description} languages={e.row.description.map(x => x.language)} /> },
                    {
                        flex: 1, field: 'webUrl', headerName: 'Liens', renderCell: (e) => {
                            return (
                                <>
                                    ios: {e.row.iOSUrl ?? '-'}<br />
                                    android: {e.row.androidUrl ?? '-'}<br />
                                    web: {e.row.webUrl ?? '-'}<br />
                                </>
                            )
                        }
                    },
                    {
                        headerName: 'Actions',
                        field: '',
                        disableColumnMenu: true,
                        disableReorder: true,
                        disableExport: true,
                        renderCell: (e) => {
                            return <>
                                <IconButton onClick={() => openEdit(e.row.id)}><Edit fontSize="small" /></IconButton>
                                <IconButton onClick={() => setToDeleteId(e.row.id)}><FaTrash fontSize={16} /></IconButton>
                            </>
                        }
                    }
                ]}
                autoHeight
            />
            {!query.loading && !query.data?.featuredApps.length &&
                <Typography textAlign="center">Aucun item </Typography>
            }
            <FeaturedAppModal
                show={modalOpen}
                close={() => { setModalOpen(false) }}
                onSaved={() => { 
                    if(editingRow.current){
                        editingRow.current = undefined;
                    } else {
                        query.refetch();
                    }
                 }}
                initialState={editingRow.current}

            />

            <DeletePrompt
                isOpen={!!toDeleteId}
                loading={deleteMutation.loading}
                close={() => setToDeleteId(undefined)}
                onConfirm={() => onDeleteClick()}
            />
        </Container>
    )
}