import { OnChangeParams, Select, SIZE, StyledDropdownListItem } from 'baseui/select'

import React, { createRef, forwardRef, PropsWithChildren, ReactElement } from 'react'
import { OptionList } from 'baseui/menu';
import { List } from 'react-virtualized';

interface Props {
    loading?: boolean;
    allwords?: { id: string, fr: string, en: string }[];
    value?: { id: string, fr: string, en: string };
    lang: 'fr' | 'en';
    dirty?: boolean,
    disabled?: boolean,
    showError?: boolean,
    usedIds?: string[],
    onValueChange?: (wordId: string | undefined) => void
}

export default function WordSelector(props: Props): ReactElement {
    const selectedValue = props.value ? [{ ...props.value, label: props.value![props.lang] }] : undefined;
    const style = props.dirty ? ({ $theme }) => ({ outline: $theme.colors.warning200 + ' solid' }) : undefined;

    function onValueChange(e: OnChangeParams) {
        const word = e.value[0]
        if (props.onValueChange)
            props.onValueChange(word?.id as string | undefined);
    }
    return (
        <Select
            size={SIZE.mini}
            options={props.allwords}
            placeholder={selectedValue ? '' : "Mot"}
            isLoading={props.loading}
            maxDropdownHeight="200px"
            onChange={onValueChange}
            value={selectedValue}
            valueKey="id"
            filterOptions={props.usedIds?.length ? (option, filterValue) => {
                let filtered = [...(option ?? [])];
                if (filterValue)
                    filtered = filtered.filter(x => (x[props.lang] as string).diacriticLess().toLowerCase().search(filterValue.diacriticLess().toLowerCase()) > -1);
                if (props.usedIds?.length)
                    filtered = filtered.filter(w => !props.usedIds!.some(x => x == w.id)) ?? [];

                return filtered;
            } : undefined
            }
            labelKey={props.lang}
            disabled={props.disabled}
            error={props.showError}
            overrides={{
                Dropdown: VirtualList, ControlContainer: { style }
            }}
        />
    )
}

const VirtualList = forwardRef((props: PropsWithChildren<any>, ref: any) => {
    return (
        <div ref={props.$ref ?? ref}>
            {/* @ts-ignore */}
            <List
                role={props.role}
                height={Number.parseInt(props.$maxHeight) || 500}
                rowCount={props.children?.length || 0}
                rowHeight={36}
                style={{ backgroundColor: 'rgb(238, 238, 238)' }}
                rowRenderer={({ index, key, style }) => (
                    <OptionList
                        key={key}
                        style={style}
                        {...props.children[index].props}
                        overrides={{
                            ListItem: {
                                style: {
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                            },
                        }}
                    />
                )}

                width={150}
            />
        </div>
    );
});