import React, { ReactElement } from 'react'
import ParticipationComplaintsContainer from '../components/Complaints/ParticipationComplaintsContainer'
import PageWrapper from '../components/layout_old/PageWrapper'
import Container from '../components/UiElements/Container/Container'
import { Tabs, Tab } from "baseui/tabs-motion";
import CommentComplaintsContainer from '../components/Complaints/CommentComplaintsContainer';


export default function ModerationScreen(): ReactElement {
    const [activeKey, setActiveKey] = React.useState<React.Key>("0");

    return (
        <PageWrapper scrollViewStyle={{ width: '100vw', flexDirection: 'column', paddingTop: '30px' }}>
            <Container>
            <Tabs
                activeKey={activeKey}
                renderAll
                onChange={({ activeKey }) => {
                    setActiveKey(activeKey);
                }}>
                <Tab title="Participations" >
                    <ParticipationComplaintsContainer />
                </Tab>
                <Tab title="Commentaires">
                    <CommentComplaintsContainer />
                </Tab>
            </Tabs>
            </Container>
        </PageWrapper>
    )
}
