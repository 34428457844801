import React, {ReactElement, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {UseUserParticipationQuery} from "../../hooks/queries/useUserParticipation";
import {Card, StyledBody} from "baseui/card";
import {Block} from "baseui/block";
import {
    TableBuilder,
    TableBuilderColumn
} from "baseui/table-semantic";
import {Button} from "baseui/button";
import Image from "../UiElements/Image/Image";
import {GqlChallengeType} from "../../types/graphql/PeriodicChallengeInput";
import {ImageUtils} from "../../utils/ImageUtils";
import UserParticipationDetails  from "./UserParticipationDetails";


export default function UserParticipation() : ReactElement {
    const params = useParams<{id}>();
    const query = UseUserParticipationQuery(params.id);
    const queryResult = query.data?.allParticipations;
    const [participation, setParticipation] = useState<Participation[]>([]);

    useEffect(()=>{
        setParticipation([]);
    }, [params.id])

    useEffect(() => {
        if (query.data?.allParticipations.nodes.length) {
            setParticipation((state) => {
                return [...state, ...query.data!.allParticipations.nodes];
            })
        }
    }, [queryResult?.pageInfo.endCursor]);

    const fetchMore = () => {
        if (queryResult?.pageInfo.hasNextPage)
            query.fetchMore({ variables: { id: params.id, cursor: queryResult?.pageInfo.endCursor } })
    }
    return (
        <Block>
            <Card>
                <StyledBody>
                    <TableBuilder
                        data={participation}
                        emptyMessage="Aucune participation"
                        isLoading={query.loading}>

                        <TableBuilderColumn header='Photo/Vidéo' overrides={{TableBodyCell:{style:{maxWidth:'120px'}}}}>
                            {row => row?.media.type === GqlChallengeType.VIDEO ?
                                <video src={ImageUtils.makeUrl(row.media.path)} controls style={{ boxShadow: '0 0 8px #bbb', width: '100%' }} />
                                :
                                <Image url={row.media.path} style={{ boxShadow: '0 0 8px #bbb', width: '100%' }} />
                            }
                        </TableBuilderColumn>
                        <TableBuilderColumn header='Informations'>
                            {row => <UserParticipationDetails {...row}/>}
                        </TableBuilderColumn>
                    </TableBuilder>
                </StyledBody>

                { query.data?.allParticipations.pageInfo.hasNextPage && <Button onClick={() => fetchMore()}>Afficher plus</Button>}
            </Card>
        </Block>
    )
}
