import React, {ReactElement, useEffect, useState} from "react";
import Container from "../UiElements/Container/Container";
import {Block} from "baseui/block";
import {Card, StyledBody} from "baseui/card";
import {FlexGrid, FlexGridItem} from "baseui/flex-grid";
import DisabledInput from "../Inputs/DisabledInput";
import {FormControl} from "baseui/form-control";
import {Button} from "baseui/button";
import {useParams} from "react-router-dom";
import {useUserQuery} from "../../hooks/queries/useUserQuery";
import {Account, AccountRoles} from "../../models/Dto/UserInfos";
import {Option, Select, Value} from "baseui/select";

export default function UserInfos() : ReactElement {
    const [value, setValue] = useState<Option[]>();
    const params = useParams<{id}>();
    const query = useUserQuery(params.id);
    const user: Account | undefined = query.data?.allAccounts.nodes[0]
    useEffect(() => {
        let value: Option[] = []
        if (user) {
            if (Array.isArray(user?.roles)) {
                value = user.roles.map((role) => {
                    if (role === "ADMIN") {
                        return {label: "Admin", id: 0};
                    } else {
                        return {label: "Mobile", id: 1};
                    }
                }) as Option[]
            }
            if (typeof user!.roles === "string") {
                if (user!.roles === "ADMIN") {
                    value = [{label: "Admin", id: 0}];
                } else {
                    value = [{label: "Mobile", id: 1}];
                }
            }
        }
        setValue(value)
    }, [user?.id])
    return (
            <Block display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Card>
                    <form action='/' method="POST">
                        <StyledBody>
                            <FlexGrid padding={'20px'} flexDirection={"row"} flexGridColumnCount={2}
                                      flexGridColumnGap="scale800" flexGridRowGap="scale800">
                                <FlexGridItem>
                                    <DisabledInput label="Nom d'utilisateur" name="username" value={user?.username} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <FormControl label="Rôle">
                                        <Select
                                            required
                                            disabled
                                            value={value}
                                            onChange={nextValue => setValue(nextValue.value as Value[])}
                                            multi
                                            labelKey="label"
                                            valueKey="value"
                                            options={[
                                                {label: "Admin", value: 0},
                                                {label: "Mobile", value: 1}
                                            ]}
                                        />
                                    </FormControl>
                                </FlexGridItem>
                                <FlexGridItem>
                                    <DisabledInput label={"Prénom"} name={"firstname"} value={user?.firstname} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <DisabledInput label={"Nom"} name={"lastname"} value={user?.lastname} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <DisabledInput label={"Courriel"} name={"email"} value={user?.email} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <DisabledInput label={"Crédits"} name={"credits"} value={user?.credits} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <DisabledInput label={"Points"} name={"totalPoints"} value={user?.totalPoints} />
                                </FlexGridItem>
                                <FlexGridItem />
                                <FlexGridItem />
                                <FlexGridItem display={'flex'} justifyContent={'end'}>
                                    <Button disabled type="submit" $style={{margin: '10px'}}>Confirmer</Button>
                                    <Button $style={{background: 'red', margin: '10px'}}>Bannir l'utilisateur</Button>
                                </FlexGridItem>
                            </FlexGrid>
                        </StyledBody>
                    </form>
                </Card>
            </Block>
    )
}