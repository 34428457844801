import { Block } from 'baseui/block'
import React, { ReactElement } from 'react'
import { PeriodicChallengeTableHeaderCell } from './PeriodicChallengeTableHeaderCell'

interface Props {
    headers: string[]
}

export default function PeriodicChallengeTableHeader({headers}: Props): ReactElement {
    return (
        <Block display={['none','none',"flex"]} marginBottom="20px" position="sticky" top={0} flexDirection="row">
            {headers.map((h,i)=>{
                return <PeriodicChallengeTableHeaderCell $style={{maxWidth:i === 0 ? '50px' : undefined }} key={'header-' + i} >{h}</PeriodicChallengeTableHeaderCell>
            })}
    </Block>
    )
}
