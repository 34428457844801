import { FlexGridItem } from 'baseui/flex-grid'
import { FormControl } from 'baseui/form-control'
import { Radio, RadioGroup } from 'baseui/radio'
import React, { ReactElement } from 'react'
import { GqlMedia } from '../../../types/graphql/Media'
import { GqlChallengeType } from '../../../types/graphql/PeriodicChallengeInput'
import ImageUploader from '../../ImageUploader/ImageUploader'

interface Props {
    mediaType: GqlChallengeType,
    media?:GqlMedia,
    onChange: (key: 'mediaType' | 'mediaId', value: GqlChallengeType | string | undefined)=>void,
    destinationDirectory: string
}

export default function ChallengeImageBasedFrame(props: Props): ReactElement {

    return (
        <FlexGridItem minWidth="300px" flex={1}>
            <FormControl label="Type de média du challenge">
                <RadioGroup required value={props.mediaType} onChange={e => props.onChange('mediaType', e.target.value as GqlChallengeType)}>
                    <Radio value={GqlChallengeType.PHOTO}>Photo</Radio>
                    <Radio value={GqlChallengeType.VIDEO}>Vidéo</Radio>
                    <Radio value={GqlChallengeType.UNDEFINED}>Les deux</Radio>
                </RadioGroup>
            </FormControl>

            <ImageUploader
                destinationDirectory={props.destinationDirectory}
                existingMedia={props.media}
                onUploaded={e => props.onChange('mediaId', e)}
                onFileDeleted={() => props.onChange('mediaId',undefined)}
            />
        </FlexGridItem>
    )
}
