import { useQuery } from '@apollo/client';
import gql from 'graphql-tag'
import React from 'react'


const QUERY = gql`
    query GetMembershipSubscriptions($id: ID!){
      membershipSubscriptions(where:{accountId: $id} order_by: {dateStart: DESC}) {
        id
        accountId
        dateCanceled
        dateRevokedOrRefund
        dateStart
        dateEnd
        inAppPurchaseProductId
        dateCreated
      }
    }
`;

interface Subscription {
    id
    accountId
    dateCanceled
    dateRevokedOrRefund
    dateStart
    dateEnd
    inAppPurchaseProductId
    dateCreated
}

interface UseUserSubscriptionQuery {
    membershipSubscriptions: Subscription[]
}

export function UseUserSubscriptionQuery(userId: string) {
    return useQuery<UseUserSubscriptionQuery>(QUERY, {variables: {id: userId}});
}