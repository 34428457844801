import { Block } from 'baseui/block'
import { Table, TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { ImageUtils } from '../../utils/ImageUtils';
import Image from '../UiElements/Image/Image';
import { useComplaintQueries } from './useComplaintQueries';
import { Button } from 'baseui/button';
import { IoIosMore } from 'react-icons/io';
import ComplaintDetailsModal from '../modals/Complaints/ComplaintDetailsModal';
import { GqlChallengeType } from '../../types/graphql/PeriodicChallengeInput';


export default function ParticipationComplaintsContainer(): ReactElement {
    const { participationsToModerate } = useComplaintQueries();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState<Participation[]>([])
    const [hasNextPage, setHasNextPage] = useState(false);
    const clickedParticipation = useRef<any>();

    useEffect(() => {
        participationsToModerate[1].fetchMore({ variables: { cursor: undefined } }).then(x => {
            setData(x.data?.participationsToModerate.nodes ?? []);
            setHasNextPage(x.data?.participationsToModerate.pageInfo.hasNextPage == true)
        });
    }, [])

    const fetchMore = () => {
        const queryResult = participationsToModerate[1].data.participationsToModerate;
        if (hasNextPage)
            participationsToModerate[1].fetchMore({ variables: { cursor: queryResult?.pageInfo.endCursor } })
    }

    function openModal(participation: any) {
        clickedParticipation.current = participation;
        setIsModalOpen(true);
    }

    function closeModal(refetch: boolean) {
        setIsModalOpen(false);
        if (refetch)
            participationsToModerate[1].refetch({ cursor: undefined })
    }

    return (
        <Block >
            {/*@ts-ignore*/}
            <TableBuilder
                isLoading={participationsToModerate[1].loading}
                emptyMessage="Il n'y a rien ici!"
                data={data}
            >
                {/*@ts-ignore*/}
                <TableBuilderColumn header="Image/Vidéo">
                    {x => {
                        const path = x.media.type === GqlChallengeType.VIDEO ? ImageUtils.makeUrl(x.path.replace(".mp4", "_thumb.jpg").replace('.mov', '_thumb.jpg'))
                            : ImageUtils.getResizedImageUrl(x.path, 50);


                        return (
                            <div>
                                <div>{x.media.type}</div>
                                <Image url={path ?? ''} alt={x.title} style={{ width: 50, height: 50 }} />
                            </div>
                        )
                    }}
                </TableBuilderColumn>
                {/*@ts-ignore*/}
                <TableBuilderColumn header="Signalements" overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => x.moderationInfos.complaintCount}
                </TableBuilderColumn>
                {/*@ts-ignore*/}
                <TableBuilderColumn header="" overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => <IoIosMore size={30} onClick={() => openModal(x)} cursor="pointer" />}
                </TableBuilderColumn>
            </TableBuilder>

            {hasNextPage && <Button onClick={fetchMore} >Charger plus de résultats</Button>}

            <ComplaintDetailsModal isOpen={isModalOpen} participation={clickedParticipation.current} close={closeModal} />
        </Block>
    )
}
