import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import React, { useEffect, useState } from 'react'
import Playoff, { PlayoffChallenge } from '../../../models/Dto/Playoff';
import { FormControl } from "baseui/form-control";
import { usePlayoffChallengeFormFields } from '../../../hooks/usePlayoffChallengeFormFields';
import { GqlPeriodicChallengeFrame } from '../../../types/graphql/PeriodicChallengeInput';
import { Button } from 'baseui/button';
import PlayoffChallengeDatesForm from './PlayoffChallengeDatesForm';
import { Step, StepButton, StepContent, Stepper, Switch } from '@mui/material';
import ChallengeImageBasedFrame from '../../modals/PeriodicChallengeModal/ChallengeImageBasedFrame';
import { Block } from 'baseui/block';
import { Input } from 'baseui/input';
import PeriodicChallengeWordBasedFrame from '../../modals/PeriodicChallengeModal/PeriodicChallengeWordBasedFrame';
import usePlayoffQueries from '../../../hooks/queries/usePlayoffQueries';
import { toaster } from 'baseui/toast';
import MultipleLanguageTextArea from '../../MultipleLanguageTextArea/MultipleLanguageTextArea';

type Props = {
    show: boolean;
    challenge?: PlayoffChallenge;
    close: () => void;
    playoff: Playoff;
}

export default function PlayoffChallengeModal({ show, challenge, close, playoff }: Props) {
    const { formFields, onChange, setFormFields, setValueFromChallenge, initialValue, getApiModel, getErrors } = usePlayoffChallengeFormFields();
    const [currentStep, setStep] = useState(0);
    const { saveChallenge, challengeMutation, getPlayoffPerId } = usePlayoffQueries();

    useEffect(() => {
        if (show) {
            if (challenge) {
                setValueFromChallenge(challenge)
            }
            else
                setFormFields(initialValue);
        }
    }, [show, challenge])

    function cancel() {
        close();
    }

    async function handleNextBtn() {
        if (currentStep < 3) {
            setStep(s => s + 1);
        } else {
            const errors = getErrors();

            if (Object.keys(errors).length) {

                toaster.negative(
                    <>
                        <strong>Veuillez corriger ces erreurs</strong>
                        <ul>
                            {Object.keys(errors).map(key => errors[key].map((x, i) => <li key={`${key}-${i}`}>{x}</li>))}
                        </ul>
                    </>
                    , {})

            } else {
                await saveChallenge({
                    variables: {
                        challenge: getApiModel(challenge?.id, playoff!.id),
                        id: challenge?.id
                    }
                })
                    .then(async x => {

                        toaster.positive(<>Challenge enregistré</>, {});
                        try {
                            const result = await getPlayoffPerId({ variables: { id: playoff.id } });
                            if(result.data){
                                console.log('should update playoff');
                            }
                        } catch(err) { 
                            console.error(err)
                        }

                        close();
                    })
                    .catch(err => {
                        toaster.negative(err.message, {})
                    })
            }
        }
    }

    return !show ? <></> : (
        <Modal isOpen onClose={cancel}>
            <ModalHeader>{challenge ? 'Modififier' : 'Nouveau'} challenge Playoff</ModalHeader>
            <ModalBody>
                <Block alignItems={'flex-start'}>
                    <Stepper activeStep={currentStep} orientation='vertical'>
                        <Step completed={false} disabled={false}>
                            <StepButton onClick={() => setStep(0)}>Round</StepButton>
                            <StepContent>
                                <FormControl error={getErrors().round}>
                                    <Input type="number" required min={1} max={4} value={formFields.round} onChange={(e) => onChange('round', Number.parseInt(e.currentTarget.value ?? "1") ?? 1)} />
                                </FormControl>

                                <FormControl label="Ultimate">
                                    <Switch
                                        checked={formFields.isUltimate}
                                        onChange={(e) => onChange('isUltimate', e.currentTarget.checked)}
                                    />
                                </FormControl>
                            </StepContent>
                        </Step>
                        <Step completed={false} disabled={false}>
                            <StepButton onClick={() => setStep(1)}>Dates et frame</StepButton>

                            <StepContent>
                                <FormControl>
                                    <PlayoffChallengeDatesForm
                                        playoff={playoff}
                                        formFields={formFields}
                                        onChange={onChange}
                                        errors={getErrors()}
                                    />
                                </FormControl>
                            </StepContent>
                        </Step>

                        <Step completed={false} disabled={false}>
                            <StepButton onClick={() => setStep(2)}>Instructions et citation</StepButton>

                            <StepContent>
                                <FormControl label="Instructions" error={getErrors().instructions}>
                                    <MultipleLanguageTextArea
                                        languages={['fr', 'en']}
                                        value={formFields.instructions}
                                        onChange={val => onChange('instructions', val)}
                                        name="instructions"
                                    />
                                </FormControl>
                                <FormControl label="Citation" error={getErrors().quote}>
                                    <MultipleLanguageTextArea
                                        languages={['fr', 'en']}
                                        value={formFields.quote}
                                        onChange={val => onChange('quote', val)}
                                        required
                                        name="quote"
                                    />
                                </FormControl>
                            </StepContent>
                        </Step>

                        <Step completed={false} disabled={false} last >
                            <StepButton onClick={() => setStep(3)}>Contenu</StepButton>
                            <StepContent TransitionProps={{ unmountOnExit: false }} >
                                {
                                    formFields.frame == GqlPeriodicChallengeFrame.Image &&
                                    <ChallengeImageBasedFrame
                                        destinationDirectory='playoff-challenge'
                                        mediaType={formFields.mediaType}
                                        media={challenge?.media}
                                        onChange={onChange}
                                    />
                                }
                                {
                                    formFields.frame == GqlPeriodicChallengeFrame.Words &&
                                    <PeriodicChallengeWordBasedFrame
                                        mediaType={formFields.mediaType}
                                        words={formFields.words}
                                        wordCount={formFields.wordCount}
                                        onChange={onChange}
                                    />
                                }
                            </StepContent>

                        </Step>
                    </Stepper>
                </Block>

            </ModalBody>

            <ModalFooter>
                <Button kind='tertiary' onClick={() => currentStep == 0 ? null : setStep(s => s - 1)}>Précédent</Button>
                <Button disabled={challengeMutation.loading} onClick={handleNextBtn}>
                    {
                        currentStep < 3 ?
                            'Suivant'
                            :
                            'Enregistrer'
                    }
                </Button>
            </ModalFooter>
        </Modal>
    )
}