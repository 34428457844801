import * as React from 'react';
import { apiServiceInstance, authDataStorageKey } from '../services/ApiService';
import { LoginResponse } from '../models/Dto/LoginResponse';
import { ServerResponse } from '../models/Dto/ServerResponse';
import { RegisterDTO } from '../models/Dto/RegisterDTO';
import { EnvConstants } from '../constants/EnvConstants';
import { UserRoles } from '../models/Dto/UserInfos';

enum authProvider { Facebook = 0, Google = 1, Apple = 2, Cognito = 3 }

export interface LogInData {
    email?: string;
    token: string;
    provider: authProvider;
    password?: string;
    providerUserId: string;
}

export class AuthenticationContext {
    private authData: any/* AuthenticationData */ | null = null;
    private initiated = false;

    constructor() {
        const x = localStorage.getItem(authDataStorageKey);
        if (x)
            this.authData = JSON.parse(x) //as AuthenticationData;

        this.initiated = true;

    }

    private removeToken() {

        localStorage.removeItem("authData");
        this.authData = null;
    }

    private storeAuthData(authData: any/* AuthenticationData*/) {
        this.authData = authData;
        apiServiceInstance.updateJWT(authData.token);
        return localStorage.setItem(authDataStorageKey, JSON.stringify(authData));
    }

    public async isSignedIn() {
        while (this.initiated === false) {
            await wait(20);
        }
        let signed = false;

        if (!this.authData)
            return false;


        try {
            const res = await apiServiceInstance.get<boolean>('auth/testUser');

            signed = res === true;
        } catch (e) {
            signed = false;
        }

        return signed;
    }

    public async onFacebookSignIn(response: any): Promise<{ res: LoginResponse, req: LogInData } | null> {
        console.log('facebook', response)
        if (response && response.type === 'success') {
            //Then log in to server
            try {
                const res = this.serverSignIn({
                    email: response.email,
                    provider: authProvider.Facebook,
                    token: response.token,
                    providerUserId: 'undefined'
                });
                return res;
            } catch {
                return null
            }
        }

        return null;
    }

    public async onGoogleSignIn(response: any): Promise<{ res: LoginResponse, req: LogInData } | null> {

        if (response.type === 'success') {
            return this.serverSignIn({
                provider: authProvider.Google,
                token: response.idToken!,
                providerUserId: response.user.id ?? '',
                email: response.user.email ?? ''
            })
        }
        return null;
    }

    public async onAppleSignIn(cred: any): Promise<{ res: LoginResponse, req: LogInData } | null> {
        if (!cred.email) {
            cred.email = "a@example.com"
        }
        return this.serverSignIn({
            token: cred.identityToken!,
            provider: authProvider.Apple,
            email: cred.email || '',
            providerUserId: cred.user
        })

    }

    // public async signInWithEmail(infos: { email: string, password: string, rememberMe: boolean }): Promise<boolean> {

    //     return await this.serverSignIn({
    //         provider: authProvider.Cognito,
    //         email: infos.email,
    //         password: infos.password,
    //         token: ''
    //     })
    // }

    private async serverSignIn(infos: LogInData): Promise<{ res: LoginResponse, req: LogInData } | null> {
        try {
            const res: LoginResponse = await apiServiceInstance.post<LoginResponse>('auth', infos)
            if (EnvConstants.getEnvConst().envName != "PRODUCTION") {
                console.log(res)
            }

            if (res?.success) {
                const roles = res.userInfos.userInfos.roles;
                const isAdmin = Array.isArray(roles) && roles.some(x=> x === UserRoles.admin) || roles == 'admin' || roles === UserRoles.admin;
                // if(!isAdmin){
                //     alert("You are not authorized. Only PVRC admins are allowed to access this app.")
                //     return null;
                // }
                    
                // if(isAdmin)
                    this.storeAuthData(res.userInfos);
                    
                return { res: res, req: infos };
            }
        } catch (e) {
            alert("Only admins are allowed to access this app")
        }
        return null;
    }

    public signOut(): boolean {
            try {
                localStorage.removeItem('authData');
                this.authData = null;
                apiServiceInstance.updateJWT('');
                return true;
            } catch {
                return false;
            }
    }

    public getUserInfos() {
        return this.authData && { ...this.authData.userInfos } || null;
    }

    public async validateUsername(username: string) {
        try {

            const res = await apiServiceInstance.post<ServerResponse>('auth/validateUserName', username);
            console.log(res)
            return res.success === true;

        } catch (err) {
            console.log(err);
            return false;
        }
    }

    public async createAccount(infos: RegisterDTO) {
        try {
            if (infos.provider == authProvider.Facebook)
                infos.providerUserId = 'undefined';

            const res = await apiServiceInstance.post<LoginResponse>('auth/register', infos);

            if (res.success && res.userInfos) {
                this.storeAuthData(res.userInfos);
            }

            return res;
        } catch (err) {
            console.log(err);
            return null;
        }
    }
}

export async function wait(ms: number) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}


const authContextTools = new AuthenticationContext()
export const AuthContext = React.createContext<[AuthenticationContext | null, boolean | undefined, React.Dispatch<React.SetStateAction<boolean | undefined>>]>([null, false, (val) => {return; }]);

export const AuthProvider = (props: { children?: any }) => {

    const [isSignedIn, setIsSignedIn] = React.useState<boolean>();
    (async () => {
        const signed = await authContextTools.isSignedIn()
        setIsSignedIn(signed);
    })();

    return (
        <AuthContext.Provider value={[authContextTools, isSignedIn, setIsSignedIn]}>
            {props.children}
        </AuthContext.Provider>
    )
} 
