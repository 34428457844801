import { gql, useQuery } from '@apollo/client'
import { Block } from 'baseui/block'
import { LabelLarge } from 'baseui/typography'
import React, { ReactElement, useContext } from 'react'
import { Img } from 'react-image'
import { EnvConstants } from '../../../constants/EnvConstants'
import Loader from '../../UiElements/Loader/Loader'
import { AutoSizer, List } from "react-virtualized"
import { GqlMedia } from "../../../types/graphql/Media";
import AdsPlannerModalContext from "../../../contexts/AdsPlanner/AdsPlannerModalContext";
import { GqlAd } from '../../../types/graphql/Ads'
import { ImageUtils } from '../../../utils/ImageUtils'

const IMAGES_QUERY = gql`
    query { allAdsMedias {
        id
        path
        name
        keywords
    }
}
`;

interface Props {
    colCount?: number
    onFileDeleted?: () => void;
    existingMedia?: GqlMedia;
}

export default function AdImageGallery(props: Props): ReactElement {
    const allImagesQuery = useQuery<{ allAdsMedias: GqlMedia[] }>(IMAGES_QUERY);

    const { selectedMedia, setSelectedMedia } = useContext(AdsPlannerModalContext);

    const DATA = allImagesQuery.data?.allAdsMedias || [];
    // const baseUrl = EnvConstants.getEnvConst().apiUrl + 'file/ad/';
    const colCount = props.colCount || 4;
    return (
        <Block width="100%" display="flex" flexDirection="column">
            <LabelLarge>Réutiliser une image existante</LabelLarge>
            <Block flex="1 auto" width="100%" height="250px">
                {allImagesQuery.loading && <Loader />}
                {DATA.length &&
                    //@ts-ignore
                    <AutoSizer>
                        {({ width, height }) => {
                            const rowHeight = (width) / colCount;
                            const rowCount = Math.ceil(DATA.length / colCount);
                            return (
                                //@ts-ignore
                                <List
                                    rowCount={rowCount}
                                    height={height}
                                    width={width}
                                    rowHeight={rowHeight}
                                    overscanRowCount={10}
                                    rowRenderer={({ index, key, style }) => (
                                        <div style={style} key={key}>
                                            <Block height="100%" display="flex" padding="2px">{
                                                (new Array(colCount).fill(0)).map((item, idx) => {
                                                    if (DATA[colCount * index + idx])
                                                        return (
                                                            <Img
                                                                key={DATA[colCount * index + idx].id}
                                                                loader={<Loader />}
                                                                src={ImageUtils.getResizedImageUrl(DATA[colCount * index + idx].path!, 100) ?? ''}
                                                                style={{ boxSizing: 'border-box', border: selectedMedia?.id === DATA[colCount * index + idx].id ? '4px solid #FFA500' : '1px solid lightgray', height: `100%`, width: `${100 / colCount}%`, }}
                                                                onClick={() => setSelectedMedia(DATA[colCount * index + idx])} />)
                                                    return null;
                                                })}

                                            </Block>
                                        </div>
                                    )}
                                />)
                        }}
                    </AutoSizer>
                }

            </Block>
        </Block>
    )
}
