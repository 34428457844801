import React, { ReactElement } from "react";
import {
    StyledRoot,
    StyledTable, StyledTableBodyCell,
    StyledTableBodyRow,
    StyledTableHeadCell,
    StyledTableHeadRow
} from "baseui/table-semantic";
import { FaComment, FaEye, FaHeart } from "react-icons/fa";
import { styled } from "styletron-react";
import { Block } from "baseui/block";
import { GqlChallengeType } from "../../types/graphql/PeriodicChallengeInput";
import { GqlPeriodicChallenge } from "../../types/graphql/PeriodicChallengeListingItem";
import id from "date-fns/esm/locale/id/index.js";
import { GQLWordCount } from "../../types/graphql/words";


const StatItem = styled(Block, { display: 'flex', justifyContent: 'start' });
const StatText = styled(Block, { marginLeft: '10px', minWidth: '30px', fontWeight: 'bold' });
const Label = styled(Block, { display: "inline-block", marginRight: '10px', marginBottom: '10px', fontWeight: 'bold' });

export default function UserParticipationDetails(row): ReactElement {
    const challengeWords = row.word_Participations.length ? row.word_Participations.map(x => x.word.translations[0].value) :
        row.challenge.word_Challenges.map(x => x.word.translations[0].value)

    return (
        <StyledRoot>
            <StyledTable>
                <StyledTableHeadRow>
                    <StyledTableHeadCell colspan='2'>
                        {<h1 style={{ marginBottom: '10px' }}>{row.title}</h1>}
                        {row.moderationInfos?.isBanned ? <h4 style={{ color: 'red', fontWeight: 'bold' }}>BANNIE - {row.moderationInfos?.bannedReason}</h4> : ""}
                    </StyledTableHeadCell>
                </StyledTableHeadRow>
                <StyledTableBodyRow>
                    <StyledTableBodyCell colspan='1'>
                        <Label>Type du challenge :</Label>{getComputedType(row.challenge)} <br />
                        <Label>Mots :</Label>{challengeWords.map((x, index) => index === 0 ? `${x} \n` : `, ${x} \n`)}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell colspan='1'>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-evenly' }}>
                            <StatItem>
                                <FaEye size='20px' /><StatText>{row.viewCount}</StatText>
                            </StatItem>
                            <StatItem>
                                <FaHeart size='20px' /><StatText>{row.likeCount}</StatText>
                            </StatItem>
                            <StatItem>
                                <FaComment size='20px' /><StatText>{row.commentCount}</StatText>
                            </StatItem>
                        </div>
                    </StyledTableBodyCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                    <StyledTableBodyCell colspan='1'>
                        <Label>Gagnant : &nbsp;</Label>
                        {row.isWinner ? <span style={{ fontWeight: 'bold', color: 'green' }}>Oui</span>
                            : <span>Non</span>} <br />
                    </StyledTableBodyCell>
                    <StyledTableBodyCell colspan='1'>
                        {row.isWinner &&
                            <div>
                                <Label>Position :</Label>{row.winningPlace} <br />
                                <Label>Points : </Label>{row.winningPoints}
                            </div>
                        }
                    </StyledTableBodyCell>
                </StyledTableBodyRow>
            </StyledTable>
        </StyledRoot>
    )
}

function getComputedType(challenge: GqlPeriodicChallenge & { discriminator: string }) {
    const discriminator = challenge.discriminator.toLowerCase();
    
    if (discriminator.includes('periodic')) {
        return challenge.durationType
    } else if (discriminator.includes("playoff")) {
        return "Playoffs";
    }
    else {
        let type = challenge.type == GqlChallengeType.PHOTO ? "P" : "V";
        type += (challenge.wordCount == GQLWordCount.Three ? "3" : "4");
        return type;
    }
}