import { Block } from 'baseui/block'
import React, { useEffect, useMemo, useState } from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import PeriodicChallengeTableHeader from './PeriodicChallengeTable/PeriodicChallengeTableHeader';
import PeriodicChallengeTableRow from './PeriodicChallengeTable/PeriodicChallengeTableRow';
import { gql, useQuery } from '@apollo/client';
import { Spinner } from 'baseui/spinner';
import { GqlChallengeType, GqlDurationType, GqlPeriodicChallengeFrame } from '../../types/graphql/PeriodicChallengeInput';
import { periodToString } from '../../utils/DateUtils';
import { GQLWordCount } from '../../types/graphql/words';
import { GqlPeriodicChallenge, GqlPeriodicChallengeListingItem, GqlPeriodicChallengeQueryResult } from '../../types/graphql/PeriodicChallengeListingItem';
import { Text } from '../Footer/Footer.styled';
import { getChallengeNumber } from '../../utils/PeriodicChallengeDateUtils';

const QUERY = gql`
query{periodicChallenges(first:99999999, order_by:{dateParticipationStart:ASC}){
    nodes{
        id
        periodicChallengeFrame
        dateParticipationStart
        dateVoteEnd
        durationType
        wordCount
        type
        participationCount
    }
}}
`


interface Props {
    filters: { durationTypes: GqlDurationType[], frames: GqlPeriodicChallengeFrame[], dates: Date[] }
    onRowClick: (id: string) => void,
    refetchTrigger:any
}

const headers = ['#', 'Période', 'Type', 'Frame', 'Media', "Nbr. Participation"];

const PeriodicChallengeListingContainer = ({ filters, refetchTrigger,...props }: Props) => {
    const { data, loading,refetch } = useQuery<GqlPeriodicChallengeQueryResult<GqlPeriodicChallengeListingItem>>(QUERY);
    const [filteredData, setFilteredData] = useState<GqlPeriodicChallengeListingItem[]>([]);

    useEffect(() => {
        let tempData = [...(data?.periodicChallenges.nodes || [])];
        if (data) {
            if (filters.dates.length == 2)
                tempData = tempData.filter(x => new Date(x.dateParticipationStart) <= filters.dates[1] && new Date(x.dateVoteEnd) >= filters.dates[0]);
            if (filters.durationTypes.length)
                tempData = tempData.filter(x=> filters.durationTypes.some(y=> y == x.durationType));
            if(filters.frames.length)
                tempData = tempData.filter(x=> filters.frames.some(y=> y == x.periodicChallengeFrame));
        }
        setFilteredData(tempData);

    }, [filters, data]);

    useMemo(()=>{
        refetch();
    },[refetchTrigger])


    return (
        <Block flexDirection='column' position="relative">
            <PeriodicChallengeTableHeader
                headers={headers}
            />
            {loading ? <Spinner />
                : !filteredData || !filteredData.length ? 
                    <Text>Aucun challenge</Text>
                : filteredData.map((item, i) => {
                    const data = [
                        getChallengeNumber(item.durationType as GqlDurationType, new Date(item.dateParticipationStart)),
                        periodToString(item.dateParticipationStart, item.dateVoteEnd),
                        getDurationString(item.durationType as GqlDurationType ),
                        getFrameString(item),
                        getMediaTypeString(item.type),
                        item.participationCount]
                    return (
                        <PeriodicChallengeTableRow onRowClick={() => props.onRowClick(item.id)} rowIndex={i} key={item.id} headers={headers} data={data} />)
                })}
        </Block>
    )
}

function getDurationString(type: GqlDurationType) {
    switch (type) {
        case GqlDurationType.Hourly: return "Heure";
        case GqlDurationType.Monthly: return "Mois";
        case GqlDurationType.Weekly: return "Semaine";
        case GqlDurationType.Daily: return "Jour";
        default: return "Inconnu";
    }
}

function getMediaTypeString(type: GqlChallengeType) {
    switch (type) {
        case GqlChallengeType.PHOTO: return "Photo";
        case GqlChallengeType.VIDEO: return "Video";
        case GqlChallengeType.UNDEFINED: return "Photo/Video"
    }
}

function getFrameString(item: any) {
    let string = item.periodicChallengeFrame == GqlPeriodicChallengeFrame.Image ? 'Image'
        : item.periodicChallengeFrame == GqlPeriodicChallengeFrame.Words ? "Mots" : "N/D";
    if (string == 'Mots') {
        string += " ("
            + (item.wordCount == GQLWordCount.One ? 1 : item.wordCount == GQLWordCount.Two ? 2 : item.wordCount == GQLWordCount.Three ? 3 : '')
            + ')';

    }
    return string;
}

export default PeriodicChallengeListingContainer
