import React, { CSSProperties, useContext } from 'react'
import FacebookBtn, {ReactFacebookLoginInfo, ReactFacebookFailureResponse} from 'react-facebook-login'

import { AuthContext, LogInData } from '../../contexts/AuthContext'
import { LoginResponse } from '../../models/Dto/LoginResponse'
import { EnvConstants } from '../../constants/EnvConstants'

interface Props {
    style?: CSSProperties;
    disabled?:boolean;
    onLoginResponse: (response: LoginResponse | null, loginData: LogInData | null) => void;
    onBeginAuth: ()=>void;
}

const FacebookLogin = (props: Props) => {
    const [authContextTools, isSignedIn, setIsSignedIn] = useContext(AuthContext);
    async function onResponse(response: ReactFacebookLoginInfo) {
        
        let loginRes: {res:LoginResponse, req: LogInData} | null = null;
        
         if(response.accessToken){
            loginRes = await authContextTools!.onFacebookSignIn({
                token: response.accessToken,
                type: "success",
                userId: response.id!,
                email: response.email,
                appId: EnvConstants.getEnvConst().facebookAppLoginConfig.id,
                // expirationDate: new Date(Date.now() + asAny).expiresIn),
                // dataAccessExpirationDate: new Date(Date.now() + result.authentication!.expiresIn!)
            }) ?? null;
        }
        props.onLoginResponse(loginRes?.res ?? null, loginRes?.req ?? null)
    }

    return (
        <FacebookBtn
        appId={EnvConstants.getEnvConst().facebookAppLoginConfig.id}
        autoLoad={true}
        fields="email,id"
        onClick={props.onBeginAuth}
        redirectUri={`https://${window.location.host}`}
        textButton="Connection par Facebook"
        callback={onResponse} />
    )
}

export default FacebookLogin
const blue = 'rgb(23,93,188)'
const styles: {[key:string]: CSSProperties} = {
    container: {
        maxHeight: 40,
        backgroundColor: blue,
        padding: '3%',
        paddingRight: '5%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    logo: {
        height: '100%',
        maxHeight: '100%',
        marginRight: '6%',
    },
    textContainer: {
        flex: 1
    },
    text: {
        color: 'white',
        fontSize: 14,
    }

}
