import { useQuery } from '@apollo/client';
import { useStyletron } from 'baseui';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';

import { LabelLarge } from 'baseui/typography';
import gql from 'graphql-tag';
import React, { ChangeEvent, CSSProperties, FormEvent, useEffect, useState } from 'react';
import { WordsQueryResult } from '../../types/graphql/words';
import Loader from '../UiElements/Loader/Loader';
import {
    Checkbox,
    LABEL_PLACEMENT
} from "baseui/checkbox";
import {
    SortDirectionsT,
} from 'baseui/data-table';

import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import { SortableHeadCell, SORT_DIRECTION, StyledBody, StyledCell, StyledHead, StyledHeadCell, StyledRow, StyledTable } from 'baseui/table';
import WordListingRow from './WordListingComponents/WordListingRow';
import { GqlWord } from '../../types/graphql/AllPeriods';


const WORDS_QUERY = gql`
query {
    allWords{
      totalCount
        nodes {
          id
          translations {
            id
            languageId
            value
          }
        }
      }
}
`;
const LANG_QUERY = gql`
  query {
    allLanguages {
      id
      name
    }
  }
`;
interface Props {
    style?: CSSProperties
    refetchTrigger?: boolean
}


const WordsList = (props: Props) => {
    const [css] = useStyletron();
    const wordsQuery = useQuery<WordsQueryResult>(WORDS_QUERY, { onCompleted: (d) => setFilteredWords(d.allWords.nodes), fetchPolicy: 'network-only' })
    const langQuery = useQuery<{ allLanguages: [{ id: string, name: string }] }>(LANG_QUERY)
    const [filteredWords, setFilteredWords] = useState<GqlWord[]>();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [showIds, setShowIds] = useState(false);
    const [sortInfos, setSortInfos] = useState<{ col: string, direction: SortDirectionsT }>({col:'en', direction:'ASC' });
    const { refetchTrigger } = props;

    function searchWord(e: ChangeEvent<HTMLInputElement>) {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        if (!searchTerm)
            setFilteredWords(wordsQuery.data?.allWords.nodes);
        else {
            const res = wordsQuery.data?.allWords.nodes
                .filter(x => x.translations!
                    .some(y => y.value!.toLowerCase().diacriticLess().indexOf(searchTerm.trim().toLowerCase().diacriticLess()) > -1));
            setFilteredWords(res);
        }
    }

    useEffect(() => {
        wordsQuery.refetch();
    }, [refetchTrigger])

    const langs = langQuery.data?.allLanguages.map(x => x.id) ?? [];

    function onIdCheckboxChange(e: FormEvent<HTMLInputElement>) {
        setShowIds(e.currentTarget.checked);
    }

    function handleSort(col: string, prevDirection: SortDirectionsT) {
        let nextDirection = null as SortDirectionsT;
        if (prevDirection === SORT_DIRECTION.ASC) {
            nextDirection = SORT_DIRECTION.DESC;
        }
        if (prevDirection === SORT_DIRECTION.DESC) {
            nextDirection = SORT_DIRECTION.ASC;
        }
        if (prevDirection === null) {
            nextDirection = SORT_DIRECTION.ASC;
        }

        setSortInfos({ col, direction: nextDirection });
    }

    function getSortedData() {
        const sorted = filteredWords?.slice(0).sort((a, b) => {
            if (!sortInfos || sortInfos.direction == null)
                return 0;

            const aVal = (a.translations?.filter(x => x.languageId == sortInfos.col)[0]?.value ?? '');
            const bVal = (b.translations?.filter(x => x.languageId == sortInfos.col)[0]?.value ?? '');
            return aVal.localeCompare(bVal) * (sortInfos.direction === "ASC" ? 1 : -1);
        });

        return sorted ?? [];
    }

    return (
        <div style={props.style}>

            <Grid gridColumns={[6, 8, 12]} gridMargins={0} gridGutters={0}>
                <Cell span={[6, 3]}>
                    {/*@ts-ignore*/}
                    <Input startEnhancer={<Search size={20} />} type='text' onChange={searchWord} placeholder="Recherche" clearable />
                </Cell>
                <Cell span={[6, 4]} skip={[0, 0, 1]} align={ALIGNMENT.center}>
                    <LabelLarge alignContent="center" alignSelf="center" >
                        {(searchTerm ? 'Résultats : ' : "Total : ") + (filteredWords?.length || 0)}
                    </LabelLarge>
                </Cell>
                <Cell span={[6, 3]} align={ALIGNMENT.center}>
                    {/*@ts-ignore*/}
                    <Checkbox labelPlacement={LABEL_PLACEMENT.right} checked={showIds} onChange={onIdCheckboxChange} >Afficher Id</Checkbox>
                </Cell>
            </Grid>

            <div className={css({ height: '750px', maxHeight: '750px', margin: '0 auto' })}>
                {
                    filteredWords && 

                        <StyledTable>
                            <StyledHead>
                                {langQuery.data?.allLanguages.map(x => <SortableHeadCell
                                    title={x.name}
                                    direction={sortInfos?.col == x.id ? sortInfos?.direction as any : null }
                                    onSort={() => { handleSort(x.id, sortInfos?.direction ?? null) }}
                                />)
                                }
                                {showIds && <StyledHeadCell>Id</StyledHeadCell>}

                            </StyledHead>

                            <StyledBody>
                                {getSortedData().map((w, idx) => <WordListingRow showId={showIds} key={w.id} item={w} langs={langs} striped={idx % 2 !== 0}></WordListingRow>)}
                            </StyledBody>

                        </StyledTable>
                        ||
                        <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><Loader /></div>
                    
                }
            </div>
        </div>
    )
}


export default WordsList

