import { Block } from 'baseui/block'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import FacebookLogin from '../components/login/FacebookLogin'
import Container from '../components/UiElements/Container/Container'
import { AuthContext, LogInData } from '../contexts/AuthContext'
import { LoginResponse } from '../models/Dto/LoginResponse'
import { UserRoles } from '../models/Dto/UserInfos'
import UnauthorizedScreen from './UnauthorizedScreen'


const LoginScreen = () => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [authContextTools, isSignedIn, setIsSignedIn] = useContext(AuthContext);
    const [isUnauthorized, showIsUnauthorized] = useState(false);
    const navigate = useNavigate();
    function onServerResponse(response: LoginResponse | null, loginData: LogInData | null) {
        setIsLoading(false);
        if (response == null)
            showIsUnauthorized(true)
        else if (response.success) {
            const roles = response?.userInfos?.userInfos?.roles ?? [];
            const isAdmin = Array.isArray(roles) && roles.some(x => x === UserRoles.admin) || roles === UserRoles.admin || roles === 'admin';

            if (isAdmin) {
                showIsUnauthorized(false);
                setIsSignedIn(true);
                navigate("/", { replace: true })
            }
            else
                showIsUnauthorized(true);

        }
        else if (response?.message)
            alert(response.message);

    }
    return (
        <Container>
            {isUnauthorized ? <UnauthorizedScreen /> :
                <Block alignItems="center" justifyContent="center" display="flex" $style={{ height: '80vh' }}>
                    <FacebookLogin onBeginAuth={() => setIsLoading(true)} disabled={isLoading} onLoginResponse={onServerResponse} style={styles.loginServiceButton} />

                    {/* Apple */}
                    {/* {Platform.OS == "macos" && <AppleLogin onBeginAuth={() => setIsLoading(true)} disabled={isLoading} onLoginResponse={onServerResponse} style={styles.loginServiceButton} />} */}
                </Block>
            }
        </Container>
    )
}

export default LoginScreen

const styles = {
    loginServiceButton: {
        marginTop: '8%',
        // marginTop: '4%',
        height: 40,
        // maxHeight: '25%',
        borderRadius: 30,
    },
}
