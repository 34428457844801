import { Block } from 'baseui/block';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { IoIosMore } from 'react-icons/io';
import { GqlChallengeType } from '../../types/graphql/PeriodicChallengeInput';
import { ImageUtils } from '../../utils/ImageUtils';
import CommentComplaintDetailsModal from '../modals/Complaints/CommentComplaintDetailsModal';
import ReadMore from '../ReadMore/ReadMore';
import Image from '../UiElements/Image/Image';
import { Button } from '../UiElements/WidgetCard/WidgetCard.styled';
import { useCommentComplaintQueries } from './useCommentComplaintQueries'

export default function CommentComplaintsContainer(): ReactElement {
    const { commentsToModerate } = useCommentComplaintQueries();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [queryResult, setQueryResult] = useState<{ nodes: any[], pageInfo: any }>();
    const clickedComment = useRef<any>();
    const data = (queryResult?.nodes ?? []).filter(x => !x.moderationInfos?.dateVerified);
    const hasNextPage = queryResult?.pageInfo.hasNextPage === true;

    useEffect(() => {
        commentsToModerate.fetchMore({ variables: { cursor: undefined } }).then(x => {
            setQueryResult(x?.data?.commentsToModerate);
        });
    }, [])

    const fetchMore = () => {
        if (queryResult?.pageInfo.hasNextPage)
            commentsToModerate.fetchMore({ variables: { cursor: queryResult?.pageInfo.endCursor } })
    }

    function openModal(comment: any) {
        clickedComment.current = comment;
        setIsModalOpen(true);
    }
    function closeModal(refetch: boolean) {
        setIsModalOpen(false);
        if (refetch)
            commentsToModerate.refetch({ cursor: undefined })
    }

    return (
        <Block >
            {/*@ts-ignore*/}
            <TableBuilder
                isLoading={commentsToModerate.loading}
                emptyMessage="Il n'y a rien ici!"
                data={data}
            >
                {/*@ts-ignore*/}
                <TableBuilderColumn header="Participation">
                    {x => {
                        const path = x.participation.media.type === GqlChallengeType.VIDEO ? ImageUtils.makeUrl(x.participation.path.replace(".mp4", "_thumb.jpg").replace('.mov', '_thumb.jpg'))
                            : ImageUtils.getResizedImageUrl(x.participation.path, 50);


                        return (
                            <div>
                                <div>{x.participation.media.type}</div>
                                <Image url={path ?? ''} alt={x.title} style={{ width: 50, height: 50 }} />
                            </div>
                        )
                    }}
                </TableBuilderColumn>
                {/*@ts-ignore*/}
                <TableBuilderColumn header="Commentaire" overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => {
                        return <ReadMore>{x.content}</ReadMore>;
                    }
                    }
                </TableBuilderColumn>
                {/*@ts-ignore*/}
                <TableBuilderColumn header="Signalements" overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => x.moderationInfos.complaintCount}
                </TableBuilderColumn>
                {/*@ts-ignore*/}
                <TableBuilderColumn header="" overrides={{ TableBodyCell: { style: { verticalAlign: 'middle' } } }} >
                    {x => <IoIosMore size={30} onClick={() => openModal(x)} cursor="pointer" />}
                </TableBuilderColumn>
            </TableBuilder>

            {hasNextPage && <Button onClick={fetchMore} >Charger plus de résultats</Button>}

            <CommentComplaintDetailsModal isOpen={isModalOpen} comment={clickedComment.current} close={closeModal} />
        </Block>
    )
}
