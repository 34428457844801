import { Block } from 'baseui/block'
import React from 'react'
import AdsPlannerCalendar from '../components/AdsPlanner/AdsPlannerCalendar'
import PageWrapper from '../components/layout_old/PageWrapper'

export const AdsScreen = () => {
    return (
        <PageWrapper>
            <Block padding={['0 10px', '0 20px', '0 40px']} height="80vh" >
                {/* <Block flex="8" width='75%'> */}
                    <AdsPlannerCalendar />
                {/* </Block> */}

                {/* <Block flex='3' width="25%" display="flex" alignItems="stretch" height="100%" justifyContent="stretch" justifyItems="stretch"> */}
                        {/* <AdsImageGallery /> */}
                {/* </Block> */}
            </Block>
        </PageWrapper>
    )
}
