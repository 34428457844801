import React, { PropsWithChildren, useContext } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import AuthHeader from '../Header/AuthHeader/AuthHeader';
import AppWrapper, { ContentWrapper } from './Layout.styled';
import { useThemeSwitcherCtx, THEME } from '../../contexts/theme/theme.provider';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { UserRoles } from '../../models/Dto/UserInfos';

const Layout: React.FunctionComponent<PropsWithChildren<unknown>> = ({
    children,
}) => {
    const location = useLocation();
    const pathname = location.pathname;
    const { theme } = useThemeSwitcherCtx();
    let layoutBg = '#ffffff';

    if (theme === THEME.dark) {
        layoutBg = '#000000';
    }

    if (
        (theme === THEME.light && pathname === '/') ||
        (theme === THEME.light && pathname === '/chat')
    ) {
        layoutBg = '#fcfcfc';
    }

    if (
        (theme === THEME.dark && pathname === '/') ||
        (theme === THEME.dark && pathname === '/chat')
    ) {
        layoutBg = '#0c0c0c';
    }
    const [authContextTools, isSignedIn, setIsSignedIn] = useContext(AuthContext);
    const roles = authContextTools?.getUserInfos()?.roles ?? [];
    const isAdmin = Array.isArray(roles) && roles.some(x => x === UserRoles.admin) || roles === UserRoles.admin || roles === 'admin';

    return (
        <AppWrapper className={theme} style={{ backgroundColor: layoutBg }}>
            {pathname === '/login' ? (
                <AuthHeader pathname={pathname} />
            ) :
            isAdmin?
             (
                <Header />
            ) : ''}
            <ContentWrapper>{children}</ContentWrapper>
            {pathname === '/login' || !isAdmin ? '' : <Footer />}
        </AppWrapper>
    );
};

export default Layout;
