import React, { useContext } from 'react';
import { Block } from 'baseui/block';
import { Avatar } from 'baseui/avatar';
import { StatefulPopover } from 'baseui/popover';
import ActiveLink from '../../../UiElements/NavLink/NavLink';
import MenuWrapper, { Line, MenuItem } from './AvatarMenu.styled';
import {
    useThemeSwitcherCtx,
    THEME,
} from '../../../../contexts/theme/theme.provider';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

type AvatarMenuType = {
    name?: string;
    src?: string;
    placement?: any;
    showOnlyMenu?: boolean;
    onClick?: () => void;
};

type MenuType = {
    onClick?: () => void;
};

const Menu = ({ onClick }: MenuType) => {
    const { theme, setTheme } = useThemeSwitcherCtx();
    const [authContextTools, _, setIsSignedIn] = useContext(AuthContext);
    const navigate = useNavigate();

    function logout() {
        const success = authContextTools?.signOut();
        if (success) {
            navigate('/')
            setIsSignedIn(false);
        }
    }
    return (
        <MenuWrapper>
            <li>
                <ActiveLink activeClassName="active" href="/">
                    <MenuItem onClick={onClick}>Dashboard</MenuItem>
                </ActiveLink>
            </li>
            {/* <Line></Line> */}
            {/* <li>
        <ActiveLink activeClassName="active" href="/settings">
          <MenuItem onClick={onClick}>Settings</MenuItem>
        </ActiveLink>
      </li> */}
            <Line></Line>
            <li>
                <MenuItem
                    onClick={() => {
                        const getTheme = theme === THEME.light ? THEME.dark : THEME.light;
                        setTheme(getTheme);
                        localStorage.setItem('theme', getTheme);
                    }}
                >
                    {theme === THEME.light ? 'Dark Mode' : 'Light Mode'}
                </MenuItem>
            </li>
            <Line></Line>
            <li onClick={() => { logout() }}>
                <MenuItem onClick={onClick}>Déconnecter</MenuItem>
            </li>
        </MenuWrapper>
    );
};

const AvatarMenu = ({
    name,
    src,
    placement,
    showOnlyMenu,
    onClick,
}: AvatarMenuType) => {
    return (
        <>
            {showOnlyMenu ? (
                <Menu onClick={onClick} />
            ) : (
                <StatefulPopover
                    content={<Menu onClick={onClick} />}
                    placement={placement ? placement : 'bottomRight'}
                    overrides={{
                        Inner: {
                            style: ({ $theme }) => {
                                return {
                                    backgroundColor: $theme.colors.primaryB,
                                };
                            },
                        },
                    }}
                    showArrow
                >
                    <Block overrides={{ Block: { style: { cursor: 'pointer' } } }}>
                        {/*@ts-ignore*/}
                        <Avatar src={src} name={name ? name : 'Jon Doe'} size="scale1000" />
                    </Block>
                </StatefulPopover>
            )}
        </>
    );
};

export default AvatarMenu;
