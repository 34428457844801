import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { GqlDurationType } from '../types/graphql/PeriodicChallengeInput';
import { GqlUsedPeriodicChallengeQueryResult } from '../types/graphql/usedPeriodicChallenge';
import { getNextDayDates, getNextHourDates, getNextMonthDates, getNextWeekDates, periodicDayStart, periodicHourStart, periodicMonthStart, periodicWeekStart } from '../utils/PeriodicChallengeDateUtils';

const USED_DATES_QUERY = gql`
query {
	usedPeriodicChallenge {
    monthly,
    weekly,
    daily,
    hourly,
  }
}
`

export type PeriodicChallengeAvailableDates = {
    [GqlDurationType.Monthly]: Date[],
    [GqlDurationType.Weekly]: Date[],
    [GqlDurationType.Daily]: Date[],
    [GqlDurationType.Hourly]: Date[]
}

export function usePeriodicChallengeAvailableDates() {
    const [dates, setDates] = useState<PeriodicChallengeAvailableDates>({ MONTHLY: [], DAILY: [], HOURLY: [], WEEKLY: [] });
    const challengeDatesQuery = useQuery<GqlUsedPeriodicChallengeQueryResult>(USED_DATES_QUERY, { onCompleted: onDataReceived });

    function onDataReceived(d: GqlUsedPeriodicChallengeQueryResult) {
        const availables: PeriodicChallengeAvailableDates = {
            MONTHLY: getNextMonthDates(periodicMonthStart, 100).map(x => x.startDate),
            WEEKLY: getNextWeekDates(periodicWeekStart, 100).map(x => x.startDate),
            DAILY: getNextDayDates(periodicDayStart, 100).map(x => x.startDate),
            HOURLY: getNextHourDates(periodicHourStart, 100).map(x => x.startDate),
        };
        const comparer = (y: string, x: Date) => new Date(y).valueOf() == x.valueOf();
        availables.MONTHLY = availables.MONTHLY.filter(x => !d.usedPeriodicChallenge.monthly.some(y => comparer(y, x)));
        availables.WEEKLY = availables.WEEKLY.filter(x => !d.usedPeriodicChallenge.weekly.some(y => comparer(y, x)));
        availables.DAILY = availables.DAILY.filter(x => !d.usedPeriodicChallenge.daily.some(y => comparer(y, x)));
        availables.HOURLY = availables.HOURLY.filter(x => !d.usedPeriodicChallenge.hourly.some(y => comparer(y, x)));

        setDates(availables);

    }

    return {
        dates,
        challengeDatesQuery
    }
}