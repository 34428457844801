import { TextField, Typography } from '@mui/material'
import { Button } from 'baseui/button'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Grid, Cell, BEHAVIOR } from 'baseui/layout-grid'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import { toaster } from 'baseui/toast'
import React, { useEffect } from 'react'
import { useFeaturedAppsMutations } from '../../hooks/queries/useFeaturedAppsQuery'
import useFeaturedAppFormFields from '../../hooks/useFeaturedAppFormFields'
import { FeaturedApps } from '../../types/graphql/FeaturedApp'
import ImageUploader from '../ImageUploader/ImageUploader'
import MultipleLanguageTextArea from '../MultipleLanguageTextArea/MultipleLanguageTextArea'

type Props = {
    initialState?: FeaturedApps
    show: boolean
    onSaved: () => void
    close: () => void
}

export default function FeaturedAppModal({ initialState, show, onSaved, close }: Props) {
    const { formFields, initialValue, onChange, setFormFields, getCreateDTO, getUpdateDto, isValid } = useFeaturedAppFormFields();
    const { create, createMutation, update, performDelete } = useFeaturedAppsMutations();

    useEffect(() => {
        if (show) {
            if (initialState)
                setFormFields(initialState as any)
            else
                setFormFields(initialValue);
        }
    }, [initialState, show]);

    const save = async function (e) {
        e.preventDefault();
        const dto = initialState ? getUpdateDto() : getCreateDTO();
        const func = initialState ? update : create;

        const res = await func({ variables: { dto: dto as any }, errorPolicy: 'all' });
        if (res.errors?.length) {
            toaster.negative(<>{res.errors.map(x => <>{x.message}<br /></>)}</>, {})
        } else {
            onSaved();
            close();
        }
    }

    return (
        /*@ts-ignore*/
        < Modal isOpen={show} role="dialog" onClose={close} size="auto" overrides={{ Dialog: { style: { minWidth: '600px' } } }}>
            <ModalHeader>
                <Typography>App</Typography>
            </ModalHeader>
            <form onSubmit={save}>
                <ModalBody>
                    <Grid gridMargins={0} gridColumns={12} behavior={BEHAVIOR.fluid}>
                        <Cell span={6}>

                            {/*@ts-ignore*/}
                            <FormControl label="Nom*">
                                {/*@ts-ignore*/}
                                <Input disabled={createMutation.loading} required value={formFields.name} onChange={e => onChange('name', e.currentTarget.value)} />
                            </FormControl>
                            {/*@ts-ignore*/}
                            <FormControl label="Description*">
                                <MultipleLanguageTextArea
                                    required
                                    languages={['en', 'fr']}
                                    value={formFields.description}
                                    onChange={e => onChange('description', e)}
                                />
                            </FormControl>
                            {/*@ts-ignore*/}
                            <FormControl label="Position">
                                {/*@ts-ignore*/}
                                <Input disabled={createMutation.loading} value={formFields.order} type="number" onChange={e => onChange('order', e.currentTarget.value)} />
                            </FormControl>
                        </Cell>

                        <Cell span={6}>
                            {/*@ts-ignore*/}
                            <FormControl label="Url iOS (App Store)">
                                {/*@ts-ignore*/}
                                <Input disabled={createMutation.loading} value={formFields.iOSUrl} onChange={e => onChange('iOSUrl', e.currentTarget.value)} />
                            </FormControl>
                            {/*@ts-ignore*/}
                            <FormControl label="Url Android (Play Store)">
                                {/*@ts-ignore*/}
                                <Input disabled={createMutation.loading} value={formFields.androidUrl} onChange={e => onChange('androidUrl', e.currentTarget.value)} />
                            </FormControl>
                            {/*@ts-ignore*/}
                            <FormControl label="Url site web (en dernier recours)">
                                {/*@ts-ignore*/}
                                <Input disabled={createMutation.loading} value={formFields.webUrl} onChange={e => onChange('webUrl', e.currentTarget.value)} />
                            </FormControl>
                        </Cell>
                    </Grid>
                    {/*@ts-ignore*/}
                    <FormControl label="Image*">
                        <ImageUploader
                            disabled={createMutation.loading}
                            destinationDirectory='featuredApps'
                            existingMedia={formFields.media}
                            onFileDeleted={() => onChange('mediaId', '')}
                            onUploaded={e => onChange('mediaId', e)}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={!isValid} isLoading={createMutation.loading}>Enregistrer</Button>
                </ModalFooter>
            </form>
        </Modal >
    )
}