import { Divider } from '@mui/material';
import { ChevronRight } from 'baseui/icon';
import { StatefulMenu } from 'baseui/menu';
import React, { useEffect, useState } from 'react';
import { IoIosAlert, IoIosNotifications } from 'react-icons/io';
import { NavLink, useNavigate } from 'react-router-dom';
import { useComplaintQueries } from '../../../Complaints/useComplaintQueries';
import Badge from '../../../UiElements/Badge/Badge';
import Menu, { MenuItem, MenuLink } from './MainMenu.styled';

const menuItems = [
    {
        label: 'Accueil',
        path: '/',
    },
    {
        separator: true,
        label: 'Utilisateurs',
        path: '/users',
    },
    {
        label: 'Pubs et images',
        path: '/ads',
    },
    {
        separator: true,
        label: 'Mots',
        children: [
            {
                label: 'Tous les Mots',
                path: '/words',
            },
            {
                label: 'Planification',
                path: '/planification',
            },
        ]
    },
    {
        label: 'Challenges périodiques',
        path: '/periodic-challenges',
    },
    {
        label: 'PLAYOFFS',
        path: '/playoffs',
    },
    {
        separator: true,
        label: 'Modération',
        path: '/moderation',
    },
    {
        label: 'Apps partenaires',
        path: '/featured-apps',
    },
    {
        label: 'Configuration',
        path: '/app-settings',
    },
];

type MenuProps = {
    className?: string;
    onClick?: () => void;
};

const MainMenu = ({ className, onClick }: MenuProps) => {
    const { moderationCountQuery } = useComplaintQueries();
    const [items, setItems] = useState(menuItems)

    useEffect(() => {
        moderationCountQuery[0]().then(x => {
            if (x?.data?.participationsToModerate?.totalCount) {
                setItems(i => i.map(item => {
                    if (item.label == 'Modération')
                        return { ...item, badge: x.data!.participationsToModerate.totalCount }

                    return item;
                }))
            }
        });
    }, []);

    return (
        <Menu className={className} style={{ zIndex: 100 }}>
            {items.map((item, idx) => (
                <LocalMenuItem key={idx} index={idx} item={item} onClock={onClick} />
            ))}
        </Menu>
    );
};

const LocalMenuItem = (props: any) => {
    const { item, onClick } = props;
    const [showSubMenu, setShowSubMenu] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            {item.separator && <Divider style={{ width: '100%' }} />}
            <MenuItem key={`top-menu--item${props.index}`} onClick={onClick} onMouseEnter={() => setShowSubMenu(true)} onMouseLeave={() => setShowSubMenu(false)}>
                <NavLink style={{ textDecoration: 'none' }} className={({ isActive }) => isActive ? 'active' : ''} to={item.path ?? '#'}>
                    <MenuLink>
                        {item.label}
                        {item.badge && <Badge icon={<IoIosNotifications color="darkred" />} count={item.badge} />}
                        {item.children?.length && <ChevronRight />}
                    </MenuLink>
                </NavLink>

                {item.children && showSubMenu &&
                    /*@ts-ignore*/
                    <StatefulMenu
                        overrides={{
                            List: { style: { position: 'absolute', zIndex: 1 } },

                        }}
                        items={item.children}
                        onItemSelect={item => navigate(item.item.path)}
                    />
                }
            </MenuItem>
        </>
    )
}

export default MainMenu;
