import React, { CSSProperties, PropsWithChildren, useEffect, useState } from 'react'

interface Props {
    style?: CSSProperties,
    scrollViewStyle?: CSSProperties,
    scrollable?: boolean
}

const PageWrapper = (props: PropsWithChildren<Props>) => {
    const [scrollHeight, setScrollHeight] = useState(window.innerHeight - 90);
    useAutoDrawerCollapse(768, );

    function adaptHeight(e: UIEvent){
        if(window.innerHeight - 90 != scrollHeight)
            setScrollHeight(window.innerHeight - 90)
    }
    useEffect(()=>{
        window.addEventListener('resize',adaptHeight);
        return()=>{
            window.removeEventListener('resize', adaptHeight)
        }
    },[])

    return (
        <div style={{flex:1, height:'100%',paddingBottom:10, ...props.style}}>
            {/* <Header onLayout /> */}
            <div style={{paddingBottom:30, flex:1, minHeight:scrollHeight, ...props.scrollViewStyle}}>
                {props.children}
            </div>
        </div>
    )
}

const  useAutoDrawerCollapse = (breakpoint: number) => {
    const [isCollapsed, setIsCollapsed] = useState(window.innerWidth <= breakpoint);
    const onChange = () => {
        if (window.innerWidth > breakpoint) {
            if (isCollapsed) {
                setIsCollapsed(false);
            }
        } else {
            if (!isCollapsed) {
                setIsCollapsed(true);
            }
        }
  
    };
  
    useEffect(() => {
        window.addEventListener('resize', onChange);
        return () => {
            window.removeEventListener('resize', onChange);
        };
    });
  
    return isCollapsed;
  }
  


export default PageWrapper

const styles = {
    scrollview: {
        paddingHorizontal:10,
        paddingVertical: 5
    }
}
